export default function (array, propertyName) {
  if (!array || array.length === 0) {
    return [];
  }

  let min = array[0][propertyName],
    max = array[0][propertyName];

  for (let i = 1, len = array.length; i < len; i++) {
    let v = array[i][propertyName];
    min = v < min ? v : min;
    max = v > max ? v : max;
  }

  return [min, max];
}
