import savedPackagesLS from 'managers/localStorage/savedPackages/savedPackagesLS';
import { setPackages, addPackage, removePackage } from './actions';
import { getFuturePackages } from './selectors';

export function hydrateSavedPackages() {
  return (dispatch) => {
    let savedPackages = savedPackagesLS.get();

    if (savedPackages !== null) {
      const conversionOccured = convertOldSavedPackagesForm(savedPackages);
      if (conversionOccured) {
        savedPackages = savedPackagesLS.get();
      }

      const futureSavedPackages = getFuturePackages(savedPackages);

      if (futureSavedPackages !== savedPackages) {
        savedPackagesLS.set(futureSavedPackages);
      }

      dispatch(setPackages(futureSavedPackages));
    }
  };
}

export function updateSavedPackages(packageUniqueKey, packet) {
  return (dispatch, getState) => {
    let changes = false;

    if (packageUniqueKey) {
      changes = true;
      dispatch(removePackage(packageUniqueKey));
    } else if (packet) {
      let isUnique = true;
      const currentPackages = getState().savedPackages.packages;

      for (var i = 0; i < currentPackages.length; i++) {
        if (currentPackages[i].packageLink === packet.packageLink) {
          isUnique = false;
          break;
        }
      }

      if (isUnique) {
        changes = true;
        dispatch(addPackage(packet));
      }
    }

    if (changes) {
      savedPackagesLS.set(getState().savedPackages.packages);
    }
  };
}

function convertOldSavedPackagesForm(savedPackages) {
  const convertedPackages = [...savedPackages];
  let conversionOccured = false;

  for (var i = 0; i < convertedPackages.length; i++) {
    const savedPackage = convertedPackages[i];

    if (savedPackage.fullSlug !== undefined) {
      conversionOccured = true;

      convertedPackages[i] = {
        packageUniqueKey: savedPackage.id,
        packageLink: savedPackage.fullSlug,
        selectedRooms: savedPackage.selectedRooms,
        hotel: {
          slug: savedPackage.hotel.slug,
          name: savedPackage.hotel.name,
          score: savedPackage.hotel.score * 10,
          reviewsCount: savedPackage.hotel.reviewsCount,
          stars: savedPackage.hotel.stars,
          shortDescription: savedPackage.hotel.description,
          location: {
            city: savedPackage.hotel.city,
          },
          images: savedPackage.hotel.images,
        },
        packet: {
          dates: savedPackage.package.dates,
          price: savedPackage.hotel.price,
          airport: {
            code: savedPackage.package.airportCode,
            name: savedPackage.package.departure,
          },
          room: {
            id: savedPackage.package.roomId,
            name: savedPackage.package.roomType,
          },
          board: {
            code: savedPackage.package.boardCode,
            name: savedPackage.package.board,
          },
        },
      };
    }
  }

  if (conversionOccured) {
    savedPackagesLS.set(convertedPackages);
  }

  return conversionOccured;
}
