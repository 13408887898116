import queryString from 'query-string';
import { PRODUCTION as RUNTIME_HOST_ENV_PRODUCTION } from 'constants/env/runtimeHostEnv';

const amazonAwsProd = 'https://bkkbbi8sf9.execute-api.eu-west-1.amazonaws.com/prod';
const amazonAwsStage = 'https://9nlwr78p86.execute-api.eu-west-1.amazonaws.com/stage';
const tripxApiEnvQueryParams = {
  prod: 'use-prod-api',
  stage: 'use-staging-api',
  stagePre: 'use-staging-pre-api',
};

export default function getAwsEndpoint({ enforceProdApi }) {
  const useProdApi = getUseProdApi({ enforceProdApi });

  const stagingApiSlug = checkForStagingApiSlug(useProdApi);

  const awsEndpoint = useProdApi ? amazonAwsProd : amazonAwsStage;

  return {
    awsEndpoint,
    stagingApiSlug,
    isStagingApi: stagingApiSlug.includes('use-staging-api=1'),
  };
}

/**
 * Logic to determine if production api should be used.
 * Production api will be used only if `RUNTIME_HOST_ENV` is set to 'production'.
 * For all other cases the default value is false unless `use-prod-api` is set to '1' as a query parameter.
 * @returns {boolean} Indicates if production api should be used.
 */
function getUseProdApi({ enforceProdApi }) {
  if (process.env.RUNTIME_HOST_ENV === RUNTIME_HOST_ENV_PRODUCTION) {
    return true;
  }

  const useProdApiParamValue =
    typeof window !== 'undefined' && parseInt(queryString.parse(window.location.search)[tripxApiEnvQueryParams.prod]);
  const useProdApi = useProdApiParamValue === 1;

  return useProdApi || enforceProdApi;
}

function checkForStagingApiSlug(useProdApi) {
  const useStagingPreApiParamValue =
    typeof window !== 'undefined' &&
    parseInt(queryString.parse(window.location.search)[tripxApiEnvQueryParams.stagePre]);
  const useStagingApiValue = useProdApi ? 0 : 1;
  const tripxApiEnvQueryParam =
    !useProdApi && useStagingPreApiParamValue === 1 ? tripxApiEnvQueryParams.stagePre : tripxApiEnvQueryParams.stage;

  return `?${tripxApiEnvQueryParam}=${useStagingApiValue}`;
}

export function urlBuilder({ method, queryParam, enforceProdApi = false }) {
  const { awsEndpoint: baseUrl, stagingApiSlug, isStagingApi } = getAwsEndpoint({ enforceProdApi });

  if (isStagingApi) {
    if (queryParam) {
      return `${baseUrl}${method}${stagingApiSlug}&${queryParam}`;
    } else {
      return `${baseUrl}${method}${stagingApiSlug}`;
    }
  }

  if (queryParam) {
    return `${baseUrl}${method}?${queryParam}`;
  } else {
    return `${baseUrl}${method}`;
  }
}
