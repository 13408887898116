export const SEARCH_BOX_SUBMIT = 'Search Box Submit';
export const SEARCH_RESULTS = 'Search Results';
export const SEARCH_RESULTS_LOADED = 'Search Results Loaded';
export const SEARCH_RESULTS_NO_RESULTS = 'no_results_packages';
export const CHECKOUT_NO_RESULTS = 'no_results_checkout';
export const SEARCH_RESULTS_FILTERED = 'Filtered Results';
export const HOTEL_LIVE_SEARCH = 'Hotel Live Search';
export const HOTEL_PAGE_ROOMS_VIEWED = 'Hotel Page Rooms Viewed';
export const SINGLE_HOTEL_SEARCH = 'Single Hotel Search';
export const ROOM_BOARD_CHANGED = 'Room/Board Changed';
export const NOT_FOUND = 'Page Not Found';

export const Checkout = 'checkout';
export const CLICKED_ADVERTS = 'clicked_adverts';
export const CLICKED_PRICE_BARS = 'clicked_price_bars';
export const CLICKED_MOST_POPULAR_HOTELS = 'clicked_most_popular_hotels';
export const CLICKED_ABANDONED_CART = 'clicked_abandoned_cart';
export const CLICKED_HOME_BOXES = 'clicked_home_boxes';
export const CLICKED_FLIGHTS_FILTERS = 'clicked_flights_filters';
export const CLICKED_HOTEL_PAGE_SEARCH = 'clicked_hotel_page_search';
export const CLICKED_ADD_TO_FAVORITES = 'clicked_add_to_favorites';
export const CLICKED_SHARE_BUTTON = 'clicked_share_button';
export const CLICKED_FLIGHT = 'clicked_flight';
export const CLICKED_SHOW_MORE_FLIGHTS = 'clicked_show_more_flights';
export const CLICKED_SEARCH = 'clicked_search';
export const ABANDONED_CART = 'abandoned_cart';
export const CIO_IDENTIFY = 'cio_identify';
export const LOAD_MORE_PACKAGES_SEARCH_PAGE = 'load_more_packages_search_page';
export const FLIGHTS_DETAILS_OFFER_EXPIRED = 'flights_details_offer_expired';
export const SHARE_MY_FAVORITES_HOTELS = 'clicked_share_all_favorites';
export const CLICKED_MY_FAVORITES = 'clicked_my_favorites';

// Old checkout flow tracking events
// Keep them for some time to gather data for comparions, then they should be removed
export const ADDED_TO_CART = 'Added to Cart';
export const LOADED_CHECKOUT_PAGE = 'Loaded Checkout Page';
export const BOOKING_SUCCESSFUL = 'Booking Successful';
export const FB_PURCHASE = 'FbPurchase';

//funnel exploration (checkout) events
export const SEARCH_PAGE = 'checkout_search_page';
export const SELECT_ROOMS = 'checkout_select_rooms';
export const FLIGHT_SELECTION = 'checkout_flight_selection';
export const FLIGHT_EXTRAS = 'checkout_flight_extras';
export const OTHER_EXTRAS = 'checkout_other_extras';
export const PERSONAL_DATA = 'checkout_personal_data';
export const CONFIRMATION = 'checkout_confirmation';

//recommended EEC events
export const PURCHASE = 'purchase';
export const VIEW_ITEM = 'view_item';
export const ADD_TO_CART = 'add_to_cart';

//Fb track events
export const FB_FIND_LOCATION = 'fb_findLocation';
export const FB_VIEW_CONTENT = 'fb_viewContent';
export const FB_ADD_TO_CART = 'fb_addToCart';
