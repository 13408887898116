import updateAtIndex from 'utils/immutable/updateAtIndex';
import * as types from './types';

const initialState = {
  steps: [],
  currentStep: 0,
  hybridCheckoutFlow: {
    fetching: null,
  },
};

function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.SET_STATE:
      return payload.state;

    case types.SET_STEPS:
      return setSteps(state, payload);

    case types.SET_CURRENT_STEP_BY_ID:
      return setCurrentStepById(state, payload);

    case types.SET_STEP_DISABLED_BY_ID:
      return setStepDisabledById(state, payload);

    case types.SET_LAST_ENABLED_STEP:
      return setLastEnabledStep(state, payload);

    case types.SET_HYBRID_CHECKOUT_FETCHING:
      return setHybridCheckoutFetching(state, payload);

    case types.RESET_CHECKOUT_WIZARD:
      return initialState;

    default:
      return state;
  }
}

export default reducer;

function setSteps(state, { steps }) {
  return {
    ...state,
    steps,
  };
}

function setCurrentStepById(state, { stepId }) {
  const nextStepIndex = state.steps.findIndex(({ id }) => id === stepId);
  const currentStep = nextStepIndex !== -1 ? nextStepIndex : state.currentStep;

  return {
    ...state,
    currentStep,
  };
}

function setStepDisabledById(state, { stepId, disabled }) {
  const stepIndex = state.steps.findIndex(({ id }) => id === stepId);
  const steps = stepIndex !== -1 ? updateAtIndex(state.steps, stepIndex, { disabled }) : state.steps;

  return {
    ...state,
    steps,
  };
}

function setLastEnabledStep(state, { stepId }) {
  const stepIndex = state.steps.findIndex(({ id }) => id === stepId);
  let steps = state.steps;

  if (stepIndex !== -1) {
    for (var i = stepIndex + 1; i < steps.length; i++) {
      steps = updateAtIndex(steps, i, { disabled: true });
    }
  }

  return {
    ...state,
    steps,
  };
}

function setHybridCheckoutFetching(state, { fetching }) {
  return {
    ...state,
    hybridCheckoutFlow: {
      ...state.hybridCheckoutFlow,
      fetching,
    },
  };
}
