import * as types from './types';
import mapDestinationsToCountries from './selectors/mapDestinationsToCountries';

const initialState = {
  isFetching: false,
  destinations: [],
  selectedDestinations: [],
  destinationToCountryMap: {},
  availableDestinations: [],
  availableDestinationsMap: {},
  hotelOnly: false,
  forceSingleValue: false,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case types.REQUEST_DESTINATIONS:
      return requestDestinations(state);

    case types.SET_DESTINATIONS:
      return setDestinations(state, action.payload);

    case types.SET_SELECTED_DESTINATIONS:
      return setSelectedDestinations(state, action.payload);

    case types.SET_HOTEL_ONLY:
      return setHotelOnly(state, action.payload);

    case types.SET_FORCE_SINGLE_VALUE:
      return setForceSingleValue(state, action.payload);

    case types.SET_AVAILABLE_DESTINATIONS:
      return setAvailableDestinations(state, action.payload);

    case types.SET_AVAILABLE_DESTINATIONS_MAP:
      return setAvailableDestinationsMap(state, action.payload);

    case types.RESET_SELECTED_DESTINATIONS:
      return resetSelectedDestinations(state);

    default:
      return state;
  }
}

export default reducer;

function requestDestinations(state) {
  return {
    ...state,
    isFetching: true,
  };
}

function setDestinations(state, { destinations }) {
  return {
    ...state,
    destinations,
    destinationToCountryMap: mapDestinationsToCountries(destinations),
    isFetching: false,
  };
}

function setSelectedDestinations(state, { selectedDestinations }) {
  if (state.hotelOnly && selectedDestinations.length > 1) {
    selectedDestinations.shift();
  }

  return {
    ...state,
    selectedDestinations,
  };
}

function setHotelOnly(state, { hotelOnly }) {
  return {
    ...state,
    selectedDestinations: hotelOnly ? state.selectedDestinations.slice(0, 1) : state.selectedDestinations,
    availableDestinations: hotelOnly ? [] : state.availableDestinations,
    hotelOnly,
  };
}

function setForceSingleValue(state, { forceSingleValue }) {
  return {
    ...state,
    forceSingleValue,
  };
}

function setAvailableDestinations(state, { availableDestinations, forceSingleValue }) {
  if (state.destinations.length === 1 && state.destinations[0].destinations.length === 1) {
    forceSingleValue = true;
  }

  return {
    ...state,
    availableDestinations,
    forceSingleValue,
  };
}

function setAvailableDestinationsMap(state, { availableDestinations, availableDestinationsMap, forceSingleValue }) {
  if (state.destinations.length === 1 && state.destinations[0].destinations.length === 1) {
    forceSingleValue = true;
  }

  return {
    ...state,
    availableDestinationsMap,
    availableDestinations,
    forceSingleValue,
  };
}

function resetSelectedDestinations(state) {
  return {
    ...state,
    selectedDestinations: initialState.selectedDestinations,
    availableDestinations: initialState.availableDestinations,
  };
}
