import * as types from './types';

const initialState = {
  isFetching: false,
  value: [],
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case types.REQUEST_POPULAR_HOTELS:
      return requestPopularHotels(state);

    case types.SET_POPULAR_HOTELS:
      return setPopularHotels(state, action.payload);

    default:
      return state;
  }
}

export default reducer;

function requestPopularHotels(state) {
  return {
    ...state,
    isFetching: true,
  };
}

function setPopularHotels(state, { value }) {
  return {
    ...state,
    value,
  };
}
