import { keyframes } from 'styled-components';
import { rgba } from 'polished';

export const fadeIn = keyframes`
  0%   { opacity: 0; }
  100% { opacity: 1; }
`;

export const heartbeat = keyframes`
  0% { transform: scale( .75 ); }
  33% { transform: scale( 1 ); }
  66% { transform: scale( .75 ); }
  100% { transform: scale( 1 ); }
`;

export const slideDown = (negativeStartAmount) => keyframes`
  0%   {
    transform: translateY(-${negativeStartAmount}px);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
  }
  100% {
    transform: translateY(0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
`;

export const fadeInRotateAndScale = keyframes`
  0%   {
    transform: scale(0.75) rotate(5deg);
    opacity: 0;
  }
  100% {
    transform: scale(1) rotate(0deg);
    opacity: 0.9;
  }
`;

export const bounceSimple = (translateAmount = 20) => keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-${translateAmount}px); }
  100% { transform: translateY(0); }
`;

export const backgroundBlink = function (backgroundColor) {
  return keyframes`
    0% { background-color: ${rgba(backgroundColor, 0.7)}; }
    100% { background-color: ${rgba(backgroundColor, 0)}; }
  `;
};
export const shake = keyframes`

  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
`;

export const packagePulsating = keyframes`
 0% {
    -webkit-box-shadow: 0 0 0 0 rgba(10,148,148, 0.5);
  }
  70% {
      -webkit-box-shadow: 0 0 0 12px rgba(10,148,148, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(10,148,148, 0);
  }
`;