export const LUGGAGE = 'luggage';
export const CABIN_LUGGAGE = 'cabinLuggage';
export const PERSONAL_ITEM = 'personalItem';
export const MEAL = 'meal';
export const PRIORITY_BOARDING = 'priorityBoarding';
export const PRIORITY_BUNDLE = 'priorityBundle';
export const SEAT_RESERVATION = 'seatReservation';
export const CO2_COMPENSATION = 'co2Compensation';
export const TRANSFERS = 'transfers';
export const INSURANCES = 'insurances';
export const CANCELLATION_PROTECTION = 'cancellationProtection';
export const CAR_RENTAL = 'carRental';
export const EARTH_TODAY = 'earthToday';
