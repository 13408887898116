/**
 * Possible routes
 * Every page that is not using the template resolver should have a key here.
 * Routes defined in `systemPages` should use the `systemPages` property name as key.
 * When updating this file also update `server\utils\routes\constants\routesKeys.js`.
 */
export const ACCOUNT = 'account';
export const AGENT_LOGIN = 'agentLogin';
export const CONFIRMATION = 'confirmation';
export const COUNTRY_SELECTION = 'countrySelection';
export const CUSTOMER_SERVICE = 'customerService';
export const DEAL = 'deal';
export const DEALS = 'deals';
export const FRONTPAGE = 'frontpage';
export const HOTELPAGE = 'checkout';
export const NOT_FOUND = 'notFound';
export const TEMPLATE = 'template';
export const THANK_YOU = 'thankYou';
export const SAVED_PACKAGES = 'myFavorites';
export const SEARCH = 'search';
export const SEARCH_LANDING = 'searchLanding';
export const LAST_MINUTE = '/lastminute';
export const SUCCESS_BOOKING = 'successBooking';
