/**
 * Replaces the item at the given position with the new item.
 * @param {Array} array - Array in which the element will be replaced.
 * @param {Number} index - Position at which the element will be replaced.
 * @param {*} item - Item that will be replaced in the arra.
 * @returns {Array} New array with replaced item.
 */
export default function immmutableReplaceAtIndex(array, index, item) {
  return [...array.slice(0, index), item, ...array.slice(index + 1)];
}
