/**
 * Available Pages
 * Components from the `/src/pages` folder.
 * Every component from the `src/pages` folder should have a corresponding key here.
 * When updating this file also update `server/utils/routes/constants/pages.js`.
 */
export const ACCOUNT = '/account';
export const AGENT_LOGIN = '/agentLogin';
export const CONFIRMATION = '/confirmation';
export const COUNTRY_SELECTION = '/countrySelection';
export const DEAL = '/deal';
export const DEALS = '/deals';
export const FRONTPAGE = '/frontpage';
export const HOTELPAGE = '/checkout';
export const NOT_FOUND = '/notFound';
export const TEMPLATE = '/template';
export const THANK_YOU = '/thankYou';
export const SAVED_PACKAGES = '/myFavorites';
export const SEARCH = '/search';
export const LANDING_PAGE = '/lp';
export const LAST_MINUTE = '/lastminute';
export const SUCCESS_BOOKING = '/successBooking';
