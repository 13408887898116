import * as types from './types';

const initialState = {
  selectedOrigin: null,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_SELECTED_ORIGIN:
      return setSelectedOrigin(state, action.payload);

    default:
      return state;
  }
}

export default reducer;

function setSelectedOrigin(state, { selectedOrigin }) {
  return {
    ...state,
    selectedOrigin,
  };
}
