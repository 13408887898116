import airBalticValidation from './airBaltic';
import norwegianValidation from './norwegian';

function validateRewardNumber(rewardNumber, airline) {
  if (airline) {
    if (airline.isAirBaltic) {
      return airBalticValidation(rewardNumber);
    } else if (airline.isNorwegian) {
      return norwegianValidation(rewardNumber);
    }
  }

  return {
    valid: true,
    message: null,
  };
}

export default validateRewardNumber;
