import * as types from './types';

export function requestAffiliate(fetching) {
  return {
    type: types.REQUEST_AFFILIATE,
    payload: {
      fetching,
    },
  };
}

export function setAffiliate(affiliate) {
  return {
    type: types.SET_AFFILIATE,
    payload: {
      affiliate,
    },
  };
}

export function setAgentId(agentId) {
  return {
    type: types.SET_AGENT_ID,
    payload: {
      agentId,
    },
  };
}

export function resetAgentId() {
  return {
    type: types.RESET_AGENT_ID,
  };
}
