export function setCountriesCoordinates(countries) {
  const coordinates = {
    AR: { latitude: '-38.4161', longitude: '-63.6167' },
    BE: { latitude: '50.5039', longitude: '4.4699' },
    BR: { latitude: '-14.2350', longitude: '-51.9253' },
    BG: { latitude: '42.7339', longitude: '25.4858' },
    CY: { latitude: '35.1264', longitude: '33.4299' },
    DK: { latitude: '56.2639', longitude: '9.5018' },
    EG: { latitude: '26.8206', longitude: '30.8025' },
    EE: { latitude: '58.5953', longitude: '25.0136' },
    FI: { latitude: '61.9241', longitude: '25.7482' },
    AE: { latitude: '23.4241', longitude: '53.8478' },
    FR: { latitude: '46.2276', longitude: '2.2137' },
    GR: { latitude: '39.0742', longitude: '21.8243' },
    IE: { latitude: '53.1424', longitude: '-7.6921' },
    IS: { latitude: '55.1424', longitude: '-8.6921' },
    IL: { latitude: '31.0461', longitude: '34.8516' },
    IT: { latitude: '41.8719', longitude: '12.5674' },
    JO: { latitude: '30.5852', longitude: '36.2384' },
    CN: { latitude: '39.9138', longitude: '116.3636' },
    HR: { latitude: '45.1000', longitude: '15.2000' },
    LV: { latitude: '56.8796', longitude: '24.6032' },
    LT: { latitude: '55.1694', longitude: '23.8813' },
    MK: { latitude: '41.6086', longitude: '21.7453' },
    MT: { latitude: '35.9375', longitude: '14.3754' },
    MA: { latitude: '31.7917', longitude: '7.0926' },
    NL: { latitude: '52.1326', longitude: '5.2913' },
    NO: { latitude: '60.4720', longitude: '8.4689' },
    AT: { latitude: '47.5162', longitude: '14.5501' },
    PL: { latitude: '51.9194', longitude: '19.1451' },
    PT: { latitude: '39.3999', longitude: '-8.2245' },
    QA: { latitude: '25.3548', longitude: '51.1839' },
    RO: { latitude: '45.9432', longitude: '24.9668' },
    RU: { latitude: '61.5240', longitude: '105.3188' },
    CH: { latitude: '46.8182', longitude: '8.2275' },
    RS: { latitude: '44.0165', longitude: '21.0059' },
    ES: { latitude: '40.4637', longitude: '-3.7492' },
    UK: { latitude: '55.3781', longitude: '-3.4360' },
    SE: { latitude: '60.1282', longitude: '18.6435' },
    TH: { latitude: '15.8700', longitude: '100.9925' },
    CZ: { latitude: '49.8175', longitude: '15.4730' },
    TR: { latitude: '60.6167', longitude: '17.4000' },
    DE: { latitude: '51.1657', longitude: '0.4515' },
    UA: { latitude: '48.3794', longitude: '31.1656' },
    HU: { latitude: '47.1625', longitude: '19.5033' },
    US: { latitude: '37.0902', longitude: '-95.7129' },
  };

  return countries.map((country) => {
    country.location = {
      coordinates: coordinates[country.code],
    };

    return country;
  });
}
