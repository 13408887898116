import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withAnimatedScrollTo from 'components/hocs/withAnimatedScrollTo';
import formatDataAttributes from 'utils/object/formatDataAttributes';
import InputComponent from './InputComponent';

const validHTMLInputTypes = ['email', 'file', 'number', 'password', 'search', 'tel', 'text'];
const validValidateOnValues = ['blur'];
const propTypes = {
  type: PropTypes.oneOf(validHTMLInputTypes),
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  validity: PropTypes.shape({
    valid: PropTypes.bool.isRequired,
    message: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.node.isRequired,
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        defaultMessage: PropTypes.string.isRequired,
      }).isRequired,
    ]),
    clearOnFocus: PropTypes.bool,
  }),
  validateOn: PropTypes.oneOf(validValidateOnValues),
  scrollTo: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func.isRequired,
};

class Input extends Component {
  constructor(props) {
    super(props);

    this.state = {
      focused: false,
      validationVisible: props.validateOn === undefined,
    };

    this.handleOnFocus = this.handleOnFocus.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);
    this.dataAttr = formatDataAttributes(props.dataAttr);
  }

  componentDidUpdate() {
    const { validateOn, validity, scrollTo, scrollToComponent } = this.props;
    const { focused, validationVisible } = this.state;

    if (!validationVisible && validateOn === 'blur' && validity && !validity.valid && !focused) {
      this.setState({
        validationVisible: true,
      });
    }

    if (scrollTo && validationVisible && validity && validity.valid === false) {
      scrollToComponent('multiple');
    }
  }

  handleOnFocus(event) {
    const { validateOn, onFocus } = this.props;
    const nextState = { focused: true };

    if (validateOn === 'blur') {
      nextState.validationVisible = false;
    }

    this.setState(nextState);
    onFocus && onFocus(event);
  }

  handleOnBlur(event) {
    const { validateOn, onBlur } = this.props;
    const nextState = { focused: false };

    if (validateOn === 'blur') {
      nextState.validationVisible = true;
    }

    this.setState(nextState);
    onBlur && onBlur(event);
  }

  render() {
    const { componentsRefs, validity, label, styles, name, ...props } = this.props;

    return (
      <InputComponent
        componentsRefs={componentsRefs}
        validity={validity}
        {...this.state}
        label={label}
        styles={styles}
        name={name}
        nativeInputProps={{
          ...props,
          onFocus: this.handleOnFocus,
          onBlur: this.handleOnBlur,
        }}
        dataAttr={this.dataAttr}
      />
    );
  }
}

Input.propTypes = propTypes;

export default withAnimatedScrollTo()(Input);
