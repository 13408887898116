import * as types from './types';

export function updateColors(colors) {
  return {
    type: types.UPDATE_COLORS,
    payload: {
      colors,
    },
  };
}

export function updateFonts(fonts) {
  return {
    type: types.UPDATE_FONTS,
    payload: {
      fonts,
    },
  };
}

export function updateTouchDevice(touchDevice) {
  return {
    type: types.UPDATE_TOUCH_DEVICE,
    payload: {
      touchDevice,
    },
  };
}
