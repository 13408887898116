import _sortBy from 'lodash/sortBy';
import * as types from './types';

const initialState = {
  fetching: false,
  insurances: null,
  validity: {
    valid: null,
    message: null,
  },
  currentTokens: null,
};

function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.SET_STATE:
      return payload.state;

    case types.REQUEST_INSURANCES:
      return requestInsurances(state, payload);

    case types.SET_INSURANCES:
      return setInsurances(state, payload);

    case types.SET_CURRENT_TOKENS:
      return setCurrentTokens(state, payload);

    case types.SET_VALIDITY:
      return setValidity(state, payload);

    case types.RESET_INSURANCES:
      return initialState;

    default:
      return state;
  }
}

export default reducer;

function requestInsurances(state, { fetching }) {
  return {
    ...state,
    fetching,
  };
}

function setInsurances(state, { insurances }) {
  return {
    ...state,
    fetching: false,
    insurances: _sortBy(insurances, (insurance) => parseInt(insurance.perPerson)),
  };
}

function setCurrentTokens(state, { currentTokens }) {
  return {
    ...state,
    currentTokens,
  };
}

function setValidity(state, { validity }) {
  return {
    ...state,
    validity,
  };
}
