export default function getVehicleRentalsPrice(selectedVehicleRentals) {
  if (!selectedVehicleRentals) {
    return 0;
  }

  return selectedVehicleRentals.map(getVehicleRentalPaymentAmount).reduce(getVehicleRentalsPaymentAmountSum, 0);
}

function getVehicleRentalPaymentAmount(vehicleRental) {
  return vehicleRental.prices.payment.amount;
}

function getVehicleRentalsPaymentAmountSum(accumulator, currentValue) {
  return accumulator + currentValue;
}
