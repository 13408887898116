import React from 'react';
import { FormattedNumber } from 'react-intl';

// Note: the `style` prop in FormattedCurrency is added just because the `style` prop in FormattedNumber
// will raise a warning from the linter: "style prop value must be an object"
function FormattedCurrency({ value, currency, style = 'currency' }) {
  if (!currency) {
    return null;
  }

  return (
    <FormattedNumber
      value={value}
      style={style}
      currency={currency}
      minimumFractionDigits={0}
      maximumFractionDigits={0}
      useGrouping={true}
    />
  );
}

export default FormattedCurrency;
