export default function sortByScore(a, b) {
  if (a.hotelScore > b.hotelScore) {
    return -1;
  }

  if (a.hotelScore < b.hotelScore) {
    return 1;
  }

  return 0;
}
