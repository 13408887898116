import * as types from './types';

export function setCurrentPageComponent(value) {
  return {
    type: types.SET_CURRENT_PAGE_COMPONENT,
    payload: {
      value,
    },
  };
}
