import { getSearchResults } from 'httpClient/requests/searchResults';
import { getThemes } from 'httpClient/requests/templates/themes';
import { setThemes, setThemesPackages, updatePackagesForTheme, setFetching } from './actions';
import * as fetchingStatus from 'constants/status/fetchingStatus';

export function fetchThemes() {
  return (dispatch, getState) => {
    const { fetching } = getState().templates.themes;

    if (fetching === fetchingStatus.IN_PROGRESS) {
      return;
    }

    dispatch(setFetching(fetchingStatus.IN_PROGRESS));

    return getThemes()
      .then((response) => {
        dispatch(setThemes(response.data));
        dispatch(setFetching(fetchingStatus.SUCCEEDED));
      })
      .catch((error) => {
        dispatch(setFetching(fetchingStatus.FAILED));
        console.error(error);
      });
  };
}

export function fetchThemesPackages() {
  return (dispatch, getState) => {
    const packages = {};
    const missingPackages = {};
    const adverts = getState().adverts.adverts;
    const themes = getState().templates.themes.themes;

    themes.forEach((theme) => {
      let themePackages = [];

      // search for themes packages in adverts
      for (var i = 0; i < adverts.length; i++) {
        const advert = adverts[i];

        if (advert.hotel.themeIds.indexOf(theme.id) !== -1) {
          themePackages.push(advert);
        }

        if (themePackages.length === 5) {
          break;
        }
      }

      // fetch missing packages, if any
      if (themePackages.length < 5) {
        const currentHotelIds = themePackages.map((packet) => packet.hotel.id);
        const unusedHotelIds = theme.exampleHotelIds
          .filter((hotelId) => currentHotelIds.indexOf(hotelId) === -1)
          .slice(0, 5 - currentHotelIds.length);

        if (unusedHotelIds.length > 0) {
          missingPackages[theme.id] = unusedHotelIds;
        }
      }

      packages[theme.id] = themePackages;
    });

    dispatch(setThemesPackages(packages));

    const themeIdsKeys = Object.keys(missingPackages);
    if (themeIdsKeys.length > 0) {
      themeIdsKeys.forEach((themeId) => {
        dispatch(searchThemePackages(themeId, missingPackages[themeId]));
      });
    }
  };
}

// Private functions
function searchThemePackages(themeId, hotelsIds) {
  return (dispatch) => {
    const params = buildSearchParameters(hotelsIds);

    getSearchResults(params)
      .then((response) => {
        const packages = formatSearchResultsAsThemePackages(response.data);
        dispatch(updatePackagesForTheme(themeId, packages));
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

function buildSearchParameters(hotelsIds) {
  return {
    origins: [],
    destinations: [],
    dates: {
      from: '',
      to: '',
      flexibleDates: true,
    },
    roomConfigurations: [
      {
        numberOfAdults: 2,
        numberOfChildren: 0,
        childrenAges: [],
      },
    ],
    hotelIds: hotelsIds,
    sort: 'rand',
    limit: hotelsIds.length,
  };
}

function formatSearchResultsAsThemePackages(data) {
  const { hotels, results } = data;
  const packages = [];

  results.forEach((result) => {
    const hotel = hotels.filter((hotel) => hotel.id === result.hotelId)[0];
    const packet = {
      airport: result.airport,
      board: result.board,
      dates: result.dates,
      destination: {
        name: hotel.location.city,
      },
      hotel: {
        id: hotel.id,
        images: hotel.images.map((image) => ({ source: image })),
        name: hotel.name,
        score: hotel.score,
        shortDescription: hotel.shortDescription,
        stars: hotel.stars,
        slug: hotel.slug,
        hotelSlug: hotel.hotelSlug,
      },
      price: result.price,
      room: result.room,
    };

    packages.push(packet);
  });

  return packages;
}
