/**
 * Returns object where properties are the destinations codes
 * and value is the country code for that destination.
 * @param {Array} countries Array containing destinations from the response.
 * @returns {Object}
 */
export default function mapDestinationsToCountries(countries) {
  let result = {};

  for (let i = 0; i < countries.length; i++) {
    const destinations = countries[i].destinations;
    for (let j = 0; j < destinations.length; j++) {
      if (result[destinations[j].code] === undefined) {
        result[destinations[j].code] = countries[i].code;
      }
    }
  }

  return result;
}
