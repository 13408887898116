import * as types from './types';

const initialState = {
  promotionCode: '',
  validity: null,
  validating: false,
};

function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.SET_STATE:
      return payload.state;

    case types.SET_PROMOTION_CODE:
      return setPromotionCode(state, payload);

    case types.REQUEST_PROMOTION_CODE_VALIDITY:
      return requestPromotionCodeValidity(state, payload);

    case types.SET_PROMOTION_CODE_VALIDITY:
      return setPromotionCodeValidity(state, payload);

    case types.RESET_PROMOTION_CODE:
      return initialState;

    default:
      return state;
  }
}

export default reducer;

function setPromotionCode(state, { promotionCode }) {
  return {
    ...state,
    promotionCode,
  };
}

function requestPromotionCodeValidity(state, { validating }) {
  return {
    ...state,
    validating,
  };
}

function setPromotionCodeValidity(state, { validity }) {
  return {
    ...state,
    validity,
    validating: false,
  };
}
