import isPnoFormat from 'validators/isPnoFormat';
import * as inputNames from '../constants/inputNames';

/**
 *
 * @param {string} name - Name of the input value.
 * @param {*} value - Input field value.
 * @param {boolean} required - Indicates if it should validate empty values.
 * @returns {boolean} - Returns true if the value is valid, otherwise false.
 */
export default function validateInputValue(name, value, tld, required) {
  let valid;

  if (required && !value) {
    return {
      valid: false,
      message: {
        id: 'missingValue.tooltip',
        description: 'Please fill in this field',
        defaultMessage: 'Please fill in this field',
      },
      clearOnFocus: true,
    };
  }

  switch (name) {
    case inputNames.PNO:
      valid = isPnoFormat(value, tld);
      return {
        valid,
        message: !valid && {
          id: 'pno.invalid.tooltip',
          description: 'The PNO format is incorrect',
          defaultMessage: 'The PNO format is incorrect',
        },
      };

    default:
      return {
        valid: true,
        message: null,
      };
  }
}
