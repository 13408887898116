import * as types from './types';

const initialState = {
  informUser: null,
};

function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.SET_STATE:
      return payload.state;

    case types.SET_INFORM_USER:
      return setUserInformed(state, payload);

    case types.RESET_STATE:
      return initialState;

    default:
      return state;
  }
}

export default reducer;

function setUserInformed(state, { informUser }) {
  return {
    ...state,
    informUser,
  };
}
