import getSelectedHotelsRoomsWithSelectedOption from './getSelectedHotelsRoomsWithSelectedOption';

export default function getRoomProductsForGTM() {
  return (dispatch) => {
    const selectedHotelRooms = dispatch(getSelectedHotelsRoomsWithSelectedOption());
    return selectedHotelRooms.map((room) => ({
      name: `${room.hotelId}-${room.selectedOption.name}`,
      id: room.selectedOption.id,
      price: room.selectedOption.upgradePrice.total,
    }));
  };
}
