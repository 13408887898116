import getInitialState from './transforms/getInitialState';
import * as types from './types';

const initialState = getInitialState();

function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.SET_STATE:
      return payload.state;

    case types.SET_FETCHING:
      return setFetching(state, payload);

    case types.SET_MENU:
      return setMenu(state, payload);

    case types.RESET_STATE:
      return initialState;

    default:
      return state;
  }
}

export default reducer;

function setFetching(state, { menuName, fetching }) {
  return {
    ...state,
    [menuName]: {
      ...state[menuName],
      fetching,
    },
  };
}

function setMenu(state, { menuName, value }) {
  return {
    ...state,
    [menuName]: {
      ...state[menuName],
      value,
    },
  };
}
