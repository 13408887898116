import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { ensureIntlSupport } from 'polyfills';
import { setFlag } from 'state/flags/actions';
import LocizeContext, { withContext } from './context';
import FC from './components/FormattedCurrency';
import { FormattedMessage as FM, IntlProvider as IP, ReactIntlErrorCode, useIntl } from 'react-intl';
import axios from 'axios';
import _isEmpty from 'lodash/isEmpty';

const DEFAULTNAMESPACE = 'app';
const translations = {};

class IntlProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      locale: null,
      currency: null,
      messages: {},
    };
  }

  componentDidMount() {
    this.loadTranslations();
  }

  loadTranslations() {
    const { locale: settingsLocale, namespace = DEFAULTNAMESPACE, customSettings, websiteId } = this.props;

    const { language, currency } = settingsLocale;

    let locale = language;

    if (locale === 'no') {
      locale = 'nb';
    }

    // no need to load the messages again for Mapbox
    if (this.props.forMapboxMap) {
      this.setState({
        locale,
        currency,
        messages: translations[locale],
      });

      return;
    }

    // return if already loaded
    if (locale && translations[locale]) {
      return;
    }

    this.initiateIntl(
      namespace,
      locale,
      currency,
      customSettings && customSettings.locizeNamespace ? customSettings.locizeNamespace : null,
      websiteId ? websiteId : null
    );
  }

  initiateIntl(namespace, locale, currency, locizeNamespace, websiteId) {
    ensureIntlSupport(locale, () => {
      import(`moment/locale/${locale === 'en' ? 'en-gb' : locale}`).then(() => moment.locale(locale));
      axios
        .get('/api/translations', { params: { locale, namespace, locizeNamespace, websiteId } })
        .then(({ data }) => {
          translations[locale] = data;

          this.setState({
            locale,
            currency,
            messages: data,
          });

          this.props.setFlag('translationsLoaded', true);
        })
        .catch((error) => {
          console.error(error);
        });
    });
  }

  render() {
    const { children, namespace = DEFAULTNAMESPACE } = this.props;
    const { locale, currency, messages } = this.state;

    return (
      <LocizeContext.Provider value={{ locale, namespace, currency }}>
        <IP locale={locale} messages={messages} onError={onReactIntlError}>
          {children}
        </IP>
      </LocizeContext.Provider>
    );
  }
}

function mapStateToProps(state) {
  return {
    locale: state.settings.value.locale,
    airlineWhitelabel: state.settings.value.airlineWhitelabel,
    customSettings: state.settings.value.customSettings,
    websiteId: state.settings.value.websiteId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setFlag(flag, value) {
      dispatch(setFlag(flag, value));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IntlProvider);

export const FormattedMessage = ({ id, defaultMessage, fallbackId = ' ', ...props }) => {
  const intl = useIntl();

  if (!process.browser || _isEmpty(intl.messages)) {
    return null;
  }

  const _id = intl.messages[id] ? id : fallbackId;

  return <FM id={_id} defaultMessage={defaultMessage} {...props} />;
};

export const FormattedCurrency = withContext()(FC);

function onReactIntlError(error) {
  if (error.code === ReactIntlErrorCode.INVALID_CONFIG) {
    if (error.message.indexOf('"locale" was not configured') !== -1) {
      return;
    }
  } else if (error.code === ReactIntlErrorCode.MISSING_TRANSLATION) {
    return;
  }

  console.error(error);
}
