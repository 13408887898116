import * as types from './types';

const initialState = {
  termsTripx: false,
  termsKlarna: false,
  termsVehicleRental: false,
  agreeToMarketing: false,
  validity: null,
  allowEmailContact: false,
  allowPhoneContact: false,
};

function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.SET_CHECKBOX_VALUE:
      return setCheckboxValue(state, payload);

    case types.SET_CHECKBOX_VALIDITY:
      return setCheckboxValidity(state, payload);

    case types.RESET_PAYMENT_CHECKBOXES:
      return initialState;

    default:
      return state;
  }
}

export default reducer;

function setCheckboxValue(state, { name, value }) {
  return {
    ...state,
    [name]: value,
    validity: null,
  };
}

function setCheckboxValidity(state, { validity }) {
  return {
    ...state,
    validity,
  };
}
