import * as types from './types';

const initialState = {
  open: false,
  hideSpinner: false,
  challengeContainerRef: null,
};

function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.SET_STATE:
      return payload.state;

    case types.SET_OPEN:
      return setOpen(state, payload);

    case types.SET_HIDE_SPINNER:
      return setHideSpinner(state, payload);

    case types.SET_CHALLENGE_CONTAINER_REF:
      return setChallengeContainerRef(state, payload);

    case types.RESET_STATE:
      return initialState;

    default:
      return state;
  }
}

export default reducer;

function setOpen(state, { open }) {
  return {
    ...state,
    open,
  };
}

function setHideSpinner(state, { hideSpinner }) {
  return {
    ...state,
    hideSpinner,
  };
}

function setChallengeContainerRef(state, { challengeContainerRef }) {
  return {
    ...state,
    challengeContainerRef,
  };
}
