import * as inputNames from 'state/checkoutPage/customerDetails/constants/inputNames';
import { setState, setAndValidateInputValue, setInputFieldValidity } from './actions';
import { validateInputValue, getValidationData, getCustomer, getTitleFromGender } from './selectors';
import calling_codes from 'static/data/countries_calling_codes.json';
import validateDateOfBirth from '../travelers/validations/validateDateOfBirth';
import getCountryCode from 'utils/countries/getCountryCode';

export function hydrateInitialState(useDummyData) {
  return async (dispatch, getState) => {
    const { tld, locale } = getState().settings.value;
    const { language } = locale;
    const isCountryCodeInState = getState().checkoutPageCustomerDetails.countryCode.value;

    let countriesNames;

    try {
      countriesNames = await import(`static/data/countriesNames/${language}/country.json`);
    } catch (e) {
      countriesNames = await import(`static/data/countriesNames/en/country.json`);
    }

    const countryCode = getCountryCode(language, tld);
    const country = countriesNames[countryCode];

    if (useDummyData) {
      dispatch(setState(getCustomer(useDummyData)));
    } else if (isCountryCodeInState === null && country) {
      dispatch(setAndValidateInputValue(inputNames.COUNTRY, { value: countryCode, label: country }));
      dispatch(hydrateCountryCallingCode(countryCode, country));
    }
  };
}

export function hydrateCountryCallingCode(countryCode, countryName) {
  return (dispatch) => {
    const callingCode = calling_codes.find(({ countryCode: x }) => x === countryCode);

    dispatch(
      setAndValidateInputValue(inputNames.COUNTRY_CODE, {
        countryCode,
        value: callingCode.callingCode,
        label: countryName,
      })
    );
  };
}

export function handleOnChangeInputValue(name, value, validity) {
  return (dispatch, getState) => {
    // Set and validate the value for the changed input.
    dispatch(setAndValidateInputValue(name, value, validity));

    // Here we handle side effects from changing the current input.
    switch (name) {
      case inputNames.COUNTRY:
        // On country change we need the update the country code as well but only
        // if a value for the phone has not been entered.
        const customerPhone = getState().checkoutPageCustomerDetails.phone.value;
        if (!customerPhone) {
          dispatch(hydrateCountryCallingCode(value.value));
        }
        break;

      case inputNames.EMAIL:
        // On email change we need to re-validate the verify email field.
        // Case then the verify email field was populated before the email field.
        const verifyEmail = getState().checkoutPageCustomerDetails.verifyEmail.value;

        if (verifyEmail) {
          dispatch(setAndValidateInputValue(inputNames.VERIFY_EMAIL, verifyEmail));
        }

        break;

      case inputNames.GENDER:
        // We populate the title based on the selected gender.
        dispatch(setAndValidateInputValue(inputNames.TITLE, getTitleFromGender(value.value)));
        break;

      case inputNames.FIRST_NAME:
      case inputNames.LAST_NAME:
      case inputNames.CITY:
      case inputNames.ADDRESS:
      case inputNames.POST_CODE:
        const shouldForceLatinLetters =
          getState().settings.value.customSettings && getState().settings.value.customSettings.forceLatinLetters;

        if (shouldForceLatinLetters) {
          const validity = validateInputValue(name, value, { shouldForceLatinLetters }, true);
          dispatch(setAndValidateInputValue(name, value, validity));
        }
        break;

      default:
        break;
    }
  };
}

export function validateCustomerData() {
  return (dispatch, getState) => {
    const customer = getState().checkoutPageCustomerDetails;
    const isVolaRo = getState().settings.value.websiteId === 64;
    const shouldForceLatinLetters =
      getState().settings.value.customSettings && getState().settings.value.customSettings.forceLatinLetters;
    let valid = true;

    for (const property in customer) {
      const data = getValidationData(customer, property);
      const additionalData = { ...data, shouldForceLatinLetters };
      let validity = validateInputValue(property, customer[property].value, additionalData, true);

      if (property === 'dateOfBirth') {
        validity = dispatch(validateDateOfBirth(null, null, null, customer.dateOfBirth.value));
      }

      if (property === 'postCode' && isVolaRo) {
        validity = validateInputValue(property, customer[property].value, additionalData, false);
      }

      if (!validity.valid) {
        dispatch(setInputFieldValidity(property, validity));
        valid = false;
      }
    }

    return valid;
  };
}

export function findInvalidField() {
  return (dispatch, getState) => {
    const customer = getState().checkoutPageCustomerDetails;
    let invalidField = null;

    for (const property in customer) {
      if (property === inputNames.TITLE || invalidField !== null) {
        continue;
      }

      if (customer[property].validity !== null && !customer[property].validity.valid) {
        invalidField = property;
      }
    }

    return invalidField;
  };
}
