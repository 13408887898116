import * as types from './types';

const initialState = {
  worldSearch: null,
};

function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.SET_WORLD_SEARCH:
      return setWorldSearch(state, payload);

    default:
      return state;
  }
}

export default reducer;

function setWorldSearch(state, { isWorldSearch }) {
  return {
    ...state,
    worldSearch: isWorldSearch,
  };
}
