import { getPopularHotels } from 'httpClient/requests/popularHotels';
import { setPopularHotels, requestPopularHotels } from './actions';

export function fetchPopularHotels() {
  return (dispatch, getState) => {
    const { isFetching } = getState().popularHotels;

    if (isFetching) {
      return;
    }

    dispatch(requestPopularHotels());
    return getPopularHotels()
      .then((response) => {
        let popularHotels = response.data;

        if (popularHotels.length > 10) {
          popularHotels = response.data.slice(0, 10);
        }

        dispatch(setPopularHotels(popularHotels));
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
