import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateSelectedFlightsExtras } from 'state/checkoutPage/travelSummary/actions';
import { updateUpgradesValidity } from 'state/checkoutPage/flightsDetails/actions';
import { FormattedMessage } from 'i18n';
import Select from 'components/inputs/select/Select';
import Option from './components/Option';
import SingleValue from './components/SingleValue';
import { rgba } from 'polished';

class SelectUpgrade extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedValue: null,
      selectedOptions: null,
    };

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnResetUpgrades = this.handleOnResetUpgrades.bind(this);
  }

  componentDidMount() {
    const { selectedFlightsExtras, touched, passengerType, passengerIndex, options, serviceKey } = this.props;
    const selectedFlightsExtrasData =
      (selectedFlightsExtras[passengerType] && selectedFlightsExtras[passengerType][passengerIndex]) || [];
    const selectedOption = options.find(
      (option) => selectedFlightsExtrasData.includes(option.value) && option.value !== 'na'
    );
    const noUpgradesOption = options.find((option) => option.value === 'na');

    if (touched || (selectedOption && selectedOption.value)) {
      this.setState({
        selectedValue: selectedOption ? selectedOption.value : noUpgradesOption.value,
        selectedOptions: selectedOption ? selectedOption : noUpgradesOption,
      });
    }

    document.addEventListener(`reset-${serviceKey}-upgrades`, this.handleOnResetUpgrades);
  }

  componentWillUnmount() {
    const { serviceKey } = this.props;
    document.removeEventListener(`reset-${serviceKey}-upgrades`, this.handleOnResetUpgrades);
  }

  handleOnResetUpgrades() {
    const { options } = this.props;
    this.handleOnChange(options[0]);
  }

  handleOnChange(data) {
    const { selectedValue } = this.state;
    const { selectedFlightsExtras, passengerType, passengerIndex } = this.props;
    const selectedFlightsExtrasData =
      (selectedFlightsExtras[passengerType] && selectedFlightsExtras[passengerType][passengerIndex]) || [];

    // Case when we clear selected option
    if (!data && selectedFlightsExtrasData.includes(selectedValue)) {
      this.setSelectedUpgrade(false, selectedValue);
      return;
    }

    if (selectedValue && selectedValue === data.value) {
      return;
    }

    if (selectedValue && selectedFlightsExtrasData.includes(selectedValue)) {
      this.setSelectedUpgrade(false, selectedValue);
    }

    this.setSelectedUpgrade(true, data.value, data.value === 'na');
  }

  setSelectedUpgrade(checked, upgradeToken, skipReducerUpdate) {
    const {
      serviceKey,
      direction,
      passengerType,
      passengerIndex,
      updateSelectedFlightsExtras,
      updateUpgradesValidity,
      options,
    } = this.props;

    this.setState({
      selectedValue: checked ? upgradeToken : null,
      selectedOptions: checked ? options.find((option) => option.value === upgradeToken) : null,
    });

    if (!skipReducerUpdate) {
      updateSelectedFlightsExtras({ checked, upgradeToken }, passengerType, passengerIndex);
    }

    updateUpgradesValidity(serviceKey, direction, passengerType, passengerIndex, { valid: true, message: null });
  }

  render() {
    const { selectedOptions } = this.state;
    const { validity, options, forceSelection, scrollTo } = this.props;
    const isUpgradeSelected = selectedOptions && selectedOptions.data && selectedOptions.data.price ? true : false;
    return (
      <Select
        options={options}
        validity={validity}
        styles={getSelectStyles}
        selectedOptions={selectedOptions}
        onChange={this.handleOnChange}
        config={{
          className: 'upgrades-options',
          components: {
            Option,
            SingleValue,
          },
          isClearable: !forceSelection,
          isSearchable: false,
        }}
        placeholder={<FormattedMessage id={'select.label'} description={'Select'} defaultMessage={'Select'} />}
        isUpgradeSelected={isUpgradeSelected}
        scrollTo={scrollTo}
      />
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { upgradesValidity } = state.checkoutPageFlightsDetails;
  const { serviceKey, direction, passengerType, passengerIndex } = ownProps;

  return {
    selectedFlightsExtras: state.checkoutPageTravelSummary.selectedFlightsExtras,
    touched:
      upgradesValidity[serviceKey][direction][passengerType] &&
      upgradesValidity[serviceKey][direction][passengerType][passengerIndex] &&
      upgradesValidity[serviceKey][direction][passengerType][passengerIndex].valid !== null,
    validity:
      upgradesValidity[serviceKey][direction][passengerType] &&
      upgradesValidity[serviceKey][direction][passengerType][passengerIndex],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateSelectedFlightsExtras(data, passengerType, passengerIndex) {
      dispatch(updateSelectedFlightsExtras(data, passengerType, passengerIndex));
    },
    updateUpgradesValidity(serviceKey, direction, passengerType, passengerIndex, validity) {
      dispatch(updateUpgradesValidity(serviceKey, direction, passengerType, passengerIndex, validity));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectUpgrade);

function getSelectStyles(colors, isUpgradeSelected) {
  return {
    valueContainer: (styles) => ({
      ...styles,
      overflow: 'hidden',
      padding: '5px 8px',
    }),
    control: (styles) => ({
      ...styles,
      boxShadow: 'none',
      borderRadius: '0px',
      borderColor: isUpgradeSelected ? rgba(colors.inputs.radio.backgroundChecked, 0.5) : colors.inputs.border.default,
      '&:hover': {
        borderColor: isUpgradeSelected
          ? rgba(colors.inputs.radio.backgroundChecked, 0.5)
          : colors.inputs.border.default,
      },
    }),
  };
}
