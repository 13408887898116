import getChargeablePersonsForRoom from './getChargeablePersonsForRoom';

export default function getChargeablePersons() {
  return (dispatch, getState) => {
    const { checkoutPagePackagesSearch } = getState();

    if (
      checkoutPagePackagesSearch.value === null ||
      (checkoutPagePackagesSearch.value && checkoutPagePackagesSearch.value.hotels.length === 0)
    ) {
      return null;
    }

    const { rooms } = checkoutPagePackagesSearch.value.hotels[0];
    return getChargeablePersonsForRoom(rooms);
  };
}
