import { ALL_FOR_COUNTRY } from 'constants/selectValues';
import formatDestination from './formatDestination';

const excludedCountries = ['CN'];
const countriesExcludedFromAllOfCountry = [];

export default function formatDestinationsForReactSelect({
  destinations,
  availableCountriesCodes,
  availableDestinations,
  selectedOriginsLength,
}) {
  const isOriginSelected = selectedOriginsLength > 0 && availableCountriesCodes.length > 0;
  const countries = []; // Used on PC
  const cities = []; // Used on PC
  const grouped = []; // Used on mobile

  destinations.forEach((country) => {
    const formatedDestinations = [];
    const disabled = isOriginSelected && availableCountriesCodes.indexOf(country.code) === -1;

    if (excludedCountries.indexOf(country.code) !== -1 || disabled) {
      return;
    }

    countries.push({
      value: country.code,
      label: country.name,
      disabled,
    });

    country.destinations.forEach((city) => {
      const disabled = isOriginSelected && availableDestinations.indexOf(city.code) === -1;
      const option = formatDestination(city, country, disabled);

      if (!disabled) {
        cities.push(option);
        formatedDestinations.push(option);
      }
    });

    // For countries with more than one destination we want to add the option to select all destinations
    if (formatedDestinations.length > 1 && countriesExcludedFromAllOfCountry.indexOf(country.code) === -1) {
      // It is important that the value for all `allForCountry` options is the same.
      // This way when we select single single `allForCountry` option all of the rest will be disabled/removed.
      // We don't want someone to select All of Spain and All of Italy...
      const allForCountryDestinations = [...formatedDestinations.filter(({ disabled }) => !disabled)];
      const optionAllForCountry = {
        value: `${ALL_FOR_COUNTRY}-${country.code}`,
        label: {
          id: 'allDestinationsForCountry.label',
          description: 'All of {country}',
          defaultMessage: 'All of {country}',
          values: { country: country.name },
        },
        disabled: allForCountryDestinations.length === 0,
        country: country.code,
        destinations: allForCountryDestinations,
        allForCountry: true,
      };

      cities.unshift(optionAllForCountry);
      formatedDestinations.unshift(optionAllForCountry);
    }

    grouped.push({
      label: country.name,
      countryCode: country.code,
      options: formatedDestinations,
      disabled,
    });
  });
  const result = {
    countries,
    cities,
    grouped,
  };

  return result;
}
