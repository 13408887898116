import React from 'react';
import { components } from 'react-select';
import { FormattedMessage, FormattedCurrency } from 'i18n';
import styled from 'styled-components';
function Option(props) {
  const { description, price } = props.data.data;

  return (
    <components.SingleValue {...props}>
      <Root>
        {price && (
          <CheckmarkWrap>
            <Checkmark />
          </CheckmarkWrap>
        )}
        {description && description.id ? <FormattedMessage {...description} /> : description}
        {price && (
          <b>
            &nbsp;&nbsp;(+
            <FormattedCurrency value={price} />)
          </b>
        )}
      </Root>
    </components.SingleValue>
  );
}

export default Option;

const Root = styled.div`
  display: flex;
`;
const CheckmarkWrap = styled.div`
  position: relative;
  height: 18px;
  width: 18px;
  opacity: 0.5;
  background-color: ${({ theme }) => theme.colors.inputs.radio.backgroundChecked};
  border: none;
  border-radius: 50%;
  margin-right: 12px;
`;

const Checkmark = styled.span`
  position: absolute;
  left: 6px;
  top: 2px;
  width: 6px;
  height: 11px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
`;
