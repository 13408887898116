export function getSelectedEarthToday(token, additionalServices) {
  if ((additionalServices && !additionalServices.earthToday) || !token) {
    return;
  }

  const { price, currency } = additionalServices.earthToday;

  return {
    token,
    price,
    currency,
  };
}
