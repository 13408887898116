import React from 'react';
import styled from 'styled-components';

function ArrowComponent({ severity }) {
  return <Root severity={severity} />;
}

export default ArrowComponent;

const Root = styled.div`
  border-color: transparent;
  border-style: solid;
  border-width: 6px 6px 0;
  border-top-color: ${({ severity, theme }) => theme.colors.background.severity[severity]};
`;
