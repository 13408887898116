import * as types from './types';

const initialState = {
  error: null,
};

function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.SET_STATE:
      return payload.state;

    case types.SET_ERROR:
      return setError(state, payload);

    default:
      return state;
  }
}

export default reducer;

function setError(state, { error }) {
  return {
    ...state,
    error,
  };
}
