import * as types from './types';

const initialState = {
  selectedHotel: null,
  isMapMoved: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SELECTED_HOTEL:
      state = setSelectedHotel(state, action.payload);
      break;

    case types.MAP_MOVED:
      state = setMapMoved(state, action.payload);
      break;
    default:
      break;
  }

  return state;
};

export default reducer;

// Private functions
const setSelectedHotel = (state, payload) => ({
  ...state,
  selectedHotel: payload.hotelId,
});

const setMapMoved = (state, payload) => ({
  ...state,
  isMapMoved: payload.isMapMoved,
});
