import * as types from './types';

const initialState = {
  fetching: null,
  value: {
    input: null,
    results: [],
  },
};

function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.SET_STATE:
      return payload.state;

    case types.SET_FETCHING:
      return setFetching(state, payload);

    case types.SET_INPUT_VALUE:
      return setInputValue(state, payload);

    case types.SET_RESULTS:
      return setResults(state, payload);

    case types.RESET_STATE:
      return initialState;

    default:
      return state;
  }
}

export default reducer;

function setFetching(state, { fetching }) {
  return {
    ...state,
    fetching,
  };
}

function setInputValue(state, { input }) {
  return {
    ...state,
    value: {
      ...state.value,
      input,
    },
  };
}

function setResults(state, { results }) {
  return {
    ...state,
    value: {
      ...state.value,
      results,
    },
  };
}
