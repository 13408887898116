export const TITLE = 'title';
export const FIRST_NAME = 'firstName';
export const LAST_NAME = 'lastName';
export const GENDER = 'gender';
export const DATE_OF_BIRTH = 'dateOfBirth';
export const PASSPORT_NUMBER = 'passportNumber';
export const REWARD_NUMBER = 'rewardNumber';

//travel document input types
export const TRAVEL_DOCUMENT_TYPE = 'travelDocumentType';
export const TRAVEL_DOCUMENT_NUMBER = 'travelDocumentNumber';
export const TRAVEL_DOCUMENT_COUNTRY_CODE = 'travelDocumentCountryCode';
export const TRAVEL_DOCUMENT_NATIONALITY = 'travelDocumentNationality';
export const TRAVEL_DOCUMENT_EXPIRATION = 'travelDocumentExpiration';
export const TRAVEL_DOCUMENT_RESIDENCE_COUNTRY = 'travelDocumentResidenceCountry';
export const TRAVEL_DOCUMENT_ISSUED = 'travelDocumentIssued';
