import * as types from './types';

const initialState = {
  fetching: false,
  additionalServices: null,
};

function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.SET_FETCHING:
      return setFetching(state, payload);

    case types.SET_ADDITIONAL_SERVICES:
      return setAdditionalServices(state, payload);

    case types.RESET_ADDITIONAL_SERVICES:
      return initialState;

    default:
      return state;
  }
}

export default reducer;

function setFetching(state, { fetching }) {
  return {
    ...state,
    fetching,
  };
}

function setAdditionalServices(state, { additionalServices }) {
  return {
    ...state,
    fetching: false,
    additionalServices,
  };
}
