import * as types from './types';

const initialState = {
  fetchingPayment: null,
  payment: null,
};

function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.SET_STATE:
      return payload.state;

    case types.SET_FETCHING_PAYMENT:
      return setFetchingPayment(state, payload);

    case types.SET_PAYMENT:
      return setPayment(state, payload);

    case types.RESET_PAYMENT_NETS_EASY:
      return initialState;

    default:
      return state;
  }
}

export default reducer;

function setFetchingPayment(state, { fetchingPayment }) {
  return {
    ...state,
    fetchingPayment,
  };
}

function setPayment(state, { payment }) {
  return {
    ...state,
    payment,
  };
}
