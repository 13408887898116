export default function setTripxCacheKey(params, headers = {}) {
  if (!process.browser) {
    return {};
  }

  const _params = { ...params };
  delete _params.affiliateId;

  headers['TripX-Cache-Key'] = btoa(JSON.stringify(_params));

  return headers;
}
