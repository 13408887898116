import 'react-app-polyfill/ie11';
import 'core-js/es/map';
import 'core-js/es/set';
import 'core-js/features/string/pad-start';
import 'core-js/features/string/repeat';
import 'core-js/features/array/includes';
import 'core-js/features/array/find';
import 'core-js/features/array/find-index';
import 'core-js/features/array/flat';
import 'core-js/features/object/values';
import 'core-js/features/object/keys';
import 'core-js/features/object/entries';
import 'core-js/features/symbol/iterator';
import 'core-js/features/map/filter';
import 'core-js/features/set/map';
import 'intersection-observer';
import 'es6-shim';
import 'custom-event-polyfill';

export function ensureIntlSupport(locale, callback) {
  if (!Intl.PluralRules) {
    import('@formatjs/intl-pluralrules/polyfill').then(() => {
      import(`@formatjs/intl-pluralrules/dist/locale-data/${locale}`).then(() => {
        callback();
      });
    });
  } else {
    callback();
  }
}
