import { css } from 'styled-components';
import axis from 'axis.js';

export const deviceWidths = {
  phone: 480,
  tablet: 768,
  desktop: 1024,
  l: 1280,
  xl: 1700,
  xxl: 2200,
};

const breakpoints = {
  below: {
    phone: deviceWidths.phone - 1,
    tablet: deviceWidths.tablet - 1,
    desktop: deviceWidths.desktop - 1,
    l: deviceWidths.l - 1,
    xl: deviceWidths.xl - 1,
    xxl: deviceWidths.xxl - 1,
  },
  fromAndBelow: {
    phone: deviceWidths.phone,
    tablet: deviceWidths.tablet,
    desktop: deviceWidths.desktop,
    l: deviceWidths.l,
    xl: deviceWidths.xl,
    xxl: deviceWidths.xxl,
  },
  above: {
    phone: deviceWidths.phone + 1,
    tablet: deviceWidths.tablet + 1,
    desktop: deviceWidths.desktop + 1,
    l: deviceWidths.l + 1,
    xl: deviceWidths.xl + 1,
    xxl: deviceWidths.xxl + 1,
  },
  fromAndAbove: {
    phone: deviceWidths.phone,
    tablet: deviceWidths.tablet,
    desktop: deviceWidths.desktop,
    l: deviceWidths.l,
    xl: deviceWidths.xl,
    xxl: deviceWidths.xxl,
  },
  hotelCard: 767,
};

const keys = Object.keys(breakpoints);

const mediaQueries = keys.reduce((acc, label) => {
  if (axis.isObject(breakpoints[label]) && Object.keys(breakpoints[label]).length) {
    // If nested value is object

    acc[label] = Object.keys(breakpoints[label]).reduce((acc, deviceLabel) => {
      acc[deviceLabel] = (...args) => css`
        @media (${label === 'below' || label === 'fromAndBelow' ? 'max-width' : 'min-width'}: ${breakpoints[label][
            deviceLabel
          ]}px) {
          ${css(...args)}
        }
      `;
      return acc;
    }, {});
  } else {
    // If nested value is not object just use max-width for that

    acc[label] = (...args) => css`
      @media (max-width: ${breakpoints[label]}px) {
        ${css(...args)}
      }
    `;
  }

  return acc;
}, {});

export { mediaQueries };

export function dynamicCss({ forMedia, rules }) {
  if (forMedia) {
    return css`
      ${mediaQueries[forMedia[0]][forMedia[1]]`
        ${rules}
      `}
    `;
  }

  return css`
    ${rules}
  `;
}
