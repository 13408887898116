import * as types from './types';

const initialState = {
  homeBoxes: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_HOME_BOXES:
      state = setHomeBoxes(state, action.payload);
      break;

    default:
      break;
  }

  return state;
};

export default reducer;

// Private functions
const setHomeBoxes = (state, payload) => {
  return {
    ...state,
    homeBoxes: payload.homeBoxes,
  };
};
