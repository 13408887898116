import { PRODUCTION as RUNTIME_HOST_ENV_PRODUCTION } from 'constants/env/runtimeHostEnv';
import localStorageBase from '../localStorageBase';

const devEnvsQueryLocalStorage = (function (ls) {
  const key = 'dev_envs_query';

  function getQuery() {
    if (process.env.RUNTIME_HOST_ENV === RUNTIME_HOST_ENV_PRODUCTION) {
      return '';
    }

    return ls.get(key);
  }

  function setQuery(query) {
    query = query.replace('?', '');

    ls.set(key, query);
  }

  return {
    get: getQuery,
    set: setQuery,
  };
})(localStorageBase);

export default devEnvsQueryLocalStorage;
