import { isNetworkOrIdempotentRequestError, exponentialDelay } from 'axios-retry';

function retryCondition(errorOrResponse) {
  // Response is fulfilled but data is null
  if (typeof errorOrResponse === 'object' && errorOrResponse.data === null) {
    return true;
  }

  if (typeof errorOrResponse === 'string') {
    if (errorOrResponse.indexOf('CORS') !== -1) {
      return true;
    }
  }

  return isNetworkOrIdempotentRequestError(errorOrResponse);
}

export default {
  retries: 1,
  retryDelay: exponentialDelay,
  retryCondition,
};
