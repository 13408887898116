import {
  LUGGAGE as LUGGAGE_SERVICE,
  CABIN_LUGGAGE as CABIN_LUGGAGE_SERVICE,
  MEAL as MEAL_SERVICE,
  PRIORITY_BUNDLE as PRIORITY_BUNDLE_SERVICE,
} from 'routes/checkoutPage/constants/servicesKeys';
import getKeyValuesFromObject from 'utils/array/getKeyValuesFromObject';

export default function getSelectedExtras() {
  return (dispatch, getState) => {
    const { checkoutPageTravelSummary, checkoutPageFlightsDetails, settings } = getState();
    const { selectedFlightsExtras } = checkoutPageTravelSummary;
    const { value } = checkoutPageFlightsDetails;
    const { currency } = settings.value.locale;

    if (!value || !selectedFlightsExtras) {
      return null;
    }

    const { PriorityBundle = {}, Luggage = {}, Meal = {}, CabinLuggage = {} } = value;

    const { ADT = {}, CHD = {}, INF = {}, GROUP = [] } = selectedFlightsExtras;
    const selectedFlightsExtrasData = [
      ...Object.values(ADT),
      ...Object.values(CHD),
      ...Object.values(INF),
      ...GROUP,
    ].flat();

    const resultSelectedFlightsExtras = Array.from(
      selectedFlightsExtrasData.reduce(
        (acc, currentValue) => acc.set(currentValue, (acc.get(currentValue) || 0) + 1),
        new Map()
      ),
      ([token, count]) => ({ token, count })
    );

    const upgrades = resultSelectedFlightsExtras
      .map(({ token, count }) => {
        return {
          luggage: transformLuggageUpgrades(Luggage, token, count, currency, LUGGAGE_SERVICE),
          cabinLuggage: transformLuggageUpgrades(CabinLuggage, token, count, currency, CABIN_LUGGAGE_SERVICE),
          meal: transformMealUpgrades(Meal, token, count, currency),
          priorityBundle: transformPriorityBundleUpgrades(PriorityBundle, token, count, currency),
        };
      })
      .reduce((acc, currentValue) => {
        Object.keys(currentValue).forEach((key) => {
          acc[key] = (acc[key] || []).concat([currentValue[key]]);
        });
        return acc;
      }, {});

    const { luggage = [], meal = [], priorityBundle = [], cabinLuggage = [] } = filterEmptyObjects(upgrades);
    const result = [...luggage, ...meal, ...priorityBundle, ...cabinLuggage].filter(
      (value) => Object.keys(value).length !== 0
    );
    return getKeyValuesFromObject(result, 'direction').reduce((acc, direction) => {
      if (!acc) {
        acc = {};
      }
      acc[direction] = result.filter((items) => items.direction === direction);
      return acc;
    }, null);
  };
}

function filterEmptyObjects(obj) {
  let newObj = {};
  for (let o in obj) {
    obj[o].forEach((item, index) => {
      if (Object.entries(item).length === 0) {
        obj[o].splice(index, 1);
      }
    });
    newObj = { ...obj };
  }
  return newObj;
}

function transformLuggageUpgrades(upgrades, token, count, currency, type) {
  const { combination = [], legs = [], flights = [] } = upgrades;
  upgrades = [...combination, ...legs, ...flights];
  const obj = {};
  upgrades.forEach(({ direction, departureAirport, destinationAirport, pieces }) => {
    pieces.forEach(({ upgradeToken, price, maxWeight, quantity }) => {
      if (token === upgradeToken) {
        obj['type'] = type;
        obj['price'] = count * parseInt(price[currency]);
        obj['description'] = `${count * quantity} ${maxWeight ? `x  ${maxWeight.value}` : ''} ${
          maxWeight ? maxWeight.unitOfMeasure : ''
        }`;
        obj['direction'] = direction;
        obj['departureAirport'] = departureAirport;
        obj['destinationAirport'] = destinationAirport;
      }
    });
  });
  return obj;
}

function transformMealUpgrades(upgrades, token, count, currency) {
  const { combination = [], legs = [], flights = [] } = upgrades;
  upgrades = [...combination, ...legs, ...flights];
  const obj = {};
  upgrades.forEach(({ direction, departureAirport, destinationAirport, pieces }) => {
    pieces.forEach(({ upgradeToken, description, price, quantity }) => {
      if (token === upgradeToken) {
        obj['type'] = MEAL_SERVICE;
        obj['description'] = `${count * quantity} x ${description}`;
        obj['price'] = count * parseInt(price[currency]);
        obj['direction'] = direction;
        obj['departureAirport'] = departureAirport;
        obj['destinationAirport'] = destinationAirport;
      }
    });
  });
  return obj;
}

function transformPriorityBundleUpgrades(upgrades, token, count, currency) {
  const { combination = [], legs = [], flights = [] } = upgrades;
  upgrades = [...combination, ...legs, ...flights];
  const obj = {};
  upgrades.forEach(({ direction, departureAirport, destinationAirport, pieces }) => {
    pieces.forEach(({ upgradeToken, name, price }) => {
      if (token === upgradeToken) {
        obj['type'] = PRIORITY_BUNDLE_SERVICE;
        obj['price'] = count * parseInt(price[currency]);
        obj['description'] = `${count} x ${name}`;
        obj['direction'] = direction;
        obj['departureAirport'] = departureAirport;
        obj['destinationAirport'] = destinationAirport;
      }
    });
  });
  return obj;
}
