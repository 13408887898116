import updateObject from 'utils/immutable/updateObject';
import * as types from './types';
import * as actionsIds from './constants/actionsIds';

const initialState = {
  actions: [
    { id: actionsIds.HEADER_MENU, icon: 'bars', disabled: false },
    { id: actionsIds.PRICES, icon: 'chart-bar', disabled: false },
    { id: actionsIds.MAP, icon: 'map-marker-alt', disabled: false },
  ],
  activeAction: null,
};

function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.SET_ACTIVE_ACTION:
      return setActiveAction(state, payload);

    case types.RESET_ACTIVE_ACTION:
      return resetActiveAction(state);

    case types.SET_DISABLE_FOR_ACTION:
      return setDisableForAction(state, payload);

    default:
      return state;
  }
}

export default reducer;

function setActiveAction(state, { action }) {
  return {
    ...state,
    activeAction: state.activeAction === action ? null : action,
  };
}

function resetActiveAction(state) {
  return {
    ...state,
    activeAction: initialState.activeAction,
  };
}

function setDisableForAction(state, { actionId, disabled }) {
  return {
    ...state,
    actions: state.actions.map((action) => {
      if (action.id !== actionId) {
        return action;
      }

      return updateObject(action, { disabled });
    }),
  };
}
