import React from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as flightDirections from 'state/checkoutPage/flightsDetails/constants/flightDirections';
import { FormattedMessage, FormattedCurrency } from 'i18n';
import CheckboxComponent from 'components/inputs/Checkbox/CheckboxComponent';
import servicesIconsMap from 'routes/checkoutPage/constants/servicesIconsMap';

function UpgradeComponent({
  checked,
  serviceKey,
  price,
  currency,
  direction,
  departureAirport,
  destinationAirport,
  carrierName,
  carriers,
  name,
  items,
  perPassenger,
  onChange,
  onClickUpgrade,
}) {
  const directionIcon = {
    [flightDirections.RETURN]: servicesIconsMap.PlaneArrival,
    [flightDirections.BOTH]: servicesIconsMap.Plane,
    [flightDirections.LEAVE]: servicesIconsMap.PlaneDeparture,
  };

  return (
    <Root onClick={onClickUpgrade}>
      <Heading>
        <Direction>
          <DirectionIcon icon={directionIcon[direction]} />
          <FormattedMessage
            id={`${direction.toLowerCase()}.label`}
            description={direction}
            defaultMessage={direction}
            tagName={'span'}
          />
          {departureAirport && (
            <span>
              ({departureAirport} - {destinationAirport})
            </span>
          )}
          <CarrierName>({carrierName})</CarrierName>
        </Direction>
      </Heading>
      <Card checked={checked}>
        <Header>
          {carriers && <CarriersImg alt='carriers' src={carriers} />}
          <Name>{name}</Name>
        </Header>
        <CardBody>
          {items.map((item, index) => (
            <PriorityItems key={index}>
              <Dot />
              <span>{item}</span>
            </PriorityItems>
          ))}
        </CardBody>
        <Upgades>
          <UpgadesContent>
            <CheckboxComponent rounded isChecked={checked} onChange={onChange} />
            <IncludePriority>
              <FormattedMessage
                id={`include.${serviceKey}.label`}
                description={`Include ${serviceKey}`}
                defaultMessage={`Include ${serviceKey}`}
              />
            </IncludePriority>
          </UpgadesContent>
          <UpgadesContent>
            <PriceContainer>
              <PriceDescription>
                {perPassenger ? (
                  <FormattedMessage
                    id={`pricePerPerson.label`}
                    description={'Price per person'}
                    defaultMessage={'Price per person'}
                  />
                ) : (
                  <FormattedMessage
                    id={`pricePerGroup.label`}
                    description={'Price per group'}
                    defaultMessage={'Price per group'}
                  />
                )}
              </PriceDescription>
              &nbsp;
              <Price>
                +<FormattedCurrency value={price[currency]} />
              </Price>
            </PriceContainer>
          </UpgadesContent>
        </Upgades>
      </Card>
    </Root>
  );
}

export default UpgradeComponent;

const Root = styled.div`
  cursor: pointer;
`;

const Heading = styled.div`
  font-weight: 600;
  margin-bottom: 15px;
  color: ${({ theme }) => theme.colors.text.default};
`;

const Header = styled.div`
  margin-left: 27px;
  padding-top: 12px;
  display: flex;
`;

const Card = styled.div`
  margin-bottom: 20px;
  box-shadow: 0px 3px 18px 0 rgba(0, 0, 0, 0.06);
  border: ${({ theme, checked }) =>
    checked
      ? `solid 1px ${rgba(theme.colors.inputs.radio.backgroundChecked, 0.5)}`
      : `solid 1px ${theme.colors.inputs.border.default}`};
`;

const CardBody = styled.div`
  font-size: 1.5em;
  font-weight: 600;
  padding: 8px 20px 0 20px;
  color: ${({ theme }) => theme.colors.text.light};
`;

const Upgades = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px;
  color: ${({ theme }) => theme.colors.text.default};

  label {
    padding-bottom: 10px;
  }
`;

const IncludePriority = styled.span`
  margin-right: 5px;
`;

const UpgadesContent = styled.div`
  font-size: 1.5em;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Direction = styled.div`
  font-size: 1.4em;

  span {
    margin-left: 10px;
  }
`;

const DirectionIcon = styled(FontAwesomeIcon)`
  font-size: 1em;
  color: ${({ theme }) => theme.colors.buttons.grey};
`;

const CarrierName = styled.span`
  margin-left: 8px;
`;

const PriorityItems = styled.div`
  display: flex;
  padding: 10px;
  color: ${({ theme }) => theme.colors.text.default};
  font-weight: 100;
`;

const Name = styled.span`
  padding-top: 8px;
  margin-left: 5px;
  color: ${({ theme }) => theme.colors.text.default};
  font-size: 1.6em;
  font-weight: bold;
`;

const CarriersImg = styled.img`
  width: 32px;
  height: 32px;
  object-fit: scale-down;
`;

const Dot = styled.span`
  position: relative;
  top: 3px;
  left: 4px;
  background: ${({ theme }) => theme.colors.text.light};
  width: 8px;
  min-width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
`;

const PriceDescription = styled.div`
  font-size: 0.8em;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.text.light};
  padding-top: 2px;
`;

const Price = styled.div``;

const PriceContainer = styled.div`
  padding-top: 3px;
  display: flex;
  justify-content: 'space-between';
`;
