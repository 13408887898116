import * as types from './types';

export function requestDestinations() {
  return {
    type: types.REQUEST_DESTINATIONS,
  };
}

export function setDestinations(destinations) {
  return {
    type: types.SET_DESTINATIONS,
    payload: {
      destinations,
    },
  };
}

export function setSelectedDestinations(selectedDestinations) {
  return {
    type: types.SET_SELECTED_DESTINATIONS,
    payload: {
      selectedDestinations,
    },
  };
}

export function setHotelOnly(hotelOnly) {
  return {
    type: types.SET_HOTEL_ONLY,
    payload: {
      hotelOnly,
    },
  };
}

export function setForceSingleValue(forceSingleValue) {
  return {
    type: types.SET_FORCE_SINGLE_VALUE,
    payload: {
      forceSingleValue,
    },
  };
}

export function setAvailableDestinations(availableDestinations, forceSingleValue) {
  return {
    type: types.SET_AVAILABLE_DESTINATIONS,
    payload: {
      availableDestinations,
      forceSingleValue,
    },
  };
}

export function setAvailableDestinationsMap(availableDestinations, availableDestinationsMap, forceSingleValue) {
  return {
    type: types.SET_AVAILABLE_DESTINATIONS_MAP,
    payload: {
      availableDestinations,
      availableDestinationsMap,
      forceSingleValue,
    },
  };
}

export function resetSelectedDestinations() {
  return {
    type: types.RESET_SELECTED_DESTINATIONS,
  };
}
