import React from 'react';
import styled from 'styled-components';
import { mediaQueries } from 'styles/utils/mediaQueries';
import Upgrade from './components/upgrade/Upgrade';

function SingleChoiceUpgradeComponent({
  serviceKey,
  title,
  upgrades,
  passengerType,
  passengerIndex,
  currency,
  groupUpgrade,
  perPassenger,
}) {
  if (!upgrades || upgrades.length === 0) {
    return null;
  }
  return (
    <Root groupUpgrade={groupUpgrade}>
      {title && (
        <Header>
          <Dot />
          {title}
        </Header>
      )}
      {upgrades.map(
        ({ pieces, direction, departureAirport, destinationAirport, carrierName, carriers, perPassenger }) =>
          pieces &&
          pieces.map(({ upgradeToken, name, price, items }, index) => (
            <Upgrade
              key={`${direction}-${index}`}
              passengerType={passengerType}
              passengerIndex={passengerIndex}
              serviceKey={serviceKey}
              currency={currency}
              direction={direction}
              departureAirport={departureAirport}
              destinationAirport={destinationAirport}
              carrierName={carrierName}
              carriers={carriers}
              upgradeToken={upgradeToken}
              name={name}
              items={items}
              price={price}
              perPassenger={perPassenger}
            />
          ))
      )}
    </Root>
  );
}

export default SingleChoiceUpgradeComponent;

const Root = styled.div`
  flex: ${({ groupUpgrade }) => (groupUpgrade ? '0 0 100%' : '0 0 49%')};

  ${mediaQueries.fromAndBelow.desktop`
		flex: 0 0 100%;
  `}
`;

const Header = styled.div`
  color: ${({ theme }) => theme.colors.text.default};
  font-size: 1.4em;
  font-weight: 600;
  padding-bottom: 10px;
  display: flex;
`;

const Dot = styled.span`
  position: relative;
  top: 3px;
  left: 4px;
  background: ${({ theme }) => theme.colors.inputs.radio.backgroundChecked};
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 20px;
`;
