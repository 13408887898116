import * as types from './types';

export const setThemes = (themes) => ({
  type: types.SET_THEMES,
  payload: {
    themes,
  },
});

export const setThemesPackages = (packages) => ({
  type: types.SET_THEMES_PACKAGES,
  payload: {
    packages,
  },
});

export const updatePackagesForTheme = (themeId, packages) => ({
  type: types.UPDATE_PACKAGES_FOR_THEME,
  payload: {
    themeId,
    packages,
  },
});
export function setFetching(fetching) {
  return {
    type: types.SET_FETCHING,
    payload: {
      fetching,
    },
  };
}
