import _merge from 'lodash/merge';
import theme from 'styles/themes/main';
import * as types from './types';

const initialState = {
  ...theme,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case types.UPDATE_COLORS:
      return updateColors(state, action.payload);

    case types.UPDATE_FONTS:
      return updateFonts(state, action.payload);

    case types.UPDATE_TOUCH_DEVICE:
      return updateTouchDevice(state, action.payload);

    default:
      return state;
  }
}

export default reducer;

function updateColors(state, { colors }) {
  return {
    ...state,
    colors: _merge({}, state.colors, colors),
  };
}

function updateFonts(state, { fonts }) {
  return {
    ...state,
    fonts: _merge({}, state.fonts, fonts),
  };
}

function updateTouchDevice(state, { touchDevice }) {
  return {
    ...state,
    touchDevice,
  };
}
