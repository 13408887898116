import * as types from './types';

const initialState = {
  fuelType: { options: null, value: null },
  transmissionType: { options: null, value: null },
  passengerCapacity: { options: null, value: null },
};

function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.SET_STATE:
      return payload.state;

    case types.SET_FUEL_TYPE_OPTIONS:
      return setFuelTypeOptions(state, payload);

    case types.SET_FUEL_TYPE_VALUE:
      return setFuelTypeValue(state, payload);

    case types.SET_TRANSMISSION_TYPE_OPTIONS:
      return setTransmissionTypeOptions(state, payload);

    case types.SET_TRANSMISSION_TYPE_VALUE:
      return setTransmissionTypeValue(state, payload);

    case types.SET_PASSENGER_CAPACITY_OPTIONS:
      return setPassengerCapacityOptions(state, payload);

    case types.SET_PASSENGER_CAPACITY_VALUE:
      return setPassengerCapacityValue(state, payload);

    case types.RESET_STATE:
      return initialState;

    default:
      return state;
  }
}

export default reducer;

function setFuelTypeOptions(state, { options }) {
  return {
    ...state,
    fuelType: {
      ...state.fuelType,
      options,
    },
  };
}

function setFuelTypeValue(state, { value }) {
  return {
    ...state,
    fuelType: {
      ...state.fuelType,
      value,
    },
  };
}

function setTransmissionTypeOptions(state, { options }) {
  return {
    ...state,
    transmissionType: {
      ...state.transmissionType,
      options,
    },
  };
}

function setTransmissionTypeValue(state, { value }) {
  return {
    ...state,
    transmissionType: {
      ...state.transmissionType,
      value,
    },
  };
}

function setPassengerCapacityOptions(state, { options }) {
  return {
    ...state,
    passengerCapacity: {
      ...state.passengerCapacity,
      options,
    },
  };
}

function setPassengerCapacityValue(state, { value }) {
  return {
    ...state,
    passengerCapacity: {
      ...state.passengerCapacity,
      value,
    },
  };
}
