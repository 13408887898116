import * as types from './types';

const initialState = {
  displayCookieBanner: null,
};

function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.SET_COOKIE_BANNER:
      return setCookieBanner(state, payload);

    case types.RESET_STATE:
      return initialState;

    default:
      return state;
  }
}

export default reducer;

function setCookieBanner(state, { displayCookieBanner }) {
  return {
    ...state,
    displayCookieBanner,
  };
}
