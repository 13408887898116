import getCookie from 'utils/cookie/getCookie';
import { TRIPX_AFFILIATE as COOKIE_KEY_TRIPX_AFFILIATE } from 'constants/cookiesKeys';
import trackEvent from '../utils/trackEvent';

export default function hotelLiveSearch(eventName, { hotelId, requestParameters, selectRooms }) {
  const tripxAffiliateCookie = getCookie(COOKIE_KEY_TRIPX_AFFILIATE);

  const eventData = {
    hotelId,
    origin: requestParameters.departureAirport,
    dates: {
      from: requestParameters.departureDate,
      to: requestParameters.returnDate,
      flexibleDates: false,
    },
    roomConfigurations: selectRooms.rooms.map(({ numberOfAdults, childrenAges }) => ({
      numberOfAdults,
      childrenAges,
    })),
    packageInfo: {
      hotelId: hotelId,
      roomId: requestParameters.packageRoomId,
      boardCode: requestParameters.packageBoardCode,
      price: requestParameters.packagePrice,
    },
    affiliateId: tripxAffiliateCookie,
  };

  trackEvent(eventName, eventData);
}
