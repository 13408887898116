import copy from 'utils/immutable/copy';
import * as types from './types';

const initialState = {
  fetching: null,
  value: null,
  filteredVehicles: [],
  filteredVehiclesTimeStamp: null,
  driversDatesOfBirth: [],
  currentRequestParameters: null,
};

function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.SET_STATE:
      return payload.state;

    case types.SET_FETCHING:
      return setFetching(state, payload);

    case types.SET_VEHICLE_RENTALS:
      return setVehicleRentals(state, payload);

    case types.SET_FILTERED_VEHICLES:
      return setFilteredVehicles(state, payload);

    case types.SET_DRIVERS_DATES_OF_BIRTH:
      return setDriversDatesOfBirth(state, payload);

    case types.SET_CURRENT_REQUEST_PARAMETERS:
      return setCurrentRequestParameters(state, payload);

    case types.RESET_STATE:
      return initialState;

    default:
      return state;
  }
}

export default reducer;

function setFetching(state, { fetching }) {
  return {
    ...state,
    fetching,
  };
}

function setVehicleRentals(state, { value }) {
  return {
    ...state,
    value,
    filteredVehicles: copy(value.carRentals),
    filteredVehiclesTimeStamp: Date.now(),
  };
}

function setFilteredVehicles(state, { filteredVehicles }) {
  return {
    ...state,
    filteredVehicles,
    filteredVehiclesTimeStamp: Date.now(),
  };
}

function setDriversDatesOfBirth(state, { driversDatesOfBirth }) {
  return {
    ...state,
    driversDatesOfBirth,
  };
}

function setCurrentRequestParameters(state, { currentRequestParameters }) {
  return {
    ...state,
    currentRequestParameters,
  };
}
