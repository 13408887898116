export default function convertFilterDataWorld() {
  return {
    boardType: {
      current: null,
      possible: ['BB', 'SC'],
    },
    cities: {
      current: null,
      possible: [
        {
          id: 'Helsinki',
          name: 'Helsinki',
        },
      ],
    },
    hotelFacilities: {
      current: null,
      possible: [
        {
          id: 88,
          name: 'WiFi',
        },
        {
          id: 57,
          name: 'Restaurang',
        },
        {
          id: 67,
          name: 'Spa',
        },
      ],
    },
    hotelPriceRange: {
      current: null,
      possible: [1000, 5000],
    },
    hotelScore: {
      current: null,
      possible: [50, 100],
    },
    hotelStars: {
      current: null,
      possible: [3, 3.5, 4, 4.5],
    },
    hotelThemes: {
      current: null,
      possible: [
        {
          id: 12,
          name: 'Storstad',
        },
        {
          id: 17,
          name: 'Familj',
        },
        {
          id: 49,
          name: 'Boutique',
        },
      ],
    },
    numberOfDays: {
      current: null,
      possible: [2, 7],
    },
  };
}
