import queryString from 'query-string';
import axis from 'axis.js';
import { propertiesMapSearchResults, propertiesMapHotelPage } from './_constants';

export function getSearchResultsQueryParams(searchParams, affiliateTrackingData) {
  return getQueryParams(searchParams, propertiesMapSearchResults, affiliateTrackingData);
}

export function getHotelPageQueryParams(searchParams, affiliateTrackingData, urlSearchQuery) {
  return getQueryParams(searchParams, propertiesMapHotelPage, affiliateTrackingData, urlSearchQuery);
}

function getQueryParams(searchParams, propertiesMap, affiliateTrackingData, urlSearchQuery) {
  const queryParams = {};
  //WORLD SEARCH IMPLEMENTATION
  searchParams['destinations'] = [...new Set(searchParams['destinations'])];
  for (var key in searchParams) {
    if (searchParams.hasOwnProperty(key)) {
      if (searchParams[key] !== null) {
        if (
          (axis.isArray(searchParams[key]) && searchParams[key].length > 0) ||
          axis.isNumber(searchParams[key]) ||
          axis.isString(searchParams[key])
        ) {
          if (propertiesMap[key] !== undefined) {
            queryParams[propertiesMap[key]] = searchParams[key].toString();
          }
        }
      }
    }
  }

  // Dates, Package Info and RoomConfigurations are handled separately because they are objects
  if (searchParams.dates) {
    propertiesMap.dates.forEach((item) => {
      if (searchParams.dates[item] !== null) {
        queryParams[item] = searchParams.dates[item];
      }
    });
  }

  if (propertiesMap.packageInfo) {
    propertiesMap.packageInfo.forEach(({ key, map, base64 }) => {
      if (searchParams.packageInfo && searchParams.packageInfo[key] !== null) {
        if (!base64) {
          queryParams[map] = searchParams.packageInfo[key];
        } else {
          queryParams[map] = window.btoa(searchParams.packageInfo[key]);
        }
      }
    });
  }

  if (searchParams.roomConfigurations) {
    queryParams.rooms = searchParams.roomConfigurations
      .map((room) => {
        let value = `${room.numberOfAdults}`;

        if (room.childrenAges.length > 0) {
          value += `-${room.childrenAges.toString().replace(/,/g, '-')}`;
        }

        return value;
      })
      .toString();
  }

  if (affiliateTrackingData) {
    const keys = Object.keys(affiliateTrackingData);

    keys.forEach((key) => {
      if (key.indexOf('utm') !== -1) {
        queryParams[key] = affiliateTrackingData[key];
      }
    });
  }

  const orderedProps = Object.values(propertiesMap).reduce((accumulator, currentValue) => {
    if (axis.isString(currentValue)) {
      accumulator.push(currentValue);
    } else if (axis.isArray(currentValue)) {
      if (axis.isString(currentValue[0])) {
        accumulator = accumulator.concat(currentValue);
      } else if (axis.isObject(currentValue[0])) {
        accumulator = accumulator.concat(currentValue.map((x) => x.map));
      }
    }

    return accumulator;
  }, []);

  if (urlSearchQuery) {
    if (urlSearchQuery.promocode) {
      queryParams.promocode = urlSearchQuery.promocode;
    }

    if (urlSearchQuery.rewardno) {
      queryParams.rewardno = urlSearchQuery.rewardno;
    }
  }

  return queryString.stringify(queryParams, {
    encode: false,
    sort: (a, b) => orderedProps.indexOf(a) - orderedProps.indexOf(b),
  });
}

export function orderSearchQuery(queryParams, config) {
  const { isHotelPage } = config;

  const propertiesMap = isHotelPage ? propertiesMapHotelPage : propertiesMapSearchResults;

  const orderedProps = Object.values(propertiesMap).reduce((accumulator, currentValue) => {
    if (axis.isString(currentValue)) {
      accumulator.push(currentValue);
    } else if (axis.isArray(currentValue)) {
      if (axis.isString(currentValue[0])) {
        accumulator = accumulator.concat(currentValue);
      } else if (axis.isObject(currentValue[0])) {
        accumulator = accumulator.concat(currentValue.map((x) => x.map));
      }
    }

    return accumulator;
  }, []);

  return queryString.stringify(queryParams, {
    encode: false,
    sort: (a, b) => orderedProps.indexOf(a) - orderedProps.indexOf(b),
  });
}
