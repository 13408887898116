import * as types from './types';
import getPackageInfoKey from './utils/getPackageInfoKey';
import isPackageInfoMissing from './utils/isPackageInfoMissing';

const initialState = {
  missing: true,
  current: null,
  previous: {},
};

function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.SET_STATE:
      return payload.state;

    case types.SET_CURRENT:
      return setCurrent(state, payload);

    case types.RESET_STATE:
      return initialState;

    default:
      return state;
  }
}

export default reducer;

function setCurrent(state, { current }) {
  const packageInfoKey = getPackageInfoKey(current);

  return {
    ...state,
    missing: isPackageInfoMissing(current),
    current,
    previous:
      packageInfoKey !== null
        ? {
            ...state.previous,
            [packageInfoKey]: { ...current },
          }
        : { ...state.previous },
  };
}
