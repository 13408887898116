import * as types from './types';

const initialState = {
  fetching: null,
  value: null,
};

function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.SET_STATE:
      return payload.state;

    case types.SET_FETCHING:
      return setFetching(state, payload);

    case types.SET_MONTHLY_PRICES_VALUE:
      return setMonthlyPricesValue(state, payload);

    case types.RESET_STATE:
      return initialState;

    default:
      return state;
  }
}

export default reducer;

function setFetching(state, { fetching }) {
  return {
    ...state,
    fetching,
  };
}

function setMonthlyPricesValue(state, { value }) {
  return {
    ...state,
    value,
  };
}
