import * as types from './types';

const initialState = {
  bookingVerify: {
    fetching: false,
    value: {
      valid: null,
      rewardPoints: null,
    },
  },
  bookingCreate: {
    fetching: false,
    value: null,
  },
};

function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.SET_STATE:
      return payload.state;

    case types.SET_FETCHING_BOOKING_VERIFY:
      return setFetchingBookingVerify(state, payload);

    case types.SET_FETCHING_BOOKING_CREATE:
      return setFetchingBookingCreate(state, payload);

    case types.SET_BOOKING_VERIFY_VALUE:
      return setBookingVerifyValue(state, payload);

    case types.SET_BOOKING_CREATE_VALUE:
      return setBookingCreateValue(state, payload);

    case types.RESET_BOOKING_VERIFY:
      return resetBookingVerify(state);

    case types.RESET_BOOKING_CREATE:
      return resetBookingCreate(state);

    case types.RESET_STATE:
      return initialState;

    default:
      return state;
  }
}

export default reducer;

function setFetchingBookingVerify(state, { fetching }) {
  return {
    ...state,
    bookingVerify: {
      ...state.bookingVerify,
      fetching,
    },
  };
}

function setFetchingBookingCreate(state, { fetching }) {
  return {
    ...state,
    bookingCreate: {
      ...state.bookingCreate,
      fetching,
    },
  };
}

function setBookingVerifyValue(state, { value }) {
  return {
    ...state,
    bookingVerify: {
      ...state.bookingVerify,
      value,
    },
  };
}

function setBookingCreateValue(state, { value }) {
  return {
    ...state,
    bookingCreate: {
      ...state.bookingCreate,
      value,
    },
  };
}

function resetBookingVerify(state) {
  return {
    ...state,
    bookingVerify: {
      ...initialState.bookingVerify,
    },
  };
}

function resetBookingCreate(state) {
  return {
    ...state,
    bookingCreate: {
      ...initialState.bookingCreate,
    },
  };
}
