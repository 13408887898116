import proxy from 'httpClient/requests';

/**
 * Search insurance products.
 * https://apidocs.tripx.se/tripx-web-api#operation/GET_insurances-search
 * @param {object} body - `/v3/insurances/search` parameters.
 */
export function getInsurancesSearch(body) {
  return proxy({
    httpMethod: 'GET',
    timeout: 20,
    version: 3,
    apiMethod: 'insurances/search',
    body,
  });
}
