import * as flightDirections from 'state/checkoutPage/flightsDetails/constants/flightDirections';

export default function getOptionsForUpgrades(upgrades) {
  const leaveUpgrades = upgrades.find((upgrade) => upgrade.direction === flightDirections.LEAVE) || {
    direction: flightDirections.LEAVE,
    options: null,
  };
  const returnUpgrades = upgrades.find((upgrade) => upgrade.direction === flightDirections.RETURN) || {
    direction: flightDirections.RETURN,
    options: null,
  };

  const bothWaysUpgrades = upgrades.find((upgrade) => upgrade.direction === flightDirections.BOTH);

  return bothWaysUpgrades ? [bothWaysUpgrades] : [leaveUpgrades, returnUpgrades];
}
