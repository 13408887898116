/**
 * @typedef checkoutPagePackagesSearchValue
 * @type {object}
 * @property {object[]} hotels - Available hotels.
 * @property {object[]} flights - Available flights.
 * @property {object[]=} messages
 * @property {string} searchToken
 * @property {string} languageCode
 * @property {string} currency
 * @property {string} packages
 * @property {PackagesSearchLinkedData} linkedData
 */

/**
 * @typedef PackagesSearchLinkedData
 * @type {object}
 * @property {object[]} airportNames - Airport names translations.
 * @property {object[]} carrierNames - Carrier names translations.
 */

import updateAtIndex from 'utils/immutable/updateAtIndex';
import * as types from './types';

const initialState = {
  fetching: null,
  value: null,
  validation: null,
};

function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.SET_STATE:
      return payload.state;

    case types.SET_FETCHING:
      return setFetching(state, payload);

    case types.SET_PACKAGES_SEARCH_VALUE:
      return setPackagesSearchValue(state, payload);

    case types.SET_VALIDATION:
      return setValidation(state, payload);

    case types.SET_HOTEL_DESTINATION_CODE:
      return setHotelDestinationCode(state, payload);

    case types.RESET_STATE:
      return initialState;

    default:
      return state;
  }
}

export default reducer;

function setFetching(state, { fetching }) {
  return {
    ...state,
    fetching,
  };
}

function setPackagesSearchValue(state, { value }) {
  return {
    ...state,
    value,
  };
}

function setValidation(state, { validation }) {
  return {
    ...state,
    validation,
  };
}

function setHotelDestinationCode(state, { hotelId, destinationCode }) {
  const hotelIndex = state.value.hotels.findIndex((hotel) => hotel.id === hotelId);

  return {
    ...state,
    value: {
      ...state.value,
      hotels: updateAtIndex(state.value.hotels, hotelIndex, {
        ...state.value.hotels[hotelIndex],
        destinationCode,
      }),
    },
  };
}
