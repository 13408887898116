import _intersection from 'lodash/intersection';
import _isEqual from 'lodash/isEqual';
import _sortBy from 'lodash/sortBy';
import { SEARCH as ROUTE_KEY_SEARCH } from 'constants/routesKeys';
import { ALL_FOR_COUNTRY } from 'constants/selectValues';
import searchPropertiesKeys from 'state/_constants/searchPropertiesKeys';
import { getRoutePathByRouteKey } from 'state/routes/operations';
import formatDestinationsForReactSelect from '../transforms/formatDestinationsForReactSelect';
import { setSelectedDestinations, setForceSingleValue } from '../actions';
import getAvailableCountries from '../selectors/getAvailableCountries';
import searchQueryAvailable from 'utils/SearchQueryAvailable';
import SearchQueryParser from 'state/_utils/SearchQueryParser';
import searchQueryLS from 'managers/localStorage/searchQuery/searchQueryLS';

export default function hydrateSelectedDestinations() {
  return (dispatch, getState) => {
    let storedQuery = null;
    const selectedOriginsLength = getState().selectOrigins.selectedOrigins.length;

    if (searchQueryAvailable(dispatch(getRoutePathByRouteKey(ROUTE_KEY_SEARCH)))) {
      storedQuery = SearchQueryParser.getSelectedDestinations();
    } else {
      storedQuery = searchQueryLS.get();
    }

    const { selectDestinations } = getState();
    const { destinations } = selectDestinations;

    // if there is only one destinations it should be selected by default
    if (destinations && destinations.length === 1) {
      let value = ALL_FOR_COUNTRY;

      if (destinations[0].destinations.length === 1) {
        dispatch(setForceSingleValue(true));
        value = destinations[0].destinations[0].code;
      }

      storedQuery = {};
      storedQuery[searchPropertiesKeys.SELECTED_DESTINATIONS_KEY] = [value];
    }

    if (storedQuery !== null) {
      if (
        storedQuery[searchPropertiesKeys.SELECTED_DESTINATIONS_KEY] &&
        storedQuery[searchPropertiesKeys.SELECTED_DESTINATIONS_KEY].length > 0
      ) {
        const selectedDestinations = destinations.filter((destination, index, array) => {
          const destinationsCode = destination.destinations.map((item) => item.code);

          if (
            array.length === 1 &&
            storedQuery[searchPropertiesKeys.SELECTED_DESTINATIONS_KEY][0] === ALL_FOR_COUNTRY
          ) {
            return true;
          }

          return (
            _intersection(destinationsCode, storedQuery[searchPropertiesKeys.SELECTED_DESTINATIONS_KEY]).length > 0
          );
        });

        const { availableDestinations, destinationToCountryMap } = getState().selectDestinations;
        const availableCountries = getAvailableCountries(availableDestinations, destinationToCountryMap);

        const formattedAvailableDestinations = formatDestinationsForReactSelect({
          destinations: selectedDestinations,
          availableCountriesCodes: availableCountries,
          availableDestinations,
          selectedOriginsLength,
        }).cities;

        const selectedDestinationsFormatted = formattedAvailableDestinations.filter((destination) => {
          return storedQuery[searchPropertiesKeys.SELECTED_DESTINATIONS_KEY].indexOf(destination.value) !== -1;
        });

        if (storedQuery.selectedDestinations && storedQuery.selectedDestinations.length > 1) {
          const wholeSpain = destinations.filter(({ code }) => code === 'ES');
          const wholeSpainDestinationsCodes = wholeSpain && wholeSpain[0].destinations.map(({ code }) => code);

          if (_isEqual(_sortBy(storedQuery.selectedDestinations), _sortBy(wholeSpainDestinationsCodes))) {
            dispatch(setSelectedDestinations([formattedAvailableDestinations[0]]));
          } else {
            dispatch(setSelectedDestinations(selectedDestinationsFormatted));
          }
        } else {
          dispatch(setSelectedDestinations(selectedDestinationsFormatted));
        }
      }
    }
  };
}
