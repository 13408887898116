import * as types from './types';

const initialState = {
  scrollToTimeStamp: null,
  isDataFetching: null,
};

function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.SET_SCROLL_TO_TIME_STAMP:
      return setScrollToTimeStamp(state, payload);
    case types.SET_IS_DATA_FETCHING:
      return setIsDataFetching(state, payload);

    default:
      return state;
  }
}

export default reducer;

function setScrollToTimeStamp(state, { scrollToTimeStamp }) {
  return {
    ...state,
    scrollToTimeStamp: scrollToTimeStamp !== undefined ? scrollToTimeStamp : Date.now(),
  };
}
function setIsDataFetching(state, { isDataFetching }) {
  return {
    ...state,
    isDataFetching: isDataFetching,
  };
}
