import moment from 'moment';

export default function getPriceBarsRequestData(currentDate, queryDate, numberOfBars) {
  const queryDateMoment = moment(queryDate, 'YYYY-MM-DD');
  const diffInDays = queryDateMoment.diff(currentDate, 'days');

  if (diffInDays > numberOfBars / 2) {
    return {
      barsPast: numberOfBars / 2,
      barsFuture: numberOfBars / 2,
    };
  }

  return {
    barsPast: diffInDays,
    barsFuture: numberOfBars - diffInDays,
  };
}
