export default function trackEvent(event, data = {}) {
  const { eventCategory = 'All', eventLabel = 'All', eventValue = null, ...properties } = data;

  window.dataLayer.push({
    event: 'trackEvent',
    eventAction: event,
    eventCategory,
    eventLabel,
    eventValue,
    properties,
  });
}
