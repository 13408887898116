import React, { Component } from 'react';

const LocizeContext = React.createContext({
  locale: null,
  namespace: null,
  currency: null,
});

export default LocizeContext;

// hoc for context
export function withContext() {
  return function Wrapper(WrappedComponent) {
    class WithContext extends Component {
      render() {
        return (
          <LocizeContext.Consumer>
            {(context) => (
              <WrappedComponent
                {...this.props}
                locale={context.locale}
                namespace={context.namespace}
                currency={context.currency}
              />
            )}
          </LocizeContext.Consumer>
        );
      }
    }

    return WithContext;
  };
}

export function ensureTranslations() {
  return function Wrapper(WrappedComponent) {
    class EnsureTranslations extends Component {
      render() {
        return (
          <LocizeContext.Consumer>
            {(context) => context.locale && <WrappedComponent {...this.props} />}
          </LocizeContext.Consumer>
        );
      }
    }

    return EnsureTranslations;
  };
}
