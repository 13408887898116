import langToCountryCodeMap from 'static/data/langToCountryCodeMap.json';

/**
 * Get the country code based on the language and top-level domain (TLD).
 *
 * @param {string} language - The language to get the country code for.
 * @param {string} tld - The top-level domain to get the country code for.
 * @return {string} The country code corresponding to the language and TLD.
 */

export default function getCountryCode(language, tld) {
  const languageData = langToCountryCodeMap[language];

  if (!languageData) {
    return 'gb'; // Language not found
  }

  if (languageData.countries && languageData.countries[tld]) {
    return languageData.countries[tld].countryCode;
  } else {
    return languageData.countryCode;
  }
}
