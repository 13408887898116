import * as types from './types';

export function setValue(value) {
  return {
    type: types.SET_VALUE,
    payload: {
      value,
    },
  };
}

export function resetState() {
  return {
    type: types.RESET_STATE,
  };
}
