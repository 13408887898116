export const propertiesMapSearchResults = {
  origins: 'origins',
  destinations: 'destinations',
  dates: ['from', 'to', 'flexibleDates', 'offerFrom', 'offerTo'],
  roomConfigurations: 'rooms',
  numberOfDays: 'numberOfDays',
  hotelScore: 'score',
  hotelStars: 'stars',
  hotelPriceRange: 'price',
  boardType: 'board',
  hotelThemes: 'themes',
  hotelFacilities: 'facilities',
  cities: 'cities',
  packageInfo: [
    { key: 'hotelId', map: 'hotel' },
    { key: 'roomId', map: 'packageRoomId' },
    { key: 'boardCode', map: 'packageBoardCode' },
    { key: 'price', map: 'packagePrice', base64: true },
  ],
  utmSource: 'utm_source',
  utmMedium: 'utm_medium',
  utmCampaign: 'utm_campaign',
};

export const propertiesMapHotelPage = {
  origin: 'origin',
  dates: ['from', 'to'],
  roomConfigurations: 'rooms',
  packageRoomId: 'packageRoomId',
  packagePrice: 'packagePrice',
  packageBoardCode: 'packageBoardCode',
  checkoutPersistence: 'checkoutPersistence',
  abandonedCart: 'abandonedCart',
  abandonedCartBlob: 'abandonedCartBlob',
  step: 'step',
  utmSource: 'utm_source',
  utmMedium: 'utm_medium',
  utmCampaign: 'utm_campaign',
  utmTerm: 'utm_term',
  utmContent: 'utm_content',
};
