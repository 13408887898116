export default function getNumberOfAdultsAndChildren(rooms) {
  return rooms
    .map((room) => ({
      numberOfAdults: room.numberOfAdults,
      numberOfChildren: room.numberOfChildren,
    }))
    .reduce(
      (accumulator, currentValue) => ({
        numberOfAdults: accumulator.numberOfAdults + currentValue.numberOfAdults,
        numberOfChildren: accumulator.numberOfChildren + currentValue.numberOfChildren,
      }),
      { numberOfAdults: 0, numberOfChildren: 0 }
    );
}
