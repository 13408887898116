import * as flightDirections from 'state/checkoutPage/flightsDetails/constants/flightDirections';
import { getOrCreateStore } from 'state/storeProvider';
import getSelectedFlightsCombinations from 'state/checkoutPage/flights/selectors/getSelectedFlightsCombinations';
import getKeyValuesFromObject from 'utils/array/getKeyValuesFromObject';

export function getSelectedFlights() {
  const { dispatch } = getOrCreateStore();
  const selectedFlightsCombinations = dispatch(getSelectedFlightsCombinations());

  if (selectedFlightsCombinations === null) {
    return null;
  }

  return selectedFlightsCombinations[0].legs
    .map(({ segments }, index) => {
      return segments.map((segment) => {
        segment.direction = index === 0 ? flightDirections.LEAVE : flightDirections.RETURN;
        return segment;
      });
    })
    .flat();
}

export function filterCombinationByType(upgrades, type, selectedFlights) {
  const { carrierNamesMap } = getOrCreateStore().getState().checkoutPageFlights;

  return upgrades
    .filter((upgrade) => upgrade.type === type)
    .filter(Boolean)
    .map((items) => {
      const initialCarrier = selectedFlights[0].carrier;
      return {
        ...items,
        carrier: initialCarrier,
        carrierName: carrierNamesMap[initialCarrier],
        carriers: `https://carrierlogos.tripx.eu/${initialCarrier}.png?w=32&`,
      };
    });
}

export function filterLegsByType(legs, type, selectedFlights) {
  const { carrierNamesMap } = getOrCreateStore().getState().checkoutPageFlights;

  return legs
    .map(({ upgrades }) => upgrades)
    .flat()
    .filter((upgrade) => upgrade.type === type)
    .map((items) => {
      const { carrier } = selectedFlights.find((flight) => flight.direction === items.direction);
      return {
        ...items,
        carrier,
        carrierName: carrierNamesMap[carrier],
        carriers: `https://carrierlogos.tripx.eu/${carrier}.png?w=32`,
      };
    });
}

export function filterFlightsByType(legs, type, selectedFlights) {
  const { carrierNamesMap } = getOrCreateStore().getState().checkoutPageFlights;

  return legs
    .map(({ flights }) => flights.map(({ upgrades }) => upgrades))
    .flat()
    .map((upgrades) => upgrades.find((upgrade) => upgrade.type === type))
    .map((upgrade, index) => {
      if (upgrade) {
        upgrade.departureAirport = selectedFlights[index].departureAirport;
        upgrade.destinationAirport = selectedFlights[index].destinationAirport;
        upgrade.carrier = selectedFlights[index].carrier;
        upgrade.carrierName = carrierNamesMap[selectedFlights[index].carrier];
        upgrade.carriers = `https://carrierlogos.tripx.eu/${selectedFlights[index].carrier}.png?w=32`;
      }

      return upgrade;
    })
    .filter(Boolean);
}

export function filterCo2Compensation(data, selectedFlights) {
  return getKeyValuesFromObject(data, 'co2Compensation').map((co2, index) => ({
    ...co2,
    departureAirport: selectedFlights[index].departureAirport,
    destinationAirport: selectedFlights[index].destinationAirport,
  }));
}
