import { getOrCreateStore } from 'state/storeProvider';
import getRoomProductsForGTM from 'state/checkoutPage/packagesSearch/selectors/getRoomProductsForGTM';
import getFlightProductsForGTM from 'state/checkoutPage/flights/selectors/getFlightProductsForGTM';
import getFlightExtrasProductsForGTM from 'state/checkoutPage/travelSummary/selectors/getFlightExtrasProductsForGTM';

function getAllProductsForGTM() {
  const { dispatch } = getOrCreateStore();

  const roomProducts = dispatch(getRoomProductsForGTM());
  const flightProducts = dispatch(getFlightProductsForGTM());
  const flightExtrasProducts = dispatch(getFlightExtrasProductsForGTM());

  return [...roomProducts, ...flightProducts, ...flightExtrasProducts];
}

export default getAllProductsForGTM;
