import * as types from './types';

export const updateNumberOfAdults = (value, roomNumber) => ({
  type: types.UPDATE_NUMBER_OF_ADULTS,
  payload: {
    roomNumber,
    value,
  },
});

export const updateNumberOfChildren = (value, roomIndex, childIndex, childAge) => ({
  type: types.UPDATE_NUMBER_OF_CHILDREN,
  payload: {
    value,
    roomIndex,
    childIndex,
    childAge,
  },
});

export const updateChildAge = (value, roomNumber, childNumber) => ({
  type: types.UPDATE_CHILD_AGE,
  payload: {
    roomNumber,
    childNumber,
    value,
  },
});

export const addRoom = () => ({
  type: types.ADD_ROOM,
});

export const removeRoom = () => ({
  type: types.REMOVE_ROOM,
});

export const removeRoomAtIndex = (index) => ({
  type: types.REMOVE_ROOM_AT_INDEX,
  payload: {
    index,
  },
});

export const setRooms = (rooms) => ({
  type: types.SET_ROOMS,
  payload: {
    rooms,
  },
});
