import * as types from './types';

export function setElementIntoView(key) {
  return {
    type: types.SET_ELEMENT_INTO_VIEW,
    payload: {
      key,
    },
  };
}
