import Base64 from 'crypto-js/enc-base64';
import Utf8 from 'crypto-js/enc-utf8';

export function tryParseUtf8(params, config) {
  try {
    // Decode Base64 to a WordArray
    const wordArray = Base64.parse(params);

    // Convert the WordArray to a UTF-8 string
    const utf8String = Utf8.stringify(wordArray);

    config.value = utf8String;

    return true;
  } catch (error) {
    return false;
  }
}
