import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { mediaQueries } from 'styles/utils/mediaQueries';
import * as flightDirections from 'state/checkoutPage/flightsDetails/constants/flightDirections';
import servicesIconsMap from 'routes/checkoutPage/constants/servicesIconsMap';
import SelectUpgrade from './components/selectUpgrade/SelectUpgrade';
import { rgba } from 'polished';

function MultipleChoiceUpgradeComponent({
  serviceKey,
  title,
  upgrades,
  passengerType,
  passengerIndex,
  airlineWhitelabel,
}) {
  return (
    <Root>
      <Card>
        {title && (
          <Header airlineWhitelabel={airlineWhitelabel}>
            <Dot />
            {title}
          </Header>
        )}
        <CardBody airlineWhitelabel={airlineWhitelabel}>
          {upgrades.map(
            ({ direction, departureAirport, destinationAirport, options, includedService, carrierName }, index) => {
              let _options = undefined;
              const directionIcon = {
                [flightDirections.RETURN]: servicesIconsMap.PlaneArrival,
                [flightDirections.LEAVE]: servicesIconsMap.PlaneDeparture,
                [flightDirections.BOTH]: servicesIconsMap.Plane,
              }[direction];

              if (options && options.length > 0) {
                _options = [
                  {
                    label: '',
                    value: 'na',
                    data: {
                      description: {
                        id: `noUpgradeSelected.${serviceKey}`,
                        description: `No ${serviceKey}.`,
                        defaultMessage: `No ${serviceKey}.`,
                      },
                    },
                  },
                  ...options,
                ];
              }

              return (
                <Content key={`${serviceKey}-upgrade-${index}`}>
                  <Direction>
                    <DirectionIcon icon={directionIcon} />
                    <DirectionLabel>
                      <FormattedMessage
                        id={`${direction.toLowerCase()}.label`}
                        description={direction}
                        defaultMessage={direction}
                      />
                      {departureAirport && (
                        <Flight>
                          ({departureAirport} - {destinationAirport})
                        </Flight>
                      )}
                      {carrierName && <CarrierName>({carrierName})</CarrierName>}
                    </DirectionLabel>
                  </Direction>
                  {includedService && (
                    <IncludedServices>
                      <CheckmarkWrap>
                        <Checkmark />
                      </CheckmarkWrap>
                      <IncludedServicesWrap>
                        <Service>{includedService.quantity}</Service>&nbsp;x &nbsp;
                        <Service>{includedService.maxWeight && includedService.maxWeight.value}</Service>
                        <Service>{includedService.maxWeight && includedService.maxWeight.unitOfMeasure}</Service>
                        <Service>
                          <FormattedMessage
                            id={'includedService.label'}
                            description={'Included'}
                            defaultMessage={'Included'}
                          />
                        </Service>
                      </IncludedServicesWrap>
                    </IncludedServices>
                  )}
                  {!includedService && _options && (
                    <NoUpgradesWrap>
                      <Message>
                        <FormattedMessage
                          id={`notIncludedServiceMessage.${serviceKey}.label`}
                          description={`No ${serviceKey} upgrades available`}
                          defaultMessage={`No ${serviceKey} upgrades available`}
                        />
                      </Message>
                    </NoUpgradesWrap>
                  )}
                  {_options && (
                    <SelectUpgrade
                      options={_options}
                      serviceKey={serviceKey}
                      direction={direction}
                      passengerType={passengerType}
                      passengerIndex={passengerIndex}
                      forceSelection
                      scrollTo
                    />
                  )}
                  {!_options && !includedService && (
                    <NoUpgradesWrap>
                      <Message>
                        <FormattedMessage
                          id={`includedServiceMessage.${serviceKey}.label`}
                          description={`No ${serviceKey} upgrades available`}
                          defaultMessage={`No ${serviceKey} upgrades available`}
                        />
                      </Message>
                    </NoUpgradesWrap>
                  )}
                </Content>
              );
            }
          )}
        </CardBody>
      </Card>
    </Root>
  );
}

export default MultipleChoiceUpgradeComponent;

const Header = styled.div`
  color: ${({ theme }) => theme.colors.text.default};
  font-size: 1.4em;
  font-weight: ${({ airlineWhitelabel }) => (airlineWhitelabel && airlineWhitelabel.isAirBaltic ? 500 : 600)};
  margin-bottom: 8px;
  padding-bottom: 10px;
  display: flex;
`;

const Root = styled.div`
  flex: 0 0 49%;

  ${mediaQueries.fromAndBelow.desktop`
		flex: 0 0 100%;
  `}
`;

const Card = styled.div`
  margin-bottom: 20px;
`;

const CardBody = styled.div`
  font-weight: ${({ airlineWhitelabel }) => (airlineWhitelabel && airlineWhitelabel.isAirBaltic ? 500 : 600)};
  color: ${({ theme }) => theme.colors.text.light};
`;

const Content = styled.div`
  padding-bottom: 15px;
`;

const DirectionIcon = styled(FontAwesomeIcon)`
  font-size: 1.5em;
  color: ${({ theme }) => theme.colors.buttons.grey};
`;

const Direction = styled.div`
  padding-bottom: 15px;
`;

const DirectionLabel = styled.div`
  display: inline-block;
  font-size: 1.4em;
  color: ${({ theme }) => theme.colors.text.default};
  margin-left: 8px;
`;

const Message = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.text.light};
  font-size: 1.4em;
  font-weight: 500;
  opacity: 0.7;
`;

const Flight = styled.div`
  display: inline-block;
  margin-left: 8px;
`;

const IncludedServices = styled.div`
  display: flex;
  margin-bottom: 8px;
  border: ${({ theme }) => `solid 1px ${rgba(theme.colors.inputs.radio.backgroundChecked, 0.5)}`};
  padding: 9px 0px 9px 10px;
  align-items: 12px;
`;

const Service = styled.span`
  font-size: 1.4em;
  color: ${({ theme }) => theme.colors.text.default};
  font-weight: 100;
  margin-right: 5px;
  &:last-child {
    font-weight: 600;
  }
`;

const Dot = styled.span`
  position: relative;
  top: 3px;
  left: 4px;
  background: ${({ theme }) => theme.colors.inputs.radio.backgroundChecked};
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 20px;
`;

const CarrierName = styled.span`
  margin-left: 8px;
`;
const IncludedServicesWrap = styled.div`
  padding-top: 2px;
`;

const CheckmarkWrap = styled.div`
  position: relative;
  height: 18px;
  width: 18px;
  opacity: 0.5;
  background-color: ${({ theme }) => theme.colors.inputs.radio.backgroundChecked};
  border: none;
  border-radius: 50%;
  margin-right: 12px;
`;

const Checkmark = styled.span`
  position: absolute;
  left: 6px;
  top: 2px;
  width: 6px;
  height: 11px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
`;

const NoUpgradesWrap = styled.div`
  display: flex;
  margin-bottom: 8px;
  border: ${({ theme }) => `solid 1px ${theme.colors.inputs.border.default}`};
  padding: 11px 0px 11px 14px;
  align-items: 12px;
`;
