import reducer from './reducers';
import * as templatesDestinationsTypes from './types';
import * as templatesDestinationsActions from './actions';
import * as templatesDestinationsOperations from './operations';
import * as templatesDestinationsSelectors from './selectors';

export { templatesDestinationsTypes };
export { templatesDestinationsActions };
export { templatesDestinationsOperations };
export { templatesDestinationsSelectors };

export { reducer as default };
