import axis from 'axis.js';

export default function transformSelectRoomsConfigurations(roomConfigurations) {
  let _roomConfigurations = roomConfigurations;

  if (axis.isNumber(roomConfigurations[0].adults) || axis.isArray(roomConfigurations[0].childages)) {
    _roomConfigurations = _roomConfigurations.map(({ adults, childages }) => ({
      numberOfAdults: adults,
      childrenAges: childages,
    }));
  }

  return _roomConfigurations.reduce(
    (acc, { numberOfAdults, childrenAges }) => {
      const { numberOfChildren, numberOfInfants } = childrenAges.reduce(
        (acc2, currentValue) => ({
          numberOfInfants: currentValue < 2 ? ++acc2.numberOfInfants : acc2.numberOfInfants,
          numberOfChildren: currentValue >= 2 ? ++acc2.numberOfChildren : acc2.numberOfChildren,
        }),
        { numberOfChildren: 0, numberOfInfants: 0 }
      );
      acc.numberOfAdults += numberOfAdults;
      acc.numberOfChildren += numberOfChildren;
      acc.numberOfInfants += numberOfInfants;
      acc.total = acc.numberOfAdults + acc.numberOfChildren + acc.numberOfInfants;
      acc.chargeablePersons = acc.numberOfAdults + acc.numberOfChildren;
      acc.childrenAges = acc.childrenAges.concat(childrenAges);
      return acc;
    },
    { numberOfAdults: 0, numberOfChildren: 0, numberOfInfants: 0, total: 0, chargeablePersons: 0, childrenAges: [] }
  );
}
