import * as types from './types';

export function setCalendarDates(calendarDates) {
  return {
    type: types.SET_CALENDAR_DATES,
    payload: {
      calendarDates,
    },
  };
}

export function toggleFlexibleDates() {
  return {
    type: types.TOGGLE_FLEXIBLE_DATES,
  };
}

export function updateCalendarHighlights(calendarHighlights) {
  return {
    type: types.UPDATE_CALENDAR_HIGHLIGHTS,
    payload: {
      calendarHighlights,
    },
  };
}

export function updateCalendarHighlightsV2(originCode, highlights) {
  return {
    type: types.UPDATE_CALENDAR_HIGHLIGHTS_V2,
    payload: {
      originCode,
      highlights,
    },
  };
}

export function updateCalendarHighlightsV3(originCode, highlights) {
  return {
    type: types.UPDATE_CALENDAR_HIGHLIGHTS_V3,
    payload: {
      originCode,
      highlights,
    },
  };
}

export function resetCalendarHighlights() {
  return {
    type: types.RESET_CALENDAR_HIGHLIGHTS,
  };
}

export function setCalendarHighlightsForReactDates(calendarHighlightsForReactDates, initialVisibleMonth) {
  return {
    type: types.SET_CALENDAR_HIGHLIGHTS_FOR_REACT_DATES,
    payload: {
      calendarHighlightsForReactDates,
      initialVisibleMonth,
    },
  };
}

export function updateAirlineCalendarValues(airlineCalendarValues) {
  return {
    type: types.UPDATE_AIRLINE_CALENDAR_VALUES,
    payload: {
      airlineCalendarValues,
    },
  };
}

export function updateAirlineCalendarValuesV2(originCode, values) {
  return {
    type: types.UPDATE_AIRLINE_CALENDAR_VALUES_V2,
    payload: {
      originCode,
      values,
    },
  };
}

export function setAirlineCalendarAvailableDates(availableDates) {
  return {
    type: types.SET_AIRLINE_CALENDAR_AVAILABLE_DATES,
    payload: {
      availableDates,
    },
  };
}

export function setFlexibleDatesDisabled(flexibleDatesDisabled) {
  return {
    type: types.SET_FLEXIBLE_DATES_DISABLED,
    payload: {
      flexibleDatesDisabled,
    },
  };
}

export function setCalendarOption(option) {
  return {
    type: types.SET_CALENDAR_OPTION,
    payload: {
      option,
    },
  };
}

export function resetSelectedDates() {
  return {
    type: types.RESET_SELECTED_DATES,
  };
}

export function resetCalendarDates() {
  return {
    type: types.RESET_CALENDAR_DATES,
  };
}
