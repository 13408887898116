import copy from 'utils/immutable/copy';
import * as types from './types';

const initialState = {
  fetching: null,
  adverts: [],
  filteredAdverts: [],
  filteredAdvertsTimeStamp: null,
};

function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.SET_STATE:
      return payload.state;

    case types.SET_FETCHING:
      return setFetching(state, payload);

    case types.SET_ADVERTS:
      return setAdverts(state, payload);

    case types.SET_FILTERED_ADVERTS:
      return setFilteredAdverts(state, payload);

    case types.RESET_STATE:
      return initialState;

    default:
      return state;
  }
}

export default reducer;

function setFetching(state, { fetching }) {
  return {
    ...state,
    fetching,
  };
}

function setAdverts(state, { adverts }) {
  return {
    ...state,
    adverts,
    filteredAdverts: copy(adverts),
    filteredAdvertsTimeStamp: Date.now(),
  };
}

function setFilteredAdverts(state, { filteredAdverts }) {
  return {
    ...state,
    filteredAdverts,
    filteredAdvertsTimeStamp: Date.now(),
  };
}
