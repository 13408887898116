export function formatFilterDataFromResponse(filterData) {
  let formattedFilterData = {};

  if (filterData) {
    if (filterData.numberOfDays) {
      formattedFilterData.numberOfDays = formatNumberOfDays(filterData.numberOfDays);
    }

    if (filterData.hotelScore) {
      formattedFilterData.hotelScore = formatHotelScore(filterData.hotelScore);
    }

    if (filterData.hotelStars) {
      formattedFilterData.hotelStars = formatHotelStars(filterData.hotelStars);
    }

    if (filterData.hotelPriceRange) {
      formattedFilterData.priceRange = formatRangedFilters(filterData.hotelPriceRange);
    }

    if (filterData.boardType) {
      formattedFilterData.boardType = formatBoardType(filterData.boardType);
    }

    if (filterData.hotelThemes) {
      formattedFilterData.hotelThemes = formatIdBasedFilters(filterData.hotelThemes);
    }

    if (filterData.hotelFacilities) {
      formattedFilterData.hotelFacilities = formatIdBasedFilters(filterData.hotelFacilities);
    }

    if (filterData.cities) {
      formattedFilterData.hotelCities = formatIdBasedFilters(filterData.cities);
    }
  }

  return formattedFilterData;
}

// Private functions
function formatRangedFilters(filterData) {
  const formattedFilterData = {
    min: 0,
    max: 0,
    value: [],
  };

  if (filterData.possible !== null) {
    formattedFilterData.min = filterData.possible[0];
    formattedFilterData.max = filterData.possible[1];
    formattedFilterData.value = filterData.possible;
  }

  if (
    filterData.current !== null &&
    filterData.possible !== null &&
    filterData.current[0] >= filterData.possible[0] &&
    filterData.current[1] <= filterData.possible[1]
  ) {
    formattedFilterData.value = filterData.current;
  }

  return formattedFilterData;
}

function formatIdBasedFilters(filterData) {
  let current = [];

  if (filterData.current && filterData.current.length > 0 && filterData.possible && filterData.possible.length > 0) {
    const possibleIds = filterData.possible.map((item) => item.id);
    current = filterData.current.filter((item) => possibleIds.includes(item));
  }

  return {
    current,
    possible: filterData.possible,
  };
}

function formatHotelScore(hotelScore) {
  let formattedHotelScore = {
    current: 0,
  };

  if (hotelScore.current !== null) {
    formattedHotelScore.current = Math.floor(hotelScore.current / 5) * 5;
  }

  return formattedHotelScore;
}

function formatHotelStars(hotelStars) {
  return {
    current: hotelStars.current || [],
  };
}

function formatNumberOfDays(filterData) {
  const formattedNumberOfDays = {
    min: 2,
    max: 16,
    value: [],
  };

  if (filterData.current !== null) {
    formattedNumberOfDays.value = filterData.current;
  }

  return formattedNumberOfDays;
}

function formatBoardType(filterData) {
  let current = null;

  if (filterData.current && filterData.possible && filterData.possible.length > 0) {
    current = filterData.possible.includes(filterData.current) ? filterData.current : null;
  }

  return {
    current,
    possible: filterData.possible,
  };
}
