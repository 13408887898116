import moment from 'moment';

import getSelectedFlightsCombinations from 'state/checkoutPage/flights/selectors/getSelectedFlightsCombinations';
import transformFlightsCombinationsForUI from 'state/checkoutPage/flights/transforms/transformFlightsCombinationsForUI';
import { deleteUTMParametersFromURL } from 'utils/url/deleteUTMParametersFromURL';

/**
 * Gets data that we need for customer IO event in Google Tag Manager (GTM);
 * @param {object} state The redux state
 * @param {Function} dispatch - The Redux dispatch function.
 * @return {object} An object containing all data for abandoned cart
 */

export const getAbandonedCartForGTM = (state, dispatch) => {
  const airportNamesMap = state.checkoutPageFlights.airportNamesMap;
  const carrierNamesMap = state.checkoutPageFlights.carrierNamesMap;
  const flightsCombinations = dispatch(getSelectedFlightsCombinations());
  const formattedFlightsData = transformFlightsCombinationsForUI(
    { legs: flightsCombinations[0].legs },
    airportNamesMap,
    carrierNamesMap,
    null
  );
  const leaveFlight = { ...formattedFlightsData[0], ...formattedFlightsData[0].carriers[0] };
  const returnFlight = {
    ...formattedFlightsData[formattedFlightsData.length - 1],
    ...formattedFlightsData[formattedFlightsData.length - 1].carriers[0],
  };

  const customerName = state.checkoutPageCustomerDetails.firstName.value;

  const logo = state.settings.value.style.logoUrl;

  let url = typeof window !== 'undefined' && `${window.location.href}`;

  url = deleteUTMParametersFromURL(url, ['affiliateId', 'referenceId']);
  url = `${url}&utm_source=email&utm_campaign=abandoned_cart_email&ca=1&referenceId=abandoned_cart_email`;

  const { travelers } = state.checkoutPageTravelers;

  const market = state.settings.value.tld;

  const momentDepartureDateTime = moment(leaveFlight.departureDateTime, 'YYYY-MM-DD HH:mm');

  const timestamp = momentDepartureDateTime && momentDepartureDateTime.valueOf() / 1000;
  const travelersCount = travelers.reduce((acc, obj) => {
    Object.keys(obj).forEach((param) => {
      acc += obj[param].length;
    });
    return acc;
  }, 0);

  const hotelInfo = state.hotelPage.hotelsInfo[0];
  const hotelCheckoutData = state.checkoutPagePackagesSearch.value.hotels[0];

  return {
    hotelImage: hotelInfo.images[0],
    hotelName: hotelInfo.name,
    destinationImage: hotelInfo.location.destination.image,
    destinationName: hotelInfo.location.destination.name,
    dateFrom: moment(hotelCheckoutData.checkIn).format('DD MMM YYYY'),
    dateTo: moment(hotelCheckoutData.checkOut).format('DD MMM YYYY'),
    leaveFlight,
    returnFlight,
    customerName,
    logo,
    url,
    travelersCount,
    market,
    timestamp,
  };
};
