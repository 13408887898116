import parse from 'html-react-parser';
/**
 * Parses HTML text.
 *
 * @param {string} text - the HTML text to parse
 * @return {type} the parsed result
 */
export default function htmlParser(text) {
  // we do this because of default behavior of the package ( throwing error )
  return parse(text || '');
}
