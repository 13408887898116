import { HOTEL_ONLY } from 'constants/selectValues';
import * as types from './types';

const initialState = {
  isFetching: false,
  origins: [],
  selectedOrigins: [],
  availableOrigins: [],
  availableOriginsMap: {},
  hotelOnly: false,
  forceSingleValue: false,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case types.REQUEST_ORIGINS:
      return requestOrigins(state);

    case types.SET_ORIGINS:
      return setOrigins(state, action.payload);

    case types.SET_SELECTED_ORIGINS:
      return setSelectedOrigins(state, action.payload);

    case types.SET_AVAILABLE_ORIGINS:
      return setAvailableOrigins(state, action.payload);

    case types.SET_AVAILABLE_ORIGINS_MAP:
      return setAvailableOriginsMap(state, action.payload);

    case types.SET_FORCE_SINGLE_VALUE:
      return setForceSingleValue(state, action.payload);

    case types.RESET_SELECTED_ORIGINS:
      return resetSelectedOrigins(state);

    default:
      return state;
  }
}

export default reducer;

function requestOrigins(state) {
  return {
    ...state,
    isFetching: true,
  };
}

function setOrigins(state, { origins }) {
  return {
    ...state,
    origins,
    isFetching: false,
  };
}

function setSelectedOrigins(state, { selectedOrigins }) {
  const indexOfNA = selectedOrigins.map(({ value }) => value).indexOf(HOTEL_ONLY); // Check if Hotel Only is selected

  if (indexOfNA !== -1) {
    if (indexOfNA === selectedOrigins.length - 1) {
      return {
        ...state,
        selectedOrigins: [selectedOrigins[indexOfNA]],
        hotelOnly: true,
      };
    } else {
      selectedOrigins.splice(indexOfNA, 1);
    }
  }

  return {
    ...state,
    selectedOrigins,
    hotelOnly: false,
  };
}

function setAvailableOrigins(state, { availableOrigins, forceSingleValue }) {
  return {
    ...state,
    availableOrigins,
    forceSingleValue: forceSingleValue || state.origins.length === 1,
  };
}

function setAvailableOriginsMap(state, { availableOrigins, availableOriginsMap, forceSingleValue }) {
  return {
    ...state,
    availableOriginsMap,
    availableOrigins,
    forceSingleValue: forceSingleValue || state.origins.length === 1,
  };
}

function setForceSingleValue(state, { forceSingleValue }) {
  return {
    ...state,
    forceSingleValue,
  };
}

function resetSelectedOrigins(state) {
  return {
    ...state,
    selectedOrigins: initialState.selectedOrigins,
    availableOrigins: initialState.availableOrigins,
  };
}
