import * as types from './types';

const initialState = {
  fetching: null,
  items: null,
  selectedItem: null,
};

function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.SET_FETCHING:
      return setFetching(state, payload);

    case types.SET_COUNTRIES:
      return setCountries(state, payload);

    case types.RESET_STATE:
      return initialState;

    default:
      return state;
  }
}

export default reducer;

function setFetching(state, { fetching }) {
  return {
    ...state,
    fetching,
  };
}

function setCountries(state, { countries }) {
  return {
    ...state,
    items: countries.sortedCountries,
    selectedItem: countries.selectedCountry,
  };
}
