import proxy from 'httpClient/requests';

export function getAffiliateByShortCode(shortCode) {
  return proxy({
    httpMethod: 'GET',
    version: 1,
    apiMethod: 'affiliate',
    body: {
      shortCode,
    },
  });
}
