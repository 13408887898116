import reducer from './reducers';
import * as popularHotelsTypes from './types';
import * as popularHotelsActions from './actions';
import * as popularHotelsOperations from './operations';
import * as popularHotelsSelectors from './selectors';

export { popularHotelsTypes };
export { popularHotelsActions };
export { popularHotelsOperations };
export { popularHotelsSelectors };

export { reducer as default };
