export default function transformResponseIDsToString(response) {
  response.hotels = response.hotels.map((hotel) => {
    hotel.id = hotel.id.toString();
    return hotel;
  });

  response.results = response.results.map((result) => {
    result.hotelId = result.hotelId.toString();
    return result;
  });

  return response;
}
