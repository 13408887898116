import * as types from './types';

export const setSelectedHotel = (hotelId) => ({
  type: types.SET_SELECTED_HOTEL,
  payload: {
    hotelId,
  },
});

export const setMapMoved = (isMapMoved) => ({
  type: types.MAP_MOVED,
  payload: {
    isMapMoved,
  },
});
