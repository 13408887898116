export const TITLE = 'title';
export const FIRST_NAME = 'firstName';
export const LAST_NAME = 'lastName';
export const GENDER = 'gender';
export const EMAIL = 'email';
export const VERIFY_EMAIL = 'verifyEmail';
export const PHONE = 'phone';
export const COUNTRY_CODE = 'countryCode';
export const TELEPHONE = `${COUNTRY_CODE}-${PHONE}`;
export const ADDRESS = 'address';
export const CITY = 'city';
export const POST_CODE = 'postCode';
export const COUNTRY = 'country';
export const DATE_OF_BIRTH = 'dateOfBirth';
// export const PASSPORT_NUMBER = 'passportNumber';
