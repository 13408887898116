import { PRODUCTION as RUNTIME_HOST_ENV_PRODUCTION } from 'constants/env/runtimeHostEnv';

export default function googleTagManagerPush(event, data = {}) {
  const isStageEnvironment = process.env.RUNTIME_HOST_ENV !== RUNTIME_HOST_ENV_PRODUCTION;

  if (isStageEnvironment) {
    console.info('gtmTag', { event, data });
    return;
  }

  window.dataLayer.push({
    event: 'trackEecEvent',
    eventAction: event,
    ...data,
  });
}
