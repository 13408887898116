import * as types from './types';

export function setState(state) {
  return {
    type: types.SET_STATE,
    payload: {
      state,
    },
  };
}

export function setAndValidateInputValue(name, value, validity) {
  return {
    type: types.SET_AND_VALIDATE_INPUT_VALUE,
    payload: {
      name,
      value,
      validity,
    },
  };
}

export function setInputFieldValidity(name, validity) {
  return {
    type: types.SET_INPUT_FIELD_VALIDITY,
    payload: {
      name,
      validity,
    },
  };
}

export function resetCustomerDetails() {
  return {
    type: types.RESET_CUSTOMER_DETAILS,
  };
}
