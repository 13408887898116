export default function isPackageInfoMissing(packageInfo) {
  if (!packageInfo || (packageInfo && !packageInfo.search)) {
    return true;
  }

  const { origin, dates, roomConfigurations } = packageInfo.search;

  if (!origin) {
    return true;
  }

  if (!dates || (dates && (!dates.from || !dates.to))) {
    return true;
  }

  if (!roomConfigurations) {
    return true;
  }

  return false;
}
