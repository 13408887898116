import * as types from './types';

export function addPackage(packet) {
  return {
    type: types.ADD_PACKAGE,
    payload: {
      packet,
    },
  };
}

export function removePackage(packageUniqueKey) {
  return {
    type: types.REMOVE_PACKAGE,
    payload: {
      packageUniqueKey,
    },
  };
}

export function setPackages(packages) {
  return {
    type: types.SET_PACKAGES,
    payload: {
      packages,
    },
  };
}
