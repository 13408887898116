import styled from 'styled-components';
import PrimaryButtonComponent from './PrimaryButtonComponent';

export default styled(PrimaryButtonComponent)`
  ${({ light }) =>
    !light &&
    `
    background: linear-gradient(-180deg, #f2b56d 0%, #fd8a00 35%, #ce5003 80%);
    transition: background 0.3s ease-in-out;

    &:hover {
      background: linear-gradient(-180deg ,#f2b56d 0%, #fd8a00 14%, #ce5003 78%);
      transition: background 0.3s ease-in-out;
    }
  `}
`;
