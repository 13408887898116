import uuidv1 from 'uuid/v1';
import * as types from './types';

const initialState = {
  hydrating: null,
  uuid: null,
};

function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.SET_STATE:
      return payload.state;

    case types.SET_HYDRATING_STATUS:
      return setHydratingStatus(state, payload);

    case types.GENERATE_SEARCH_UUID:
      return generateSearchUuid(state, payload);

    case types.RESET_STATE:
      return initialState;

    default:
      return state;
  }
}

export default reducer;

function setHydratingStatus(state, { hydrating }) {
  return {
    ...state,
    hydrating,
  };
}

function generateSearchUuid(state, { abandonedCartUuid }) {
  return {
    ...state,
    uuid: abandonedCartUuid ? abandonedCartUuid : uuidv1(),
  };
}
