export default function getSelectedHotelsRoomsWithSelectedOption(config = {}) {
  return (dispatch, getState) => {
    const { checkoutPagePackagesSearch, checkoutPageTravelSummary } = getState();
    const { firstHotel, hotelId = firstHotel && checkoutPageTravelSummary.selectedRooms[0].hotelId } = config;
    const hotelsRooms = checkoutPagePackagesSearch.value.hotels.reduce(getHotelsRoomsMappedByHotelId, {});
    const hotelRoomWithSelectedOption = getHotelRoomWithSelectedOptionCallback(hotelsRooms);
    let selectedRooms = checkoutPageTravelSummary.selectedRooms;

    if (hotelId) {
      const byHotelId = getFilterByHotelIdCallback(hotelId);
      selectedRooms = selectedRooms.filter(byHotelId);
    }

    return selectedRooms.map(hotelRoomWithSelectedOption);
  };
}

function getHotelsRoomsMappedByHotelId(accumulator, currentValue) {
  accumulator[currentValue.id] = currentValue.rooms;
  return accumulator;
}

function getFilterByHotelIdCallback(hotelId) {
  return function byHotelId(selectedRoom) {
    return selectedRoom.hotelId === hotelId;
  };
}

function getHotelRoomWithSelectedOptionCallback(hotelsRooms) {
  return function hotelRoomWithSelectedOption(selectedRoom) {
    const { hotelReferenceToken, hotelId, roomIndex, productToken } = selectedRoom;
    const { availableOptions, ...hotelRoom } = hotelsRooms[hotelId][roomIndex];
    const findByProductToken = getFindByProductTokenCallback(productToken);
    const selectedOption = availableOptions.find(findByProductToken);

    return {
      hotelReferenceToken,
      hotelId,
      roomIndex,
      ...hotelRoom,
      selectedOption,
    };
  };
}

function getFindByProductTokenCallback(productToken) {
  return function findByProductToken(availableOption) {
    return availableOption.productToken === productToken;
  };
}
