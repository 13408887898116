/**
 * Update a single object in an array.
 * @param {Array<Object>} array - Array containing the object to update.
 * @param {Number} _index - Position of the object to be updated.
 * @param {Object} object - Object containing the updated properties.
 * @return {Array} Array with updated object.
 */
export default function immmutableUpdateAtIndex(array, _index, object) {
  return array.map((item, index) => {
    if (index !== _index) {
      return item;
    }

    return {
      ...item,
      ...object,
    };
  });
}
