import React from 'react';
import { getOrCreateStore } from 'state/storeProvider';
import _groupBy from 'lodash/groupBy';
import _sortBy from 'lodash/orderBy';
import { FormattedMessage } from 'i18n';
import * as passengerTypes from 'state/checkoutPage/travelers/constants/passengerTypes';
import * as flightDirections from 'state/checkoutPage/flightsDetails/constants/flightDirections';
import getKeyValuesFromObject from 'utils/array/getKeyValuesFromObject';
import SingleChoiceUpgrade from 'components/upgrades/singleChoiceUpgrade/SingleChoiceUpgradeComponent';
import MultipleChoiceUpgrade from 'components/upgrades/multipleChoiceUpgrade/MultipleChoiceUpgradeComponent';
import {
  getSelectedFlights,
  filterCombinationByType,
  filterLegsByType,
  filterFlightsByType,
  filterCo2Compensation,
} from './utils/transformInitialData';
import { PRIORITY_BUNDLE as PRIORITY_BUNDLE_SERVICE } from 'routes/checkoutPage/constants/servicesKeys';
import getOptionsForUpgrades from './selectors/getOptionsForUpgrades';

export function transformFlightDetailsData(currentData) {
  const { combination } = currentData;
  const { legs, upgrades, searchToken, productToken } = combination;
  const selectedFlights = getSelectedFlights();

  const result = getKeyValuesFromObject(combination, 'type').reduce((acc, type) => {
    acc[type] = {
      combination: filterCombinationByType(upgrades, type, selectedFlights),
      legs: filterLegsByType(legs, type, selectedFlights),
      flights: filterFlightsByType(legs, type, selectedFlights),
    };
    return acc;
  }, {});

  const co2Compensation = filterCo2Compensation(combination, selectedFlights);
  if (co2Compensation && co2Compensation.length > 0) {
    result.co2Compensation = co2Compensation;
  }

  result['searchToken'] = searchToken;
  result['productToken'] = productToken;

  return result;
}

export function groupUpgradesPerAllowedPassengerType(upgrades) {
  let groups = { ADT: [], CHD: [], INF: [], GROUP: [] };
  const { true: upgradesPerPassenger, false: groupUpgrades } = _groupBy(upgrades, 'perPassenger');

  if (upgradesPerPassenger) {
    for (const property in passengerTypes) {
      groups[passengerTypes[property]] = upgradesPerPassenger.filter(
        ({ allowedPaxTypes, allowedPaxAges }) =>
          allowedPaxTypes.indexOf(passengerTypes[property]) !== -1 && !allowedPaxAges
      );
    }

    upgradesPerPassenger.forEach((upgrade) => {
      if (upgrade.allowedPaxAges && upgrade.allowedPaxAges.length > 0) {
        const hasAdults = upgrade.allowedPaxAges.filter((paxAge) => paxAge >= 18).length > 0;
        const hasChildren = upgrade.allowedPaxAges.filter((paxAge) => paxAge < 18).length > 0;

        if (hasAdults) {
          groups['ADT'].push(upgrade);
        }

        if (hasChildren) {
          groups['CHD'].push(upgrade);
        }
      }
    });
  }

  if (groupUpgrades) {
    groups[passengerTypes.GROUP] = groupUpgrades;
  }

  return groups;
}

export function createUpgrades({
  groups,
  numberOfAdults,
  numberOfChildren,
  numberOfInfants,
  childrenAges,
  serviceKey,
  currency,
}) {
  const results = [];
  const { getState } = getOrCreateStore();
  const { settings } = getState();
  const { airlineWhitelabel } = settings.value;
  const Component = serviceKey === PRIORITY_BUNDLE_SERVICE ? SingleChoiceUpgrade : MultipleChoiceUpgrade;

  for (const property in groups) {
    const groupUpgrade = property === passengerTypes.GROUP;
    const groupOrInfantUpgrade = groupUpgrade || property === passengerTypes.INFANT;

    // THIS IF WILL ALWAYS BE TRUE FOR ADULT & CHILD , BUT DOES SECOND CHECK IF GROUPS OR INFANT HAVE ANY UPGRADE
    if (!groupOrInfantUpgrade || groups[property].length > 0) {
      let numberOfUpgrades;
      const titleProps = [];

      let childrenAsAdults = 0;

      const paxAgesDefined = Object.values(groups)
        .flat()
        .find(({ allowedPaxAges }) => allowedPaxAges !== null);

      if (!paxAgesDefined) {
        childrenAsAdults = childrenAges.filter((ages) => ages >= 12).length;
      } else {
        //THIS IS NOT THE FINAL SOLUTION FOR THIS ISSUE
        //TODO : consider mapping travelers with PaxAges when defined
        const hasChildrenAsAdultsDefined = Object.values(groups)
          .flat()
          .find(({ allowedPaxAges }) => allowedPaxAges >= 12 && allowedPaxAges < 18);
        if (!hasChildrenAsAdultsDefined) {
          childrenAsAdults = childrenAges.filter((ages) => ages >= 12).length;
        }
      }

      if (property === passengerTypes.ADULT) {
        titleProps.push(passengerTypes.ADULT);
        if (childrenAsAdults > 0) {
          Array.from(Array(childrenAsAdults)).forEach((item) => {
            titleProps.push(passengerTypes.CHILD);
          });
        }
        numberOfUpgrades = numberOfAdults + childrenAsAdults;
      } else if (property === passengerTypes.CHILD) {
        titleProps.push(passengerTypes.CHILD);
        numberOfUpgrades = numberOfChildren - childrenAsAdults;
      } else if (property === passengerTypes.INFANT) {
        titleProps.push(passengerTypes.INFANT);
        numberOfUpgrades = numberOfInfants;
      } else {
        numberOfUpgrades = 1;
      }

      // If the upgrade has `options` property then render the `MultipleChoiceUpgrade` component
      // otherwise use the `SingleChoiceUpgrade` component.
      results.push(
        Array.from(Array(numberOfUpgrades)).map((item, index) => ({
          key: `${serviceKey}-${titleProps[index] ? titleProps[index] : property}-${index}`,
          serviceKey,
          title:
            property !== passengerTypes.GROUP ? (
              <FormattedMessage
                id={`${titleProps[index] ? titleProps[index].toLowerCase() : property.toLowerCase()}.numbered.label`}
                description={`${titleProps[index]} {index}`}
                defaultMessage={`${titleProps[index]} {index}`}
                values={{ index: index + 1 }}
              />
            ) : null,
          upgrades: serviceKey === PRIORITY_BUNDLE_SERVICE ? groups[property] : getOptionsForUpgrades(groups[property]),
          passengerType: titleProps[index] ? titleProps[index] : property,
          passengerIndex: !groupUpgrade ? index : undefined,
          currency,
          airlineWhitelabel,
        }))
      );
    }
  }

  const grouped = _groupBy(results.flat(), 'passengerType');

  const adultGroup =
    grouped['ADT'] &&
    grouped['ADT'].length > 0 &&
    grouped['ADT'].map((item, index) => ({
      ...item,
      passengerIndex: index,
      title: (
        <FormattedMessage
          id={`${passengerTypes.ADULT.toLowerCase()}.numbered.label`}
          description={`${passengerTypes.ADULT} {index}`}
          defaultMessage={`${passengerTypes.ADULT} {index}`}
          values={{ index: index + 1 }}
        />
      ),
    }));

  const childGroup =
    grouped['CHD'] &&
    grouped['CHD'].length > 0 &&
    grouped['CHD'].map((item, index) => ({
      ...item,
      passengerIndex: index,
      title: (
        <FormattedMessage
          id={`${passengerTypes.CHILD.toLowerCase()}.numbered.label`}
          description={`${passengerTypes.CHILD} {index}`}
          defaultMessage={`${passengerTypes.CHILD} {index}`}
          values={{ index: index + 1 }}
        />
      ),
    }));

  const groupResults = { ...grouped };

  if (adultGroup) {
    groupResults[passengerTypes.ADULT] = adultGroup;
  }

  if (childGroup) {
    groupResults[passengerTypes.CHILD] = childGroup;
  }

  const _results = Object.values(groupResults)
    .flat()
    .map((item, index) => <Component key={item ? item.key : index} {...item} />);

  return _sortBy(_results, (result) => result.props.upgrades.map((upgrade) => upgrade.direction));
}

export function getUpgradesByUpgradeToken(upgradeTokens, flightsDetailsValue) {
  const upgrades = {};

  if (flightsDetailsValue) {
    const { searchToken, productToken, co2Compensation, ...services } = flightsDetailsValue;
    const servicesFlat = Object.values(services)
      .map((item) => Object.values(item))
      .flat()
      .flat();

    upgradeTokens.forEach((upgradeToken) => {
      let piece;
      const service = servicesFlat.find((service) => {
        const _piece = service.pieces.find(({ upgradeToken: pieceUpgradeToken }) => pieceUpgradeToken === upgradeToken);

        if (_piece) {
          piece = _piece;
          return true;
        }

        return false;
      });

      upgrades[upgradeToken] = { ...service, piece };
    });
  }

  return upgrades;
}

export function combineUpgradesIncludedServices(upgrades = [], includedServices = []) {
  const servicesToRemove = [];
  const _upgrades = upgrades.map((upgrade) => {
    const includedServiceIndex = includedServices.findIndex(
      (service) =>
        service.direction === upgrade.direction &&
        service.allowedPaxTypes &&
        service.allowedPaxTypes.some((paxType) => upgrade.allowedPaxTypes.includes(paxType)) &&
        service.departureAirport === upgrade.departureAirport &&
        service.destinationAirport === upgrade.destinationAirport
    );

    if (includedServiceIndex !== -1) {
      servicesToRemove.push(includedServiceIndex);
      upgrade.includedService = includedServices[includedServiceIndex].includedService;
    }

    return upgrade;
  });
  const _includedServices = includedServices.filter((item, index) => servicesToRemove.indexOf(index) === -1);

  return [..._upgrades, ..._includedServices].sort(sortByDirection);
}

function sortByDirection(a, b) {
  const aIsLeave = a.direction === flightDirections.LEAVE;
  const bIsLeave = b.direction === flightDirections.LEAVE;

  if (aIsLeave && !bIsLeave) {
    return -1;
  }

  if (!aIsLeave && bIsLeave) {
    return 1;
  }

  return 0;
}
