import { combineReducers } from 'redux';
import { loadingBarReducer } from 'react-redux-loading-bar';
import actionBar from './actionBar';
import adverts from './adverts';
import advertsFilters from './advertsFilters';
import agent from './agent';
import checkoutPageAbandonedCart from './checkoutPage/abandonedCart';
import checkoutPageBase from './checkoutPage/base';
import checkoutPageBooking from './checkoutPage/booking';
import checkoutPageCancellationProtections from './checkoutPage/cancellationProtections';
import checkoutPageCustomerDetails from './checkoutPage/customerDetails';
import checkoutPageFlights from './checkoutPage/flights';
import checkoutPageFlightsDetails from './checkoutPage/flightsDetails';
import checkoutPageFlightsFilters from './checkoutPage/flightsFilters';
import checkoutPagePackagesSearch from './checkoutPage/packagesSearch';
import checkoutPagePayment from './checkoutPage/payment';
import checkoutPagePaymentAdyen from './checkoutPage/paymentAdyen';
import checkoutPagePaymentCheckboxes from './checkoutPage/paymentCheckboxes';
import checkoutPagePaymentKlarna from './checkoutPage/paymentKlarna';
import checkoutPagePaymentNetsEasy from './checkoutPage/paymentNetsEasy';
import checkoutPagePaymentModal from './checkoutPage/paymentModal';
import checkoutPagePaymentOptions from './checkoutPage/paymentOptions';
import checkoutPagePaymentProviderTripX from './checkoutPage/paymentProviders/paymentProviderTripX';
import checkoutPagePromotionCode from './checkoutPage/promotionCode';
import checkoutPageInsurances from './checkoutPage/insurances';
import checkoutPageTravelers from './checkoutPage/travelers';
import checkoutPageTransfers from './checkoutPage/transfers';
import checkoutPageAdditionalServices from './checkoutPage/additionalServices';
import checkoutPageTravelSummary from './checkoutPage/travelSummary';
import checkoutPageVehicleRental from './checkoutPage/vehicleRental';
import checkoutPageCarRentalDriver from './checkoutPage/carRentalDriver';
import checkoutPageVehicleRentalFilters from './checkoutPage/vehicleRentalFilters';
import checkoutPageVehicleRentalTsAndCs from './checkoutPage/vehicleRentalTsAndCs';
import checkoutPageWizard from './checkoutPage/wizard';
import currentPageComponent from './currentPageComponent';
import cookie from './cookie';
import deal from './deal';
import deals from './deals';
import destinations from './destinations';
import flags from './flags';
import flexibleDatesInformer from './flexibleDatesInformer';
import homeBoxes from './homeBoxes';
import hotelPage from './hotelPage';
import hotelPagePackageInfo from './hotelPage/packageInfo';
import hotelPageMonthlyPrices from './hotelPageMonthlyPrices';
import hotelQuerySearch from './hotelQuerySearch';
import intersectionObserver from './intersectionObserver';
import logger from './logger';
import lastminute from './lastminute';
import menus from './menus';
import popularHotels from './popularHotels';
import priceBars from './priceBars';
import refs from './refs';
import routes from './routes';
import savedPackages from './savedPackages';
import searchConditions from './searchConditions';
import searchContainer from './searchContainer';
import searchResults from './searchResults';
import searchResultsFilters from './searchResultsFilters';
import searchResultsMap from './searchResultsMap';
import searchResultsSpecific from './searchResultsSpecific';
import selectDates from './selectDates';
import selectDestinations from './selectDestinations';
import selectOrigins from './selectOrigins';
import selectRooms from './selectRooms';
import settings from './settings';
import template from './template';
import templatesDestinations from './templates/destinations';
import templatesHotel from './templates/hotel';
import templatesHotelSearchParametersSelectDates from './templates/hotel/searchParameters/selectDates';
import templatesHotelSearchParametersSelectOrigin from './templates/hotel/searchParameters/selectOrigin';
import templatesHotelSearchParametersSelectRooms from './templates/hotel/searchParameters/selectRooms';
import templatesThemes from './templates/themes';
import theme from './theme';
import urlSearchQuery from './urlSearchQuery';
import userBookings from './userBookings';
import countryPicker from './countryPicker';
import worldSearch from './worldSearch';
import webSitePicker from './webSitePicker';

export default {
  loadingBar: loadingBarReducer,
  actionBar,
  adverts,
  advertsFilters,
  agent,
  countryPicker,
  checkoutPageAbandonedCart,
  checkoutPageBase,
  checkoutPageBooking,
  checkoutPageCancellationProtections,
  checkoutPageCustomerDetails,
  checkoutPageFlights,
  checkoutPageFlightsDetails,
  checkoutPageFlightsFilters,
  checkoutPagePackagesSearch,
  checkoutPagePayment,
  checkoutPagePaymentAdyen,
  checkoutPagePaymentCheckboxes,
  checkoutPagePaymentKlarna,
  checkoutPagePaymentNetsEasy,
  checkoutPagePaymentModal,
  checkoutPagePaymentOptions,
  checkoutPagePaymentProviderTripX,
  checkoutPagePromotionCode,
  checkoutPageInsurances,
  checkoutPageTravelers,
  checkoutPageTransfers,
  checkoutPageAdditionalServices,
  checkoutPageTravelSummary,
  checkoutPageVehicleRental,
  checkoutPageVehicleRentalFilters,
  checkoutPageVehicleRentalTsAndCs,
  checkoutPageCarRentalDriver,
  checkoutPageWizard,
  currentPageComponent,
  cookie,
  deal,
  deals,
  destinations,
  flags,
  flexibleDatesInformer,
  homeBoxes,
  hotelPage,
  hotelPagePackageInfo,
  hotelPageMonthlyPrices,
  hotelQuerySearch,
  intersectionObserver,
  logger,
  lastminute,
  menus,
  popularHotels,
  priceBars,
  refs,
  routes,
  savedPackages,
  searchConditions,
  searchContainer,
  searchResults,
  searchResultsFilters,
  searchResultsMap,
  searchResultsSpecific,
  selectDates,
  selectDestinations,
  selectOrigins,
  selectRooms,
  settings,
  template,
  templates: combineReducers({
    destinations: templatesDestinations,
    hotel: templatesHotel,
    themes: templatesThemes,
  }),
  templatesHotelSearchParameters: combineReducers({
    selectDates: templatesHotelSearchParametersSelectDates,
    selectOrigin: templatesHotelSearchParametersSelectOrigin,
    selectRooms: templatesHotelSearchParametersSelectRooms,
  }),
  theme,
  urlSearchQuery,
  userBookings,
  worldSearch,
  webSitePicker,
};
