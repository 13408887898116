export const PACKAGES_DOHOP = 'packages.dohop';
export const RESEGUIDEN = 'boka.reseguiden.se';
export const RESEGUIDEN_NO = 'bestill.reiseguiden.no';
export const HILLMAN_TRAVEL_EU = 'hillman.travel';
export const HILLMAN_TRAVEL_NL = 'hillman.travel';
export const AMANO_HOLIDAYS = 'amano.holidays';
export const MATKAT_OFFERILLA = 'matkat.offerilla';
export const HOLIDAY_PIRATES = 'holidaypirates';
export const LOTHOTEL_WAKACJE = 'lothotel.wakacje';
export const HOLIDAYMIX_INVIA = 'holidaymix.invia';
