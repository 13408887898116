import * as types from './types';

const initialState = {
  login: null,
  loginError: null,
  cancelBooking: null,
  cancellationSuccess: null,
  credentials: {
    email: '',
    bookingCode: '',
  },
  hotelImages: [],
  value: {},
  destination: null,
};

function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.SET_LOGIN:
      return setLogin(state, payload);

    case types.SET_LOGIN_ERROR:
      return setLoginError(state, payload);

    case types.SET_EMAIL:
      return setEmail(state, payload);

    case types.SET_BOOKING_CODE:
      return setBookingCode(state, payload);

    case types.SET_CREDENTIALS:
      return setCredentials(state, payload);

    case types.SET_USER_BOOKINGS:
      return setUserBookings(state, payload);

    case types.SET_HOTEL_IMAGES:
      return setHotelImages(state, payload);

    case types.SET_DESTINATION:
      return setDestination(state, payload);

    case types.SET_CANCEL_BOOKING:
      return setCancelBooking(state, payload);

    case types.SET_CANCELLATION_SUCCESS:
      return setCancellationSuccess(state, payload);

    case types.RESET_USER_BOOKINGS:
      return initialState;

    default:
      return state;
  }
}

export default reducer;

function setLogin(state, { login }) {
  return {
    ...state,
    login,
  };
}

function setLoginError(state, { loginError }) {
  return {
    ...state,
    loginError,
  };
}

function setEmail(state, { email }) {
  return {
    ...state,
    credentials: {
      ...state.credentials,
      email,
    },
  };
}

function setBookingCode(state, { bookingCode }) {
  return {
    ...state,
    credentials: {
      ...state.credentials,
      bookingCode,
    },
  };
}

function setCredentials(state, { credentials }) {
  return {
    ...state,
    credentials,
  };
}

function setUserBookings(state, { bookings }) {
  return {
    ...state,
    value: bookings,
  };
}

function setHotelImages(state, { hotelImages }) {
  return {
    ...state,
    hotelImages,
  };
}
function setDestination(state, { destination }) {
  return {
    ...state,
    destination,
  };
}

function setCancelBooking(state, { cancelBooking }) {
  return {
    ...state,
    cancelBooking,
  };
}

function setCancellationSuccess(state, { cancellationSuccess }) {
  return {
    ...state,
    cancellationSuccess,
  };
}
