import trackEecEvent from '../utils/trackEecEvent';

export default function singleHotelSearch(eventName, { currency, hotelInfo, data }) {
  const eventData = {
    ecommerce: {
      currencyCode: currency.toUpperCase(),
      detail: {
        products: [
          {
            id: hotelInfo.id,
            name: hotelInfo.name,
            price: data.packages[0].price.total,
            category: `${hotelInfo.location.country}/${hotelInfo.location.destination.name}`,
            quantity: 1,
          },
        ],
      },
    },
  };

  trackEecEvent(eventName, eventData);
}
