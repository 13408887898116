import * as types from './types';

const initialState = {
  destinations: [],
  fetching: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_DESTINATIONS:
      state = setDestinations(state, action.payload);
      break;
    case types.SET_FETCHING:
      return setFetching(state, action.payload);
    default:
      break;
  }

  return state;
};

export default reducer;

// Private functions
function setDestinations(state, payload) {
  return {
    ...state,
    destinations: payload.destinations,
  };
}

function setFetching(state, { fetching }) {
  return {
    ...state,
    fetching,
  };
}
