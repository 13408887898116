import { getDestinations } from 'httpClient/requests/templates/destinations';
import { setDestinations, setFetching } from './actions';
import { setCountriesCoordinates } from './selectors';
import Router from 'next/router';
import { TEMPLATE as PAGE_TEMPLATE } from 'constants/pages';
import * as fetchingStatus from 'constants/status/fetchingStatus';

// @TODO Why do we have separate destinations for templates? This should be removed and selectDestinations to be used.
export function fetchDestinations() {
  return (dispatch, getState) => {
    const { fetching } = getState().templates.destinations;

    if (fetching === fetchingStatus.IN_PROGRESS) {
      return;
    }

    dispatch(setFetching(fetchingStatus.IN_PROGRESS));
    return getDestinations()
      .then((response) => {
        const destinations = setCountriesCoordinates(response.data);
        dispatch(setDestinations(destinations));
        dispatch(setFetching(fetchingStatus.SUCCEEDED));
      })
      .catch((error) => {
        dispatch(setFetching(fetchingStatus.FAILED));
        console.error(error);
      });
  };
}

export function redirectToCountriesTemplatePage() {
  return (dispatch) => {
    const pathname = Router.asPath.split('?')[0];
    const indexOfCountry = pathname.lastIndexOf('/');
    const nextPathname = pathname.slice(0, indexOfCountry);

    window.Rollbar.error(`Country Info is missing`, nextPathname);

    Router.push(PAGE_TEMPLATE, nextPathname);
  };
}
