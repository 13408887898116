export default function formatDestination(destination, country, disabled = false) {
  // To be able to filter the options by aliases we need to include them in the label.
  // Then we override the react-select Option component to display only the city.name.
  return {
    value: destination.code,
    label: `${destination.name}-${destination.alias.toString()}`,
    destinationName: destination.name,
    disabled,
    country: country.code,
    countHotels: destination.countHotels,
  };
}
