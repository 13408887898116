import pop from 'utils/immutable/pop';
import push from 'utils/immutable/push';
import removeAtIndex from 'utils/immutable/removeAtIndex';
import replaceAtIndex from 'utils/immutable/replaceAtIndex';
import updateAtIndex from 'utils/immutable/updateAtIndex';
import * as types from './types';
import checkMoreInfantsThanAdults from './operations/checkMoreInfantsThanAdults';
import { moreInfantsThanAdultsStateHandler } from 'state/selectRooms/reducers';
import { checkForRoomWithoutChildrenOrAdults } from 'state/selectRooms/reducers';

const initialState = {
  rooms: [
    {
      numberOfAdults: 2,
      numberOfChildren: 0,
      childrenAges: [],
      isMoreInfantsThanAdults: false,
    },
  ],
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case types.UPDATE_NUMBER_OF_ADULTS:
      return updateNumberOfAdults(state, action.payload);

    case types.UPDATE_NUMBER_OF_CHILDREN:
      return updateNumberOfChildren(state, action.payload);

    case types.UPDATE_CHILD_AGE:
      return updateChildAge(state, action.payload);

    case types.ADD_ROOM:
      return addRoom(state);

    case types.REMOVE_ROOM:
      return removeRoom(state);

    case types.REMOVE_ROOM_AT_INDEX:
      return removeRoomAtIndex(state, action.payload);

    case types.SET_ROOMS:
      return setRooms(state, action.payload);

    default:
      return state;
  }
}

export default reducer;

function updateNumberOfAdults(state, { roomNumber, value }) {
  const isMoreInfantsThanAdults = checkMoreInfantsThanAdults(
    state.rooms[roomNumber].childrenAges,
    state.rooms[roomNumber].numberOfAdults + value
  );
  return {
    ...state,
    rooms: updateAtIndex(state.rooms, roomNumber, {
      numberOfAdults: state.rooms[roomNumber].numberOfAdults + value,
      isMoreInfantsThanAdults: isMoreInfantsThanAdults,
    }),
  };
}

function updateNumberOfChildren(state, { value, roomIndex, childIndex, childAge }) {
  const isMoreInfantsThanAdults = checkMoreInfantsThanAdults(
    state.rooms[roomIndex].childrenAges,
    state.rooms[roomIndex].numberOfAdults,
    value
  );

  const { numberOfChildren, childrenAges } = moreInfantsThanAdultsStateHandler(
    isMoreInfantsThanAdults,
    state.rooms[roomIndex].numberOfChildren,
    state.rooms[roomIndex].childrenAges,
    value,
    childAge,
    childIndex
  );

  const newState = updateAtIndex(state.rooms, roomIndex, {
    numberOfChildren,
    childrenAges,
    isMoreInfantsThanAdults,
  });

  const roomWithoutChildrenOrAdults = checkForRoomWithoutChildrenOrAdults(newState);

  return {
    ...state,
    rooms: roomWithoutChildrenOrAdults !== -1 ? removeAtIndex(newState, roomWithoutChildrenOrAdults) : newState,
  };
}

function updateChildAge(state, { roomNumber, childNumber, value }) {
  const isMoreInfantsThanAdults = checkMoreInfantsThanAdults(
    replaceAtIndex(
      state.rooms[roomNumber].childrenAges,
      childNumber,
      state.rooms[roomNumber].childrenAges[childNumber] + value
    ),
    state.rooms[roomNumber].numberOfAdults
  );
  return {
    ...state,
    rooms: updateAtIndex(state.rooms, roomNumber, {
      childrenAges: replaceAtIndex(
        state.rooms[roomNumber].childrenAges,
        childNumber,
        state.rooms[roomNumber].childrenAges[childNumber] + value
      ),
      isMoreInfantsThanAdults: isMoreInfantsThanAdults,
    }),
  };
}

function addRoom(state) {
  return {
    ...state,
    rooms: push(state.rooms, {
      numberOfAdults: 2,
      numberOfChildren: 0,
      childrenAges: [],
    }),
  };
}

function removeRoom(state) {
  return {
    ...state,
    rooms: pop(state.rooms),
  };
}

function removeRoomAtIndex(state, { index }) {
  return {
    ...state,
    rooms: removeAtIndex(state.rooms, index),
  };
}

function setRooms(state, { rooms }) {
  return {
    ...state,
    rooms,
  };
}
