export default function getVehicleRentalsByProductTokens(vehicleRentals, selectedVehicleRentals) {
  if (!vehicleRentals || !selectedVehicleRentals || (selectedVehicleRentals && selectedVehicleRentals.length === 0)) {
    return null;
  }

  const productTokens = selectedVehicleRentals.map(getProductToken);
  const filterByProductToken = getFilterByProductTokenCallback(productTokens);
  return vehicleRentals.filter(filterByProductToken);
}

export function getProductToken(selectedVehicleRental) {
  return selectedVehicleRental.productToken;
}

function getFilterByProductTokenCallback(productTokens) {
  return function filterByProductToken(vehicleRental) {
    return productTokens.indexOf(vehicleRental.productToken) !== -1;
  };
}
