import * as types from './types';

const initialState = {
  value: {},
};

function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.SET_VALUE:
      return setValue(state, payload);

    case types.RESET_STATE:
      return initialState;

    default:
      return state;
  }
}

export default reducer;

function setValue(state, { value }) {
  return {
    ...state,
    value,
  };
}
