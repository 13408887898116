import pop from 'utils/immutable/pop';
import push from 'utils/immutable/push';
import removeAtIndex from 'utils/immutable/removeAtIndex';
import replaceAtIndex from 'utils/immutable/replaceAtIndex';
import updateAtIndex from 'utils/immutable/updateAtIndex';
import * as types from './types';
import checkMoreInfantsThanAdults from 'state/templates/hotel/searchParameters/selectRooms/operations/checkMoreInfantsThanAdults';
import getNumberOfAdultsAndChildren from './selectors/getNumberOfAdultsAndChildren';

const initialState = {
  rooms: [
    {
      numberOfAdults: 2,
      numberOfChildren: 0,
      childrenAges: [],
      isMoreInfantsThanAdults: false,
    },
  ],
};

function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.UPDATE_NUMBER_OF_ADULTS:
      return updateNumberOfAdults(state, payload);

    case types.UPDATE_NUMBER_OF_CHILDREN:
      return updateNumberOfChildren(state, payload);

    case types.UPDATE_CHILD_AGE:
      return updateChildAge(state, payload);

    case types.ADD_ROOM:
      return addRoom(state);

    case types.REMOVE_ROOM:
      return removeRoom(state);

    case types.REMOVE_ROOM_AT_INDEX:
      return removeRoomAtIndex(state, payload);

    case types.SET_ROOMS:
      return setRooms(state, payload);

    case types.RESET_ROOMS:
      return resetRooms();

    default:
      return state;
  }
}

export default reducer;

function updateNumberOfAdults(state, { value, roomIndex }) {
  const isMoreInfantsThanAdults = checkMoreInfantsThanAdults(
    state.rooms[roomIndex].childrenAges,
    state.rooms[roomIndex].numberOfAdults + value
  );
  const newState = updateAtIndex(state.rooms, roomIndex, {
    numberOfAdults: state.rooms[roomIndex].numberOfAdults + value,
    isMoreInfantsThanAdults: isMoreInfantsThanAdults,
  });

  const roomWithoutChildrenOrAdults = checkForRoomWithoutChildrenOrAdults(newState);

  return {
    ...state,
    rooms: roomWithoutChildrenOrAdults !== -1 ? removeAtIndex(newState, roomWithoutChildrenOrAdults) : newState,
  };
}

function updateNumberOfChildren(state, { value, roomIndex, childIndex, childAge }) {
  const isMoreInfantsThanAdults = checkMoreInfantsThanAdults(
    state.rooms[roomIndex].childrenAges,
    state.rooms[roomIndex].numberOfAdults,
    value
  );

  const { numberOfChildren, childrenAges } = moreInfantsThanAdultsStateHandler(
    isMoreInfantsThanAdults,
    state.rooms[roomIndex].numberOfChildren,
    state.rooms[roomIndex].childrenAges,
    value,
    childAge,
    childIndex
  );

  const newState = updateAtIndex(state.rooms, roomIndex, {
    numberOfChildren,
    childrenAges,
    isMoreInfantsThanAdults,
  });

  const roomWithoutChildrenOrAdults = checkForRoomWithoutChildrenOrAdults(newState);

  return {
    ...state,
    rooms: roomWithoutChildrenOrAdults !== -1 ? removeAtIndex(newState, roomWithoutChildrenOrAdults) : newState,
  };
}

function updateChildAge(state, { value, roomIndex, childIndex }) {
  const isMoreInfantsThanAdults = checkMoreInfantsThanAdults(
    replaceAtIndex(
      state.rooms[roomIndex].childrenAges,
      childIndex,
      state.rooms[roomIndex].childrenAges[childIndex] + value
    ),
    state.rooms[roomIndex].numberOfAdults
  );
  return {
    ...state,
    rooms: updateAtIndex(state.rooms, roomIndex, {
      childrenAges: replaceAtIndex(
        state.rooms[roomIndex].childrenAges,
        childIndex,
        state.rooms[roomIndex].childrenAges[childIndex] + value
      ),
      isMoreInfantsThanAdults: isMoreInfantsThanAdults,
    }),
  };
}

function addRoom(state) {
  const { numberOfAdults, numberOfChildren } = getNumberOfAdultsAndChildren(state.rooms);
  const totalPax = numberOfAdults + numberOfChildren;
  return {
    ...state,
    rooms: push(state.rooms, {
      numberOfAdults: totalPax === 8 ? 1 : 2,
      numberOfChildren: 0,
      childrenAges: [],
    }),
  };
}

function removeRoom(state) {
  return {
    ...state,
    rooms: pop(state.rooms),
  };
}

function removeRoomAtIndex(state, payload) {
  return {
    ...state,
    rooms: removeAtIndex(state.rooms, payload.index),
  };
}

function setRooms(state, payload) {
  return {
    ...state,
    rooms: payload.rooms,
  };
}

function resetRooms() {
  return {
    ...initialState,
  };
}

export function checkForRoomWithoutChildrenOrAdults(data) {
  return data.findIndex(({ numberOfAdults, numberOfChildren }) => numberOfAdults === 0 && numberOfChildren === 0);
}

export function moreInfantsThanAdultsStateHandler(
  isMoreInfantsThanAdults,
  numberOfChildren,
  childrenAges,
  value,
  childAge,
  childIndex
) {
  const _numberOfChildren = isMoreInfantsThanAdults ? numberOfChildren : numberOfChildren + value;

  let _childrenAges;
  if (isMoreInfantsThanAdults) {
    _childrenAges = childrenAges;
  } else if (value > 0) {
    _childrenAges = push(childrenAges, childAge);
  } else {
    _childrenAges = removeAtIndex(childrenAges, childIndex);
  }
  return {
    numberOfChildren: _numberOfChildren,
    childrenAges: _childrenAges,
  };
}
