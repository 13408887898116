import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import { composeWithDevTools } from 'redux-devtools-extension';
import searchResultsMiddleware from './_middleware/searchResultsMiddleware';
import reducers from './reducers';

export function initializeStore(initialState = {}) {
  const middleware = [thunk, promise(), searchResultsMiddleware];

  //! UNCOMMENT IF YOU WANT ALL ACTION AND STATE TO BE LOGGED INSIDE CONSOLE, FOR DEBUGGING.
  //! BUT YOU CAN USE REDUX DEVTOOLS EXTENSION INSIDE YOUR BROWSER
  // if (process.env.NODE_ENV !== 'production' && process.browser) {
  //   // Note: logger must be the last middleware in chain,
  //   // otherwise it will log thunk and promise, not actual actions.
  //   middleware.push(logger);
  // }

  return createStore(
    combineReducers({
      ...reducers,
    }),
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
  );
}
