import * as types from './types';

const initialState = {
  inputsHidden: true,
  inputsHiddenOnMobile: true,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_INPUTS_HIDDEN:
      return setInputsHidden(state, action.payload);

    case types.TOGGLE_INPUTS_HIDDEN_ON_MOBILE:
      return toggleInputsHiddenOnMobile(state);

    case types.RESET_INPUTS_HIDDEN:
      return resetInputsHidden(state);

    case types.RESET_INPUTS_HIDDEN_ON_MOBILE:
      return resetInputsHiddenOnMobile(state);

    default:
      return state;
  }
}

export default reducer;

function setInputsHidden(state, { inputsHidden }) {
  return {
    ...state,
    inputsHidden,
  };
}

function toggleInputsHiddenOnMobile(state) {
  return {
    ...state,
    inputsHidden: !state.inputsHiddenOnMobile,
    inputsHiddenOnMobile: !state.inputsHiddenOnMobile,
  };
}

function resetInputsHidden(state) {
  return {
    ...state,
    inputsHidden: initialState.inputsHidden,
  };
}

function resetInputsHiddenOnMobile(state) {
  return {
    ...state,
    inputsHidden: initialState.inputsHiddenOnMobile,
    inputsHiddenOnMobile: initialState.inputsHiddenOnMobile,
  };
}
