import trackEvent from '../utils/trackEvent';

export default function searchResultsLoaded(eventName, { params, response }) {
  const eventData = {
    content_type: 'hotel',
    checkin_date: params.dates.from ? params.dates.from : response.data.results[0].dates.from,
    checkout_date: params.dates.to ? params.dates.to : response.data.results[0].dates.to,
    content_ids: response.data.results.slice(0, 5).map(({ hotelId }) => hotelId),
    city: response.data.hotels[0].location.city,
    region: response.data.hotels[0].location.city,
    country: response.data.hotels[0].location.country,
    num_adults: params.roomConfigurations
      .map(({ numberOfAdults }) => numberOfAdults)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0),
    num_children: params.roomConfigurations
      .map(({ numberOfChildren }) => numberOfChildren)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0),
  };

  if (eventData.checkin_date === null) {
    delete eventData.checkin_date;
  }

  if (eventData.checkout_date === null) {
    delete eventData.checkout_date;
  }

  trackEvent(eventName, eventData);
}
