import updateObject from 'utils/immutable/updateObject';
import * as types from './types';

const initialState = {
  selectedDates: {
    from: null,
    to: null,
    flexibleDates: false,
  },
  priceDays: {
    fetching: null,
    value: null,
    calendarPriceInfo: null,
  },
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_SELECTED_DATES:
      return setSelectedDates(state, action.payload);

    case types.SET_PRICE_DAYS:
      return setPriceDays(state, action.payload);

    case types.SET_PRICE_DAYS_FETCHING:
      return setPriceDaysFetching(state, action.payload);

    case types.SET_PRICE_DAYS_CALENDAR_INFO:
      return setPriceDaysCalendarInfo(state, action.payload);

    default:
      return state;
  }
}

export default reducer;

function setSelectedDates(state, { selectedDates }) {
  return {
    ...state,
    selectedDates: updateObject(state.selectedDates, {
      from: selectedDates.startDate,
      to: selectedDates.endDate,
    }),
  };
}

function setPriceDays(state, { priceDays }) {
  return {
    ...state,
    priceDays: {
      value: priceDays,
    },
  };
}
function setPriceDaysFetching(state, { fetching }) {
  return {
    ...state,
    priceDays: {
      ...state.priceDays,
      fetching,
    },
  };
}

function setPriceDaysCalendarInfo(state, { calendarPriceInfo }) {
  return {
    ...state,
    priceDays: {
      ...state.priceDays,
      calendarPriceInfo,
    },
  };
}
