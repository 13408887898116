import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const propTypes = {
  disabled: PropTypes.bool,
  light: PropTypes.bool,
  fullWidth: PropTypes.bool,
  padding: PropTypes.string,
  onClick: PropTypes.func,
};

const defaultProps = {
  disabled: false,
  light: false,
  fullWidth: false,
};

function PrimaryButtonComponent({ disabled, light, fullWidth, padding, styles, onClick, children, ...props }) {
  return (
    <Root
      data-test='primary-button-component'
      disabled={disabled}
      padding={padding}
      light={light}
      fullWidth={fullWidth}
      styles={styles}
      onClick={onClick}
      {...props}
    >
      {children}
    </Root>
  );
}

PrimaryButtonComponent.propTypes = propTypes;

PrimaryButtonComponent.defaultProps = defaultProps;

export default PrimaryButtonComponent;

export const Root = styled.button`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  padding: ${({ padding }) => (padding ? padding : '12px 16px')};
  background-color: ${({ theme, disabled, light }) =>
    !disabled ? (light ? theme.colors.background.light : theme.colors.adjacent.default) : theme.colors.text.disabled};
  border: ${({ theme, light }) => (light ? `1px solid ${theme.colors.inputs.border.default}` : '0')};
  border-radius: 10px;
  font-size: 100%;
  font-weight: 700;
  color: ${({ theme, light }) => (light ? theme.colors.text.light_v2 : theme.colors.buttons.primary.text)};
  cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'not-allowed')};
  ${({ styles }) => styles};
`;
