export function getCO2CompensationIsIncluded(co2Compensations) {
  if (!co2Compensations) {
    return null;
  }

  return co2Compensations.map((item) => item.isIncluded).some((item) => item === true);
}

export function getCO2CompensationPrice(co2Compensations) {
  if (!co2Compensations) {
    return 0;
  }

  const notIncludedCO2InPackage = co2Compensations.map((item) => item.isIncluded).some((item) => item === false);

  if (notIncludedCO2InPackage) return co2Compensations.map((item) => item.amount).reduce((a, b) => a + b, 0);

  return 0;
}
