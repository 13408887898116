import * as types from './types';

const initialState = {
  driver: null,
};

function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.SET_DRIVER:
      return setDriver(state, payload);

    case types.RESET_STATE:
      return initialState;

    default:
      return state;
  }
}

export default reducer;

function setDriver(state, { driverDetails }) {
  return {
    ...state,
    driver: driverDetails,
  };
}
