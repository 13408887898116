import * as types from './types';

export function setPriceBarsResult(result, minMax) {
  return {
    type: types.SET_PRICE_BARS_RESULT,
    payload: {
      result,
      minMax,
    },
  };
}

export function updatePriceBarsResult(result, isBarsPast, updatedMinMax) {
  return {
    type: types.UPDATE_PRICE_BARS_RESULT,
    payload: {
      result,
      isBarsPast,
      updatedMinMax,
    },
  };
}

export function updatePriceBarsVisibility(rollUp, rollDown, windowCurrentScrollYPosition) {
  return {
    type: types.UPDATE_PRICE_BARS_VISIBILITY,
    payload: {
      rollUp,
      rollDown,
      windowCurrentScrollYPosition,
    },
  };
}
export function setDisplayPriceBars(isDisplayingBars) {
  return {
    type: types.SET_DISPLAY_PRICE_BARS,
    payload: {
      isDisplayingBars,
    },
  };
}

export function setFetchingPriceBars(status) {
  return {
    type: types.SET_FETCHING_PRICE_BARS,
    payload: { status },
  };
}
