import * as types from './types';

export function setState(state) {
  return {
    type: types.SET_STATE,
    payload: {
      state,
    },
  };
}

export function setFlag(flag, value) {
  return {
    type: types.SET_FLAG,
    payload: {
      flag,
      value,
    },
  };
}

export function resetState() {
  return {
    type: types.RESET_STATE,
  };
}
