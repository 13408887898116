import * as types from './types';

export const setDestinations = (destinations) => ({
  type: types.SET_DESTINATIONS,
  payload: {
    destinations,
  },
});
export function setFetching(fetching) {
  return {
    type: types.SET_FETCHING,
    payload: {
      fetching,
    },
  };
}
