import _forEach from 'lodash/forEach';

/**
 * Returns countries which are valid destinations based on the selected origins.
 * @param {string[]} origins Array of origins codes.
 * @param {object} destinationToCountryMap Result from 'function mapDestinationsToCountries(countries)'.
 * @returns {string[]} Array of country codes.
 */
export default function getAvailableCountries(origins, destinationToCountryMap) {
  let result = [];

  _forEach(origins, (origin) => {
    const country = destinationToCountryMap[origin];

    // Prevent duplicates
    if (country && result.indexOf(country) === -1) {
      result.push(country);
    }
  });

  return result;
}
