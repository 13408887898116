import Router from 'next/router';
import { getAffiliateByShortCode } from 'httpClient/requests/affiliate';
import { FRONTPAGE as FRONTPAGE_ROUTE } from 'constants/routesKeys';
import { FRONTPAGE as FRONTPAGE_PAGE } from 'constants/pages';
import { TRIPX_AFFILIATE as TRIPX_AFFILIATE_COOKIE, AGENT_ID as AGENT_ID_COOKIE } from 'constants/cookiesKeys';
import setCookie from 'utils/cookie/setCookie';
import setCookieProxy from 'utils/cookie/setCookieProxy';
import deleteCookie from 'utils/cookie/deleteCookie';
import { routesOperations } from 'state/routes';
import { requestAffiliate, setAffiliate, setAgentId, resetAgentId } from './actions';

export function agentLogin(agentId) {
  return (dispatch, getState) => {
    const affiliateId = getState().agent.affiliate.id;

    if (affiliateId === 0) {
      return;
    }

    dispatch(setCookieProxy(TRIPX_AFFILIATE_COOKIE, affiliateId, 30));
    setCookie(AGENT_ID_COOKIE, agentId, 30);
    dispatch(setAgentId(agentId));

    const pathToSearchPage = dispatch(routesOperations.getRoutePathByRouteKey(FRONTPAGE_ROUTE));
    Router.push(FRONTPAGE_PAGE, pathToSearchPage);
  };
}

export function agentLogout() {
  return (dispatch) => {
    deleteCookie(AGENT_ID_COOKIE);
    dispatch(setCookieProxy(TRIPX_AFFILIATE_COOKIE, 'tripx', 30));
    dispatch(resetAgentId());
  };
}

export function fetchAffiliate(shortCode) {
  return (dispatch, getState) => {
    const { fetching } = getState().agent;

    if (fetching) {
      return;
    }

    dispatch(requestAffiliate(true));

    getAffiliateByShortCode(shortCode)
      .then(({ data }) => {
        dispatch(setAffiliate(data));
      })
      .catch((error) => {
        console.error(error);
        dispatch(requestAffiliate(false));
      });
  };
}
