import React, { Component } from 'react';
import { connect } from 'react-redux';
import withAnimatedScrollTo from 'components/hocs/withAnimatedScrollTo';
import SelectComponent from './SelectComponent';

class Select extends Component {
  constructor(props) {
    super(props);

    this.state = {
      focused: false,
      validationVisible: props.validateOn === undefined,
    };

    this.handleOnFocus = this.handleOnFocus.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);
  }

  componentDidUpdate() {
    const { validity, scrollTo, scrollToComponent } = this.props;

    if (scrollTo && validity && validity.valid === false) {
      scrollToComponent('multiple');
    }
  }

  handleOnFocus(event) {
    const { validateOn, onFocus } = this.props;
    const nextState = { focused: true };

    if (validateOn === 'blur') {
      nextState.validationVisible = false;
    }

    this.setState(nextState);
    onFocus && onFocus(event);
  }

  handleOnBlur(event) {
    const { validateOn, onBlur } = this.props;
    const nextState = { focused: false };

    if (validateOn === 'blur') {
      nextState.validationVisible = true;
    }

    this.setState(nextState);
    onBlur && onBlur(event);
  }

  render() {
    const { componentsRefs } = this.props;

    return (
      <SelectComponent
        {...this.props}
        {...this.state}
        onFocus={this.handleOnFocus}
        componentsRefs={componentsRefs}
        onBlur={this.handleOnBlur}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    colors: state.theme.colors,
  };
}

export default connect(mapStateToProps)(withAnimatedScrollTo()(Select));
