import { HOTEL_ONLY } from 'constants/selectValues';

export function getDestinationFullName(hotelsInfo) {
  const categories = hotelsInfo.map(({ location }) => {
    const { country, destination } = location;
    return `${country}/${destination.name}`;
  });

  return [...new Set(categories)].toString(); // remove duplicates
}

//Retrieves the origin code or hotel only 'na' value
export function getOriginCode(hotelPagePackageInfo) {
  return hotelPagePackageInfo.current ? hotelPagePackageInfo.current.search.origin : HOTEL_ONLY;
}

//Retrieves the destination code of the hotel
export function getDestinationCode(hotelsInfo) {
  return hotelsInfo[0].location.destination.code;
}
