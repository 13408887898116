const weightAge = [1, 2, 1, 2, 1, 2, 1, 2, 1, 2];
const constant = 9;
const moduloNumber = 10;

function isValidRewardNumber(rewardNumber = '') {
  if (!rewardNumber) {
    return {
      valid: true,
      message: null,
    };
  }

  if (rewardNumber && rewardNumber.length !== 10) {
    return {
      valid: false,
      message: {
        id: 'rewardNumber.invalid',
        description: 'Your reward number is not valid',
        defaultMessage: 'Your reward number is not valid',
      },
    };
  }

  const rewardNumberConverted = `0${rewardNumber.slice(0, -1)}`;
  const resultStep3 = [...rewardNumberConverted]
    .map((item, index) => weightAge[index] * item)
    .reduce((prev, curr) => prev + curr, 0);
  const resultStep4 = resultStep3 + constant;
  const checkDigit = resultStep4 - (resultStep4 % moduloNumber) - resultStep3;

  if (checkDigit !== parseInt(rewardNumber[rewardNumber.length - 1])) {
    return {
      valid: false,
      message: {
        id: 'rewardNumber.invalid',
        description: 'Your reward number is not valid',
        defaultMessage: 'Your reward number is not valid',
      },
    };
  }

  return {
    valid: true,
    message: null,
  };
}

export default isValidRewardNumber;
