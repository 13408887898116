import _groupBy from 'lodash/groupBy';
import moment from 'moment';
import capitalize from 'utils/string/capitalize';

export default function groupByMonth(response) {
  const result = [];
  const grouped = _groupBy(response, groupByFunction);

  for (var key in grouped) {
    if (grouped.hasOwnProperty(key)) {
      result.push({
        key,
        tag: key.split('-')[1],
        items: grouped[key],
      });
    }
  }

  return result;
}

function groupByFunction(priceBar) {
  const momentDate = moment(priceBar.date);
  return `${momentDate.year()}-${capitalize(priceBar.month)}`;
}
