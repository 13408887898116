function calculateRewardNumberCheckDigit(val) {
  const checksum = [];
  let odd = false;
  let increment = 0;
  let i;

  for (i = 0; i < val.length; i++) {
    var t = val.substring(i, i + 1);
    if (odd) {
      odd = false;
      var intCheck = parseInt(t);
      var oddCheck = (intCheck * 2).toString();
      for (var j = 0; j < oddCheck.length; j++) {
        checksum[increment++] = oddCheck.substring(j, j + 1);
      }
    } else {
      odd = true;
      checksum[increment++] = t;
    }
  }

  var sumInput = 0;
  var sumChecksum = 0;

  for (i = 0; i < val.length; i++) {
    var t2 = val.substring(i, i + 1);
    var currentInt = parseInt(t2);
    sumInput = sumInput + currentInt;
  }

  for (i = 0; i < checksum.length; i++) {
    if (parseInt(checksum[i])) {
      sumChecksum = sumChecksum + parseInt(checksum[i]);
    }
  }

  var topRes = sumChecksum + 10;
  var divRes = Math.floor(topRes / 10);
  var sumRes = divRes * 10;
  var calcCheckSum = sumRes - sumChecksum;

  // if the highest number 10 is 10 then we force it to 0 to avoid number
  // overflow
  if (calcCheckSum === 10) {
    calcCheckSum = 0;
  }

  return calcCheckSum.toString();
}

function isValidRewardNumber(rewardNumber) {
  if (!rewardNumber) {
    return {
      valid: true,
      message: null,
    };
  }

  const isDigitRegExp = new RegExp('^\\d{8}(\\d{2})?$');

  if (isDigitRegExp.test(rewardNumber)) {
    const len = rewardNumber.length;
    const rewardNumberWithoutCheckDigit = rewardNumber.substring(0, len - 1);
    const checkDigit = rewardNumber.substring(len - 1, len);
    const calculatedCheckDigit = calculateRewardNumberCheckDigit(rewardNumberWithoutCheckDigit);
    const valid = checkDigit === calculatedCheckDigit;
    return {
      valid,
      message: !valid
        ? {
            id: 'rewardNumber.invalid',
            description: 'Your reward number is not valid',
            defaultMessage: 'Your reward number is not valid',
          }
        : null,
    };
  }

  return {
    valid: false,
    message: {
      id: 'rewardNumber.invalid',
      description: 'Your reward number is not valid',
      defaultMessage: 'Your reward number is not valid',
    },
  };
}

export default isValidRewardNumber;
