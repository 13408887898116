import { HOTEL_ONLY } from 'constants/selectValues';
import SearchQueryParser from 'state/_utils/SearchQueryParser';
import searchQueryLS from 'managers/localStorage/searchQuery/searchQueryLS';
import searchPropertiesKeys from 'state/_constants/searchPropertiesKeys';
import { routesOperations } from 'state/routes';
import { setHotelOnly } from 'state/selectDestinations/actions';
import { setSelectedOrigins, setForceSingleValue } from '../actions';
import formatOriginsForReactSelect from '../selectors/formatOriginsForReactSelect';
import searchQueryAvailable from 'utils/SearchQueryAvailable';
import { SEARCH } from 'constants/routesKeys';

export default function hydrateSelectedOrigins() {
  return (dispatch, getState) => {
    let storedQuery = null;

    if (searchQueryAvailable(dispatch(routesOperations.getRoutePathByRouteKey(SEARCH)))) {
      storedQuery = SearchQueryParser.getSelectedOrigins();
    } else {
      storedQuery = searchQueryLS.get();
    }

    const { origins } = getState().selectOrigins;

    // if there is only one origins, it should be selected by default
    if (origins && origins.length === 1) {
      dispatch(setForceSingleValue(true));

      if (!storedQuery) {
        storedQuery = {};
      }

      storedQuery[searchPropertiesKeys.SELECTED_ORIGINS_KEY] = [origins[0].code];
    }

    if (storedQuery !== null) {
      if (
        storedQuery[searchPropertiesKeys.SELECTED_ORIGINS_KEY] &&
        storedQuery[searchPropertiesKeys.SELECTED_ORIGINS_KEY].length > 0
      ) {
        let selectedOrigins = [];
        const hotelOnly = storedQuery[searchPropertiesKeys.SELECTED_ORIGINS_KEY].indexOf(HOTEL_ONLY) !== -1;

        if (!hotelOnly) {
          selectedOrigins = origins.filter((origin) => {
            return storedQuery[searchPropertiesKeys.SELECTED_ORIGINS_KEY].indexOf(origin.code) !== -1;
          });
        } else {
          dispatch(setHotelOnly(true));
        }

        // @TODO Should be refactored with configuration.
        const { locale, airlineWhitelabel } = getState().settings.value;
        const removeGot = airlineWhitelabel && airlineWhitelabel.isNorwegian && locale.language === 'no';

        const selectedOriginsFormatted = formatOriginsForReactSelect(
          { origins: selectedOrigins },
          hotelOnly,
          true,
          removeGot
        );
        dispatch(setSelectedOrigins(selectedOriginsFormatted));
      }
    }
  };
}
