import * as types from './types';

const initialState = {
  fetching: null,
  value: null,
};

function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.SET_FETCHING:
      return setFetching(state, payload);

    case types.SET_DESTINATIONS:
      return setDestinations(state, payload);

    case types.RESET_STATE:
      return initialState;

    default:
      return state;
  }
}

export default reducer;

function setFetching(state, { fetching }) {
  return {
    ...state,
    fetching,
  };
}

function setDestinations(state, { destinations }) {
  return {
    ...state,
    value: destinations,
  };
}
