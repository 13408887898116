import queryString from 'query-string';
import getCookie from 'utils/cookie/getCookie';
import { TRIPX_AFFILIATE } from 'constants/cookiesKeys';
import roomConfigurationsParser from 'state/_utils/roomConfigurationsParser';

export default function buildSearchResultsRequestParamsFromQuery() {
  // TODO: Transfer logic to SearchQueryParser
  const queryParams = queryString.parse(window.location.search);

  const searchRequestParams = {};

  if (queryParams.origins !== undefined) {
    searchRequestParams.origins = queryParams.origins.split(',').map((origin) => origin.substring(0, 3));
  } else if (queryParams.airport !== undefined) {
    searchRequestParams.origins = queryParams.airport.split(',').map((origin) => origin.substring(0, 3));
  }

  if (queryParams.destinations !== undefined) {
    searchRequestParams.destinations = queryParams.destinations.split(',');
  } else if (queryParams.destination !== undefined) {
    searchRequestParams.destinations = queryParams.destination.split(',');
  }

  searchRequestParams.dates = {};
  if (queryParams.from !== undefined || queryParams.to !== undefined || queryParams.flexibleDates !== undefined) {
    if (queryParams.from !== undefined) {
      searchRequestParams.dates.from = queryParams.from;
    }

    if (queryParams.to !== undefined) {
      searchRequestParams.dates.to = queryParams.to;
    }

    if (queryParams.flexibleDates !== undefined) {
      searchRequestParams.dates.flexibleDates = queryParams.flexibleDates === 'true';
    }
  }
  if (
    queryParams.offerFrom !== undefined &&
    queryParams.offerTo !== undefined &&
    queryParams.numberOfDays !== undefined
  ) {
    searchRequestParams.dates.offerFrom = queryParams.offerFrom;
    searchRequestParams.dates.offerTo = queryParams.offerTo;
  }

  searchRequestParams.roomConfigurations = [];
  let rooms = [];
  if (queryParams.rooms !== undefined) {
    rooms = queryParams.rooms.split(',');
  } else if (queryParams.pax !== undefined) {
    rooms = queryParams.pax.split('|');
  } else {
    searchRequestParams.roomConfigurations.push({
      numberOfAdults: 2,
      numberOfChildren: 0,
      childrenAges: [],
    });
  }

  if (rooms.length > 0) {
    searchRequestParams.roomConfigurations = roomConfigurationsParser(rooms);
  }

  if (queryParams.numberOfDays !== undefined) {
    //set number of days only if there is no from and to query params.
    if (!queryParams.from && !queryParams.to) {
      searchRequestParams.numberOfDays = queryParams.numberOfDays.split(',').map((item) => parseInt(item));
    }
  }

  if (queryParams.score !== undefined) {
    searchRequestParams.hotelScore = parseFloat(queryParams.score);
  }

  if (queryParams.stars !== undefined) {
    const stars = queryParams.stars.split(',');
    const starsListOfNum = stars.map((star) => parseFloat(star));
    searchRequestParams.hotelStars = starsListOfNum;
  }

  if (queryParams.price !== undefined) {
    searchRequestParams.hotelPriceRange = queryParams.price.split(',').map((item) => parseInt(item));
  }

  if (queryParams.board !== undefined) {
    searchRequestParams.boardType = queryParams.board;
  }

  if (queryParams.themes !== undefined) {
    searchRequestParams.hotelThemes = queryParams.themes.split(',').map((item) => parseInt(item));
  }

  if (queryParams.facilities !== undefined) {
    searchRequestParams.hotelFacilities = queryParams.facilities.split(',').map((item) => parseInt(item));
  }

  if (queryParams.cities !== undefined) {
    searchRequestParams.cities = queryParams.cities.split(',');
  }

  if (
    queryParams.hotel !== undefined ||
    queryParams.packageRoomId !== undefined ||
    queryParams.packageBoardCode !== undefined ||
    queryParams.packagePrice !== undefined ||
    queryParams.hotelName !== undefined
  ) {
    searchRequestParams.packageInfo = {};

    if (queryParams.hotel !== undefined) {
      searchRequestParams.packageInfo.hotelId = queryParams.hotel;
    }

    if (queryParams.packageRoomId !== undefined) {
      searchRequestParams.packageInfo.roomId = queryParams.packageRoomId;
    }

    if (queryParams.packageBoardCode !== undefined) {
      searchRequestParams.packageInfo.boardCode = queryParams.packageBoardCode;
    }

    if (queryParams.packagePrice !== undefined) {
      searchRequestParams.packageInfo.price = window.atob(queryParams.packagePrice.replace(/-/g, ''));
    }
    if (queryParams.hotelName !== undefined) {
      searchRequestParams.packageInfo.hotelSlug = queryParams.hotelName;
    }
  }

  const tripxAffiliateCookie = getCookie(TRIPX_AFFILIATE);

  searchRequestParams.affiliateId = tripxAffiliateCookie;

  return searchRequestParams;
}
