import getAllProductsForGTM from './utils/getAllProductsForGTM';
import trackEecEvent from '../utils/trackEecEvent';
import { getAbandonedCartForGTM } from './utils/getAbandonedCartForGTM';

export default function checkout(eventName, eventData, state, dispatch) {
  const { step } = eventData;
  const products = getAllProductsForGTM();
  const abandonedCart = step === 5 && getAbandonedCartForGTM(state, dispatch);

  const _eventData = {
    ecommerce: {
      checkout: {
        actionField: { step },
        products,
      },
    },
    abandonedCart,
  };

  trackEecEvent(eventName, _eventData);
}
