import localStorageBase from '../localStorageBase';

const savedPackagesLocalStorage = (function (ls) {
  const key = 'saved_packages';

  function getSavedPackages() {
    return ls.get(key);
  }

  function updateSavedPackages(updatedData) {
    ls.set(key, updatedData);
  }

  return {
    get: getSavedPackages,
    set: updateSavedPackages,
  };
})(localStorageBase);

export default savedPackagesLocalStorage;
