import { TRIPX_AFFILIATE as TRIPX_AFFILIATE_COOKIE } from 'constants/cookiesKeys';
import calculateTotalPrice from 'state/checkoutPage/travelSummary/selectors/calculateTotalPrice';
import getCookie from 'utils/cookie/getCookie';
import getProductId from './utils/getProductId';
import getProductName from './utils/getProductName';
import { getDestinationFullName, getOriginCode, getDestinationCode } from './utils/getProductCategory';
import trackEecEvent from '../utils/trackEecEvent';

export default function addToCart(eventName, state) {
  const { settings, hotelPage, deal, checkoutPagePromotionCode, hotelPagePackageInfo } = state;
  const { totalPrice } = calculateTotalPrice();

  const _eventData = {
    ga4Ecommerce: {
      ecommerce: {
        currency: settings.value.locale.currency,
        value: totalPrice,
        items: [
          {
            item_id: getProductId(hotelPage.hotelsInfo, deal),
            item_name: getProductName(hotelPage.hotelsInfo, deal),
            affiliation: getCookie(TRIPX_AFFILIATE_COOKIE),
            coupon: checkoutPagePromotionCode.promotionCode,
            index: 0,
            item_category: getDestinationFullName(hotelPage.hotelsInfo),
            item_category2: getOriginCode(hotelPagePackageInfo),
            item_category3: getDestinationCode(hotelPage.hotelsInfo),
            price: totalPrice,
            quantity: 1,
          },
        ],
      },
    },
  };

  trackEecEvent(eventName, _eventData);
}
