function checkForConsecutiveSpaces(str) {
  return /\s{2}/.test(str); //RETURNS TRUE IF THERE IS CONSECUTIVE SPACES
}

export default function isStringWithoutNumbersOrSpecialCharacters(value) {
  if (checkForConsecutiveSpaces(value)) {
    return false;
  }

  return value.match(/[\d~!?:"|'\\`@#$%^&_.,;+=*/()[\]{}]+/) === null;
}
