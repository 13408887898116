import HttpClient from 'httpClient';
import { urlBuilder } from 'httpClient/aws/getAwsEndpoint';
import setTripxCacheKey from 'httpClient/aws/headers/setTripxCacheKey';

const methodPriceBars = '/price-bars';

export function getPriceBars(queryDate, params, priceBarsRequestData) {
  const query = `date=${queryDate}&barsPast=${priceBarsRequestData.barsPast}&barsFuture=${priceBarsRequestData.barsFuture}`;
  const config = { headers: setTripxCacheKey(params) };

  const url = urlBuilder({ method: methodPriceBars, queryParam: query });

  return HttpClient.post(url, params, config);
}

export function getPriceBarsPaggination(queryDate, params, data) {
  const config = { headers: setTripxCacheKey({ ...queryDate, ...params }) };

  let query = `date=${queryDate}`;

  if (data.isBarsPast) {
    query += `&barsPast=30&barsFuture=0`;
  } else {
    query += `&barsPast=0&barsFuture=30`;
  }

  const url = urlBuilder({ method: methodPriceBars, queryParam: query });

  return HttpClient.post(url, params, config);
}
