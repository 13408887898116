import trackEvent from '../utils/trackEvent';
import cioIdentify from './cioIdentify';
import { CIO_IDENTIFY } from '../constants/eventsNames';
import { getAbandonedCartForGTM } from './utils/getAbandonedCartForGTM';

export default function abandonedCart(eventName, state, dispatch) {
  if (state.settings.value.customSettings && state.settings.value.customSettings.enableAbandonedCart) {
    const _eventData = {
      abandonedCart: getAbandonedCartForGTM(state, dispatch),
    };

    cioIdentify(CIO_IDENTIFY, state);
    trackEvent(eventName, _eventData);
  }
}
