import getCookie from 'utils/cookie/getCookie';
import { TRIPX_AFFILIATE } from 'constants/cookiesKeys';
import getSelectedDestinationsValues from 'state/selectDestinations/selectors/getSelectedDestinationsValues';
import { getNumberOfDaysForSearchRequestParameters } from './getNumberOfDaysForSearchRequestParameters';
import { getDatesForSearchRequestParameters } from './getDatesForSearchRequestParameters';

export default function buildSearchResultsRequestParams(
  origins,
  destinations,
  dates,
  roomConfigurations,
  searchResultsFilters,
  packageInfo,
  ...rest
) {
  // When we want all board types, we don't send any value
  let boardType = null;
  if (searchResultsFilters !== null && searchResultsFilters.boardType && searchResultsFilters.boardType.current) {
    boardType = searchResultsFilters.boardType.current;
  }
  // Send number of days only if the user has changed it or if we use period calendar
  const numberOfDays = getNumberOfDaysForSearchRequestParameters(searchResultsFilters, ...rest);

  // Send hotel price range only if the user has changed it
  let hotelPriceRange = null;
  if (
    searchResultsFilters !== null &&
    searchResultsFilters.priceRange &&
    (searchResultsFilters.priceRange.value[0] !== searchResultsFilters.priceRange.min ||
      searchResultsFilters.priceRange.value[1] !== searchResultsFilters.priceRange.max)
  ) {
    hotelPriceRange = searchResultsFilters.priceRange.value;
  }

  // Send hotel score only if the user has changed it
  let hotelScore = null;
  if (searchResultsFilters !== null && searchResultsFilters.hotelScore && !searchResultsFilters.hotelScore.reset) {
    const currFloor = Math.floor((searchResultsFilters.hotelScore.current / 10) * 2) / 2;
    const minPossibleFloor = Math.floor((searchResultsFilters.hotelScore.possible[0] / 10) * 2) / 2;
    if (currFloor !== minPossibleFloor) {
      hotelScore = searchResultsFilters.hotelScore.current;
    }
  }

  let hotelStars = null;
  if (
    searchResultsFilters !== null &&
    searchResultsFilters.hotelStars &&
    searchResultsFilters.hotelStars.current.length > 0
  ) {
    hotelStars = searchResultsFilters.hotelStars.current;
  }

  let hotelThemes = null;
  if (
    searchResultsFilters !== null &&
    searchResultsFilters.hotelThemes &&
    searchResultsFilters.hotelThemes.current.length > 0
  ) {
    hotelThemes = searchResultsFilters.hotelThemes.current;
  }

  let hotelFacilities = null;
  if (
    searchResultsFilters !== null &&
    searchResultsFilters.hotelFacilities &&
    searchResultsFilters.hotelFacilities.current.length > 0
  ) {
    hotelFacilities = searchResultsFilters.hotelFacilities.current;
  }

  let hotelCities = null;
  if (
    searchResultsFilters !== null &&
    searchResultsFilters.hotelCities &&
    searchResultsFilters.hotelCities.current.length > 0
  ) {
    hotelCities = searchResultsFilters.hotelCities.current;
  }

  const affiliateId = getCookie(TRIPX_AFFILIATE);

  return {
    origins: origins !== null ? origins.map((origin) => origin.value) : null,
    destinations: getSelectedDestinationsValues(destinations),
    dates: getDatesForSearchRequestParameters(dates, ...rest),
    roomConfigurations,
    numberOfDays,
    hotelScore,
    hotelStars,
    hotelPriceRange,
    boardType,
    hotelThemes,
    hotelFacilities,
    cities: hotelCities,
    packageInfo,
    affiliateId,
  };
}
