import calculateTotalPrice from 'state/checkoutPage/travelSummary/selectors/calculateTotalPrice';
import transformSelectRoomsConfigurations from 'state/selectRooms/transforms/transformSelectRoomsConfigurations';
import getProductId from './utils/getProductId';
import trackEvent from '../utils/trackEvent';

function getBookingCommissions(bookingCreateValue) {
  if (bookingCreateValue === null) {
    return null;
  }

  if (bookingCreateValue.commissions && bookingCreateValue.commissions.length > 0) {
    const commissions = bookingCreateValue.commissions.map(({ commission, currency, currecny, supplier }) => {
      return {
        a: commission,
        c: currency || currecny,
        s: supplier,
      };
    });

    if (commissions) {
      return commissions;
    }
  }

  return null;
}

export default function fbPurchase(eventName, eventData, state) {
  const { settings, hotelPage, hotelPagePackageInfo, deal, checkoutPageBooking, checkoutPagePromotionCode } = state;
  const hotelInfo = hotelPage.hotelsInfo[0];
  const { current } = hotelPagePackageInfo;
  const { value: bookingCreateValue } = checkoutPageBooking.bookingCreate;
  const c = getBookingCommissions(bookingCreateValue);
  const { numberOfAdults, numberOfChildren, numberOfInfants } = transformSelectRoomsConfigurations(
    current.search.roomConfigurations
  );
  const { totalPrice } = calculateTotalPrice();

  const _eventData = {
    // FB params below
    content_type: 'hotel',
    checkin_date: current.search.dates.from,
    checkout_date: current.search.dates.to,
    content_ids: getProductId(hotelPage.hotelsInfo, deal),
    city: hotelInfo.location.city,
    region: hotelInfo.location.city,
    country: hotelInfo.location.country,
    num_adults: numberOfAdults,
    num_children: numberOfChildren + numberOfInfants,
    value: totalPrice,
    currency: settings.value.locale.currency,
    transaction_id: eventData.bookingCode,
    promoCode: checkoutPagePromotionCode.promotionCode,
    promoCodeValue: checkoutPagePromotionCode.promotionCode,
    c,
  };

  trackEvent(eventName, _eventData);
}
