import { getSearchResults } from 'httpClient/requests/searchResults';
import { HOTEL_ONLY } from 'constants/selectValues';
import {
  SEARCH_RESULTS as TRACKING_EVENT_SEARCH_RESULTS,
  SEARCH_RESULTS_LOADED as TRACKING_EVENT_SEARCH_RESULTS_LOADED,
  SEARCH_RESULTS_NO_RESULTS as TRACKING_EVENT_SEARCH_RESULTS_NO_RESULTS,
} from 'managers/gtmEventTracking/constants/eventsNames';
import executeTrackingEvent from 'managers/gtmEventTracking/executeTrackingEvent';
import { setFiltersData } from 'state/searchResultsFilters/actions';
import { formatFilterDataFromResponse } from 'state/searchResultsFilters/operations/formatFilterDataFromResponse';
import {
  setSearchResults,
  setIsFetchingSearchResults,
  setIsFilteringSearchResults,
  evalShowLoader,
  setCanHideLoader,
  setShowNoResultsFoundPage,
} from '../actions';
import createHotelsMap from '../selectors/createHotelsMap';
import { setCalendarDates } from 'state/selectDates/actions';
import { setUserInformed } from 'state/flexibleDatesInformer/actions';
import convertPackageSearchWorld from './convertPackageSearchWorld';
import convertFilterDataWorld from './convertFilterDataWorld';
import { getSearchResultsWorldHotel } from 'httpClient/requests/searchResults';
import transformResponseIDsToString from '../transforms/transformResponseIDsToString';
import { SPECIFIC_DATES_CALENDAR } from 'components/inputs/SelectDates/constants/calendarSwitch';

export default function fetchSearchResults(params, isFiltering) {
  return (dispatch, getState) => {
    const { worldSearch } = getState().worldSearch;
    const { calendarOption } = getState().selectDates;

    if (isFiltering) {
      dispatch(setIsFilteringSearchResults(true));
    } else {
      dispatch(setIsFetchingSearchResults(true));
    }
    //WORLD SEARCH IMPLEMENTATION
    //@TODO: REFACTOR THIS
    getSearchResults(params, worldSearch)
      .then(async (response) => {
        if (worldSearch) {
          const { results, hotelCodes } = convertPackageSearchWorld(response, params);
          response.data.results = results;
          response.data.filterData = convertFilterDataWorld();

          response.data.hotels = await getSearchResultsWorldHotel(hotelCodes).then((response) =>
            response.data.map((hotelData) => {
              const body = {
                score: Math.floor(75 + Math.random() * (99 - 75)),
                reviewsCount: Math.floor(1000 + Math.random() * (1500 - 1000)),
                slug: `/destination/world/city/${hotelData.id}`,
                worldSearch: true,
              };
              return { ...hotelData, ...body };
            })
          );
        }
        //retry logic is here
        if (
          response.data.results &&
          response.data.results.length === 0 &&
          !params.dates.flexibleDates &&
          calendarOption === SPECIFIC_DATES_CALENDAR
        ) {
          params.dates.flexibleDates = true;
          dispatch(setCalendarDates(params.dates));
          dispatch(setUserInformed(true));
          dispatch(fetchSearchResults(params, isFiltering));
        } else {
          response.data = transformResponseIDsToString(response.data);
          const showInfoMessage =
            checkIsPackageSearch(params) &&
            !checkIfPackagePricesApplyForSelectedRoomConfiguration(params.roomConfigurations);

          const sortBy = getState().searchResultsFilters.sortBy;

          if (Object.keys(response.data.filterData).length !== 0 && !isFiltering) {
            const filterData = formatFilterDataFromResponse(response.data.filterData);
            dispatch(setFiltersData(filterData));
          }

          const hotelsMap = createHotelsMap(response.data.results, response.data.hotels);

          dispatch(setSearchResults(response.data.results, hotelsMap, showInfoMessage, sortBy.value));

          if (response.data.results && response.data.results.length > 0) {
            dispatch(executeTrackingEvent(TRACKING_EVENT_SEARCH_RESULTS, params));
            dispatch(executeTrackingEvent(TRACKING_EVENT_SEARCH_RESULTS_LOADED, { params, response }));
          } else {
            dispatch(executeTrackingEvent(TRACKING_EVENT_SEARCH_RESULTS_NO_RESULTS));
          }
        }
      })
      .catch((error) => {
        if (isFiltering) {
          dispatch(setIsFilteringSearchResults(false));
        } else {
          dispatch(setIsFetchingSearchResults(false));
        }

        dispatch(evalShowLoader());
        dispatch(setShowNoResultsFoundPage(true));

        console.error(error);
      });

    if (isFiltering) {
      // Display the loader immediately for filtering
      dispatch(evalShowLoader());

      setTimeout(function () {
        dispatch(setCanHideLoader(true));
      }, 2000);
    } else {
      // Display the loader if no response was received in 1s for searching
      setTimeout(function () {
        dispatch(evalShowLoader());

        // Always display the loader for min 3s
        setTimeout(function () {
          dispatch(setCanHideLoader(true));
        }, 2000);
      }, 1000);
    }
  };
}

function checkIsPackageSearch(params) {
  const { origins, destinations, dates } = params;
  const { from, to, flexibleDates } = dates;
  const hotelOnly = origins && origins.indexOf(HOTEL_ONLY) !== -1;

  if (
    hotelOnly ||
    (origins && origins.length === 1 && destinations && destinations.length === 1 && from && to && !flexibleDates)
  ) {
    return false;
  }

  return true;
}

function checkIfRoomConfigurationApplies(adt, chd) {
  const acceptableCases = [
    { numberOfAdults: 1, numberOfChildren: 1 },
    { numberOfAdults: 2, numberOfChildren: 2 },
    { numberOfAdults: 2, numberOfChildren: 0 },
    { numberOfAdults: 2, numberOfChildren: 1 },
    { numberOfAdults: 3, numberOfChildren: 0 },
  ];

  return acceptableCases.find(
    ({ numberOfAdults, numberOfChildren }) => numberOfAdults === adt && numberOfChildren === chd
  )
    ? true
    : false;
}

function checkIfPackagePricesApplyForSelectedRoomConfiguration(roomConfigurations) {
  let priceBasedRooms = true;

  if (roomConfigurations && roomConfigurations.length > 0) {
    for (let i = 0; i < roomConfigurations.length; i++) {
      const roomConfigurationApplies = checkIfRoomConfigurationApplies(
        roomConfigurations[i].numberOfAdults,
        roomConfigurations[i].numberOfChildren
      );

      if (!roomConfigurationApplies) {
        priceBasedRooms = false;
        break;
      }
    }
  }

  return priceBasedRooms;
}
