export const SET_IS_FETCHING_SEARCH_RESULTS = 'searchResults/SET_IS_FETCHING_SEARCH_RESULTS';
export const SET_IS_FILTERING_SEARCH_RESULTS = 'searchResults/SET_IS_FILTERING_SEARCH_RESULTS';
export const EVAL_SHOW_LOADER = 'searchResults/EVAL_SHOW_LOADER';
export const SET_CAN_HIDE_LOADER = 'searchResults/SET_CAN_HIDE_LOADER';
export const FETCH_SEARCH_RESULTS = 'searchResults/FETCH_SEARCH_RESULTS';
export const FETCH_SEARCH_RESULTS_FROM_QUERY = 'searchResults/FETCH_SEARCH_RESULTS_FROM_QUERY';
export const FILTER_SEARCH_RESULTS = 'searchResults/FILTER_SEARCH_RESULTS';
export const SET_SEARCH_RESULTS = 'searchResults/SET_SEARCH_RESULTS';
export const UPDATE_HOTELS_INFO = 'searchResults/UPDATE_HOTELS_INFO';
export const SCROLL_PACKAGE_INTO_VIEW = 'searchResults/SCROLL_PACKAGE_INTO_VIEW';
export const SORT_BY_SEARCH_RESULTS = 'searchResults/SORT_BY_SEARCH_RESULTS';
export const FILTER_BY_SEARCH_RESULTS = 'searchResults/FILTER_BY_SEARCH_RESULTS';
export const SET_SHOW_NO_RESULTS_FOUND_PAGE = 'searchResults/SET_SHOW_NO_RESULTS_FOUND_PAGE';
