import copy from 'utils/immutable/copy';
import * as types from './types';

const initialState = {
  flights: null,
  filteredFlights: [],
  filteredFlightsTimeStamp: null,
  airportNamesMap: null,
  carrierNamesMap: null,
  attributes: {
    cheapestFlight: null,
    bestFlight: null,
  },
};

function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.SET_STATE:
      return payload.state;

    case types.SET_FLIGHTS_DATA:
      return setFlightsData(state, payload);

    case types.SET_FILTERED_FLIGHTS:
      return setFilteredFlights(state, payload);

    case types.SORT_FILTERED_FLIGHTS:
      return sortFilteredFlights(state, payload);

    case types.SET_CHEAPEST_FILTERED_FLIGHT:
      return setCheapestFilteredFlight(state, payload);

    case types.SET_BEST_FILTERED_FLIGHT:
      return setBestFilteredFlight(state, payload);

    case types.RESET_FLIGHTS:
      return initialState;

    default:
      return state;
  }
}

export default reducer;

function setFlightsData(state, { flights, airportNamesMap, carrierNamesMap }) {
  return {
    ...state,
    flights,
    filteredFlights: copy(flights),
    filteredFlightsTimeStamp: Date.now(),
    airportNamesMap,
    carrierNamesMap,
  };
}

function setFilteredFlights(state, { filteredFlights }) {
  return {
    ...state,
    filteredFlights,
    filteredFlightsTimeStamp: Date.now(),
  };
}

function sortFilteredFlights(state, { sortedFilteredFlights }) {
  return {
    ...state,
    filteredFlights: sortedFilteredFlights,
    filteredFlightsTimeStamp: Date.now(),
  };
}

function setCheapestFilteredFlight(state, { value }) {
  return {
    ...state,
    attributes: {
      cheapestFlight: value,
      bestFlight: state.attributes.bestFlight,
    },
  };
}

function setBestFilteredFlight(state, { value }) {
  return {
    ...state,
    attributes: {
      bestFlight: value,
      cheapestFlight: state.attributes.cheapestFlight,
    },
  };
}
