import * as types from './types';

const initialState = {
  paths: null,
  config: null,
  selectedCountry: null,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_PATHS:
      return setPaths(state, action.payload);

    case types.SET_CONFIG:
      return setConfig(state, action.payload);

    case types.SET_SELECTED_COUNTRY:
      return setSelectedCountry(state, action.payload);

    default:
      return state;
  }
}

export default reducer;

function setPaths(state, { paths }) {
  return {
    ...state,
    paths,
  };
}

function setConfig(state, { config }) {
  return {
    ...state,
    config,
  };
}

function setSelectedCountry(state, { selectedCountry }) {
  return {
    ...state,
    selectedCountry,
  };
}
