import * as types from './types';

export function requestPopularHotels() {
  return {
    type: types.REQUEST_POPULAR_HOTELS,
  };
}

export function setPopularHotels(value) {
  return {
    type: types.SET_POPULAR_HOTELS,
    payload: {
      value,
    },
  };
}
