const localStorageBase = (function () {
  function _storageAvailable() {
    try {
      if (!process.browser) {
        return false;
      }

      var storage = window['localStorage'],
        x = '__storage_test__';
      storage.setItem(x, x);
      storage.removeItem(x);
      return true;
    } catch (e) {
      return (
        e instanceof DOMException &&
        // everything except Firefox
        (e.code === 22 ||
          // Firefox
          e.code === 1014 ||
          // test name field too, because code might not be present
          // everything except Firefox
          e.name === 'QuotaExceededError' ||
          // Firefox
          e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
        // acknowledge QuotaExceededError only if there's something already stored
        storage.length !== 0
      );
    }
  }

  function _printError(error, explicit) {
    console.error(`[${explicit ? 'EXPLICIT' : 'INEXPLICIT'}] ${error.name}: ${error.message}`);
  }

  const storageAvailable = _storageAvailable();

  function getItemFromStorage(key) {
    if (storageAvailable) {
      const value = localStorage.getItem(key);

      if (value !== null) {
        try {
          return JSON.parse(value);
        } catch (error) {
          if (error instanceof SyntaxError) {
            _printError(error, true);
          } else {
            _printError(error, false);
          }
        }
      }
    } else {
      console.warn('LocalStorage is not supported!');
    }

    return null;
  }

  function setItemToStorage(key, data) {
    if (storageAvailable) {
      try {
        const value = JSON.stringify(data);
        localStorage.setItem(key, value);
      } catch (error) {
        if (error instanceof SyntaxError) {
          _printError(error, true);
        } else {
          _printError(error, false);
        }
      }
    } else {
      console.warn('LocalStorage is not supported!');
    }
  }

  function removeItemFromStorage(key) {
    if (storageAvailable) {
      try {
        localStorage.removeItem(key);
      } catch (error) {
        if (error instanceof SyntaxError) {
          _printError(error, true);
        } else {
          _printError(error, false);
        }
      }
    } else {
      console.warn('LocalStorage is not supported!');
    }
  }

  return {
    get: getItemFromStorage,
    set: setItemToStorage,
    remove: removeItemFromStorage,
  };
})();

export default localStorageBase;
