import * as types from './types';

const initialState = {
  departure: { options: null, value: null },
  month: { options: null, value: null },
  travelLength: { options: null, value: null },
};

function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.SET_STATE:
      return payload.state;

    case types.SET_DEPARTURE_OPTIONS:
      return setDepartureOptions(state, payload);

    case types.SET_DEPARTURE_VALUE:
      return setDepartureValue(state, payload);

    case types.SET_MONTH_OPTIONS:
      return setMonthOptions(state, payload);

    case types.SET_MONTH_VALUE:
      return setMonthValue(state, payload);

    case types.SET_TRAVEL_LENGTH_OPTIONS:
      return setTravelLengthOptions(state, payload);

    case types.SET_TRAVEL_LENGTH_VALUE:
      return setTravelLengthValue(state, payload);

    case types.RESET_STATE:
      return initialState;

    default:
      return state;
  }
}

export default reducer;

function setDepartureOptions(state, { options }) {
  return {
    ...state,
    departure: {
      ...state.departure,
      options,
    },
  };
}

function setDepartureValue(state, { value }) {
  return {
    ...state,
    departure: {
      ...state.departure,
      value,
    },
  };
}

function setMonthOptions(state, { options }) {
  return {
    ...state,
    month: {
      ...state.month,
      options,
    },
  };
}

function setMonthValue(state, { value }) {
  return {
    ...state,
    month: {
      ...state.month,
      value,
    },
  };
}

function setTravelLengthOptions(state, { options }) {
  return {
    ...state,
    travelLength: {
      ...state.travelLength,
      options,
    },
  };
}

function setTravelLengthValue(state, { value }) {
  return {
    ...state,
    travelLength: {
      ...state.travelLength,
      value,
    },
  };
}
