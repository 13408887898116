import * as types from './types';

const initialState = {
  fetching: false,
  affiliate: null,
  agentId: null,
};

function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.REQUEST_AFFILIATE:
      return requestAffiliate(state, payload);

    case types.SET_AFFILIATE:
      return setAffiliate(state, payload);

    case types.SET_AGENT_ID:
      return setAgentId(state, payload);

    case types.RESET_AGENT_ID:
      return resetAgentId(state);

    default:
      return state;
  }
}

export default reducer;

function requestAffiliate(state, { fetching }) {
  return {
    ...state,
    fetching,
  };
}

function setAffiliate(state, { affiliate }) {
  return {
    ...state,
    affiliate,
    fetching: false,
  };
}

function setAgentId(state, { agentId }) {
  return {
    ...state,
    agentId,
  };
}

function resetAgentId(state) {
  return {
    ...state,
    agentId: initialState.agentId,
  };
}
