import * as types from './types';

export function setActiveAction(action) {
  return {
    type: types.SET_ACTIVE_ACTION,
    payload: {
      action,
    },
  };
}

export function resetActiveAction() {
  return {
    type: types.RESET_ACTIVE_ACTION,
  };
}

export function setDisableForAction(actionId, disabled) {
  return {
    type: types.SET_DISABLE_FOR_ACTION,
    payload: {
      actionId,
      disabled,
    },
  };
}
