import moment from 'moment';

export default function getInitialVisibleMonth(highlights) {
  const visibleMonth = {
    leave: null,
    return: null,
  };
  let isSet = false;

  for (var keyYear in highlights) {
    if (highlights.hasOwnProperty(keyYear)) {
      const months = highlights[keyYear];

      for (var keyMonth in months) {
        if (months.hasOwnProperty(keyMonth)) {
          const dates = months[keyMonth];

          for (var keyDate in dates) {
            if (dates.hasOwnProperty(keyDate)) {
              const value = dates[keyDate];

              if (visibleMonth.leave === null && value.leave) {
                visibleMonth.leave = moment(`${keyYear}-${parseInt(keyMonth) + 1}-${keyDate}`, 'YYYY-MM-DD');
              }

              if (visibleMonth.return === null && value.return) {
                visibleMonth.return = moment(`${keyYear}-${parseInt(keyMonth) + 1}-${keyDate}`, 'YYYY-MM-DD');
              }

              if (visibleMonth.leave !== null && visibleMonth.return !== null) {
                isSet = true;
                break;
              }
            }
          }

          if (isSet) {
            break;
          }
        }
      }

      if (isSet) {
        break;
      }
    }
  }

  return visibleMonth;
}
