import { HOTEL_ONLY } from 'constants/selectValues';
import { setMissingValuesForInput, resetMissingValues } from './actions';

export function evalIsSearchAllowed() {
  return (dispatch, getState) => {
    const { selectOrigins, selectDestinations, selectDates, settings } = getState();
    const { selectedOrigins } = selectOrigins;
    const { selectedDestinations } = selectDestinations;
    const { dates } = selectDates;
    const isHotelOnly = selectedOrigins[0] && selectedOrigins[0].value === HOTEL_ONLY;

    if (settings.value.survivalMode && selectedOrigins.length === 0) {
      dispatch(setMissingValuesForInput('selectOrigins', true));
      return false;
    }

    if ((settings.value.survivalMode || isHotelOnly) && selectedDestinations.length === 0) {
      dispatch(setMissingValuesForInput('selectDestinations', true));
      return false;
    }

    if ((settings.value.survivalMode || isHotelOnly) && (dates.from === null || dates.to === null)) {
      dispatch(setMissingValuesForInput('selectDates', true));
      return false;
    }

    if (dates.from !== null && dates.to === null) {
      dispatch(setMissingValuesForInput('selectDates', true));
      return false;
    }

    return true;
  };
}

export function evalResetMissingValues() {
  return (dispatch, getState) => {
    const { missingValues } = getState().searchConditions;

    if (missingValues.selectDates || missingValues.selectDestinations) {
      dispatch(resetMissingValues());
    }
  };
}
