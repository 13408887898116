import { getInsurancesSearch } from 'httpClient/requests/checkoutPage/insurances';
import { requestInsurances, setInsurances, setValidity, setCurrentTokens } from './actions';
import { getInsurancesSearchRequestParameters } from './selectors';

export function fetchInsurances() {
  return (dispatch, getState) => {
    const { fetching, currentTokens } = getState().checkoutPageInsurances;
    const requestParameters = getInsurancesSearchRequestParameters(getState());
    const hotelChanged = currentTokens
      ? currentTokens.searchToken !== requestParameters.searchToken ||
        currentTokens.productToken !== requestParameters.productToken
      : true;

    if (fetching || !hotelChanged) {
      return;
    }

    dispatch(requestInsurances(true));

    return getInsurancesSearch(requestParameters)
      .then(({ data: { insurances } }) => {
        dispatch(setInsurances(insurances === null ? [] : insurances));
        dispatch(setCurrentTokens(requestParameters));
      })
      .catch((error) => {
        console.error(error);
        dispatch(requestInsurances(false));
      });
  };
}

export function validateInsurancesData() {
  return (dispatch, getState) => {
    const { fetching, insurances, validity } = getState().checkoutPageInsurances;

    if (fetching) {
      return false;
    }

    if (!insurances || (insurances && insurances.length === 0) || validity.valid) {
      return true;
    }

    dispatch(
      setValidity({
        valid: false,
        message: {
          id: 'requiredField.message',
          description: 'Please select one of the given options',
          defaultMessage: 'Please select one of the given options',
        },
      })
    );

    return false;
  };
}

export function getValidity() {
  return (dispatch, getState) => {
    return getState().checkoutPageInsurances.validity;
  };
}
