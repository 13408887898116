export default {
  ALCALL: 'ALC',
  BERALL: 'BER',
  SXF: 'BER',
  LGW: 'LON',
  FLL: 'MIA',
  FLLALL: 'MIA',
  MXP: 'MIL',
  EWR: 'NYC',
  JFK: 'NYC',
  NYCALL: 'NYC',
  OSLALL: 'OSL',
  CDG: 'PAR',
  ORY: 'PAR',
  PARALL: 'PAR',
  KEF: 'REK',
  FCO: 'ROM',
  ARN: 'STO',
  TCIALL: 'TFS',
};
