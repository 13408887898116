export const SET_STATE = 'checkoutPage/flightsFilters/SET_STATE';
export const SET_DIRECT = 'checkoutPage/flightsFilters/SET_DIRECT';
export const TOGGLE_DIRECT = 'checkoutPage/flightsFilters/TOGGLE_DIRECT';
export const SET_DEPARTURE_MIN_TIME_VALUE = 'checkoutPage/flightsFilters/SET_DEPARTURE_MIN_TIME_VALUE';
export const SET_DEPARTURE_MAX_TIME_VALUE = 'checkoutPage/flightsFilters/SET_DEPARTURE_MAX_TIME_VALUE';
export const SET_DEPARTURE_TIME_VALUE = 'checkoutPage/flightsFilters/SET_DEPARTURE_TIME_VALUE';
export const SET_RETURN_MIN_TIME_VALUE = 'checkoutPage/flightsFilters/SET_RETURN_MIN_TIME_VALUE';
export const SET_RETURN_MAX_TIME_VALUE = 'checkoutPage/flightsFilters/SET_RETURN_MAX_TIME_VALUE';
export const SET_RETURN_TIME_VALUE = 'checkoutPage/flightsFilters/SET_RETURN_TIME_VALUE';
export const SET_SORT_BY_VALUE = 'checkoutPage/flightsFilters/SET_SORT_BY_VALUE';
export const SET_DEPARTURE_AIRPORTS_VALUE = 'checkoutPage/flightsFilters/SET_DEPARTURE_AIRPORTS_VALUE';
export const SET_ARRIVAL_AIRPORTS_VALUE = 'checkoutPage/flightsFilters/SET_ARRIVAL_AIRPORTS_VALUE';
export const TOGGLE_IS_SAME_AIRPORTS_VALUE = 'checkoutPage/flightsFilters/TOGGLE_IS_SAME_AIRPORTS_VALUE';
export const TRIGGER_SHOW_CHEAPER_FLIGHTS_VALUE = 'checkoutPage/flightsFilters/TRIGGER_SHOW_CHEAPER_FLIGHTS_VALUE';
export const CLEAR_FLIGHTS_FILTERS = 'checkoutPage/flightsFilters/CLEAR_FLIGHTS_FILTERS';
export const RESET_FLIGHT_FILTERS = 'checkoutPage/flightsFilters/RESET_FLIGHT_FILTERS';
