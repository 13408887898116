/**
 * The value comes from `pageData.tripx.template`.
 * When updating this file also update `server/utils/routes/constants/templateKeys.js`.
 */
export const COUNTRIES = 'countries_list';
export const COUNTRY = 'country';
export const DESTINATION = 'destination';
export const HOTEL = 'hotel';
export const AIRLINE_LANDINGPAGE = 'landing_page';
export const THEMES = 'themes_list';
export const THEME = 'theme';
export const ADVERTS = 'adverts';
export const CUSTOMER_SERVICE = 'contact';
export const CORONA_VIRUS = 'covid_19_info';
export const COOKIES = 'cookies_page';
