import * as types from './types';
import { validateInputValue, getValidationData } from './selectors';
import * as inputNames from './constants/inputNames';

const initialState = {
  [inputNames.TITLE]: { value: '', validity: null, disabled: false },
  [inputNames.FIRST_NAME]: { value: '', validity: null, disabled: false },
  [inputNames.LAST_NAME]: { value: '', validity: null, disabled: false },
  [inputNames.GENDER]: { value: '', validity: null, disabled: false },
  [inputNames.EMAIL]: { value: '', validity: null, disabled: false },
  [inputNames.VERIFY_EMAIL]: { value: '', validity: null, disabled: false },
  [inputNames.PHONE]: { value: '', validity: null, disabled: false },
  [inputNames.COUNTRY_CODE]: { value: null, validity: false, disabled: false },
  [inputNames.ADDRESS]: { value: '', validity: null, disabled: false },
  [inputNames.CITY]: { value: '', validity: null, disabled: false },
  [inputNames.POST_CODE]: { value: '', validity: null, disabled: false },
  [inputNames.COUNTRY]: { value: null, validity: null, disabled: false },
  [inputNames.DATE_OF_BIRTH]: { value: '', validity: null, disabled: false },
  // [inputNames.PASSPORT_NUMBER]: { value: '', validity: null, disabled: false }
};

function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.SET_STATE:
      return payload.state;

    case types.SET_AND_VALIDATE_INPUT_VALUE:
      return setAndValidateInputValue(state, payload);

    case types.SET_INPUT_FIELD_VALIDITY:
      return setInputFieldValidity(state, payload);

    case types.RESET_CUSTOMER_DETAILS:
      return initialState;

    default:
      return state;
  }
}

export default reducer;

function setAndValidateInputValue(state, { name, value, validity }) {
  const data = getValidationData(state, name);
  
  return {
    ...state,
    [name]: {
      ...state[name],
      value,
      validity: validity ? validity : validateInputValue(name, value, data),
    },
  };
}

function setInputFieldValidity(state, { name, validity }) {
  return {
    ...state,
    [name]: {
      ...state[name],
      validity,
    },
  };
}
