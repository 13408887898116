import * as types from './types';

export function setSelectedDates(selectedDates) {
  return {
    type: types.SET_SELECTED_DATES,
    payload: {
      selectedDates,
    },
  };
}
export function setPriceDays(priceDays) {
  return {
    type: types.SET_PRICE_DAYS,
    payload: {
      priceDays,
    },
  };
}
export function setPriceDaysFetching(fetching) {
  return {
    type: types.SET_PRICE_DAYS_FETCHING,
    payload: {
      fetching,
    },
  };
}

export function setPriceDaysCalendarInfo(calendarPriceInfo) {
  return {
    type: types.SET_PRICE_DAYS_CALENDAR_INFO,
    payload: {
      calendarPriceInfo,
    },
  };
}
