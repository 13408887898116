/**
 * @typedef ProxyParams
 * @type {object}
 * @property {string=} [httpMethod=GET] - Valid HTTP method (verb).
 * @property {number=} [timeout=20] - Request timeout.
 * @property {number=} [version=1] - API version.
 * @property {string} apiMethod - TripXWebAPI method (vithout version).
 * @property {number=} affiliateId - Affiliate ID.
 * @property {object} body - Parameters as instructed in TripXWebAPI documentation.
 * @property {ProxyParamsQueryString=} queryString - Parameters as instructed in TripX API documentation.
 */

/**
 * @typedef ProxyParamsQueryString
 * @type {object}
 * @property {string=} affiliateId - The id of the affiliate (for websites that are not tripx).
 * @property {number=} agentId - The id of the agent (if there is a logged in agent).
 */

import HttpClient from 'httpClient';
import { urlBuilder } from 'httpClient/aws/getAwsEndpoint';
import getCookie from 'utils/cookie/getCookie';
import { TRIPX_AFFILIATE as TRIPX_AFFILIATE_COOKIE } from 'constants/cookiesKeys';

const method = '/p';

/**
 * Function that serves as a proxy to TripXWebAPI. (WP just forwards the requests with authnetication)
 * @param {ProxyParams} params
 */
export default function proxy(params, config) {
  const url = urlBuilder({ method, queryParam: null });

  params = setRequiredParams(params);

  // Check if we send the correct url for bookings/retrieve
  if (params && params.apiMethod === 'bookings/retrieve' && url.includes('use-staging-api=1')) {
    window.Rollbar.error(`bookings/retrieve proxy URL: ${url}, for booking code: ${params.body.bookingCode}`);
  }

  return HttpClient.post(url, params, config);
}

/**
 * Updates the request payload with some additional required properties.
 * @param {ProxyParams} params
 * @returns {ProxyParams}
 */
function setRequiredParams(params) {
  // We are required to always send `affiliateId`.
  const affiliateId = getCookie(TRIPX_AFFILIATE_COOKIE);

  if (affiliateId && affiliateId !== 'tripx') {
    params.queryString = {
      ...params.queryString,
      affiliateId,
    };
  }

  return params;
}
