import * as types from './types';

export function setState(state) {
  return {
    type: types.SET_STATE,
    payload: {
      state,
    },
  };
}

export function requestFlightsDetails(fetching) {
  return {
    type: types.REQUEST_FLIGHTS_DETAILS,
    payload: {
      fetching,
    },
  };
}

export function setFlightsDetailsValue(value) {
  return {
    type: types.SET_FLIGHTS_DETAILS_VALUE,
    payload: {
      value,
    },
  };
}

export function resetFlightsDetails() {
  return {
    type: types.RESET_FLIGHTS_DETAILS,
  };
}

export function setValidity(validity) {
  return {
    type: types.SET_VALIDITY,
    payload: {
      validity,
    },
  };
}

export function setUpgradesValidity(upgradesValidity) {
  return {
    type: types.SET_UPGRADES_VALIDITY,
    payload: {
      upgradesValidity,
    },
  };
}

export function updateUpgradesValidity(serviceKey, direction, passengerType, passengerIndex, validity) {
  return {
    type: types.UPDATE_UPGRADES_VALIDITY,
    payload: {
      serviceKey,
      direction,
      passengerType,
      passengerIndex,
      validity,
    },
  };
}

export function setChangedUpgradePrice(changedUpgradePrice) {
  return {
    type: types.SET_CHANGED_UPGRADE_PRICE,
    payload: {
      changedUpgradePrice,
    },
  };
}

export function setNetworkErrorValue(networkError) {
  return {
    type: types.SET_NETWORK_ERROR_VALUE,
    payload: {
      networkError,
    },
  };
}

export function setRequestedDepartureFlightMissing(requestedDepartureFlightMissing) {
  return {
    type: types.SET_REQUESTED_DEPARTURE_FLIGHT_MISSING,
    payload: {
      requestedDepartureFlightMissing,
    },
  };
}

export function setTravelDocuments(travelDocuments) {
  return {
    type: types.SET_TRAVEL_DOCUMENTS,
    payload: {
      ...travelDocuments,
    },
  };
}
