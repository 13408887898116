import * as types from './types';

export function setState(state) {
  return {
    type: types.SET_STATE,
    payload: {
      state,
    },
  };
}

export function setUserInformed(informUser) {
  return {
    type: types.SET_INFORM_USER,
    payload: {
      informUser,
    },
  };
}

export function resetState() {
  return {
    type: types.RESET_STATE,
  };
}
