export const SET_LOGIN = 'userBookings/SET_LOGIN';
export const SET_LOGIN_ERROR = 'userBookings/SET_LOGIN_ERROR';
export const SET_EMAIL = 'userBookings/SET_EMAIL';
export const SET_BOOKING_CODE = 'userBookings/SET_BOOKING_CODE';
export const SET_CREDENTIALS = 'userBookings/SET_CREDENTIALS';
export const SET_USER_BOOKINGS = 'userBookings/SET_USER_BOOKINGS';
export const SET_HOTEL_IMAGES = 'userBookings/SET_HOTEL_IMAGES';
export const SET_DESTINATION = 'userBookings/SET_DESTINATION';
export const SET_CANCEL_BOOKING = 'userBookings/SET_CANCEL_BOOKING';
export const SET_CANCELLATION_SUCCESS = 'userBookings/SET_CANCELLATION_SUCCESS';
export const RESET_USER_BOOKINGS = 'userBookings/RESET_USER_BOOKINGS';
