import * as types from './types';

export function setPaths(paths) {
  return {
    type: types.SET_PATHS,
    payload: {
      paths,
    },
  };
}

export function setConfig(config) {
  return {
    type: types.SET_CONFIG,
    payload: {
      config,
    },
  };
}

export function setSelectedCountry(selectedCountry) {
  return {
    type: types.SET_SELECTED_COUNTRY,
    payload: {
      selectedCountry,
    },
  };
}
