export const SET_STATE = 'checkoutPage/flightsDetails/SET_STATE';
export const REQUEST_FLIGHTS_DETAILS = 'checkoutPage/flightsDetails/REQUEST_FLIGHTS_DETAILS';
export const SET_FLIGHTS_DETAILS_VALUE = 'checkoutPage/flightsDetails/SET_FLIGHTS_DETAILS_VALUE';
export const RESET_FLIGHTS_DETAILS = 'checkoutPage/flightsDetails/RESET_FLIGHTS_DETAILS';
export const SET_VALIDITY = 'checkoutPage/flightsDetails/SET_VALIDITY';
export const SET_UPGRADES_VALIDITY = 'checkoutPage/flightsDetails/SET_UPGRADES_VALIDITY';
export const UPDATE_UPGRADES_VALIDITY = 'checkoutPage/flightsDetails/UPDATE_UPGRADES_VALIDITY';
export const SET_CHANGED_UPGRADE_PRICE = 'checkoutPage/flightsDetails/SET_CHANGED_UPGRADE_PRICE';
export const SET_NETWORK_ERROR_VALUE = 'checkoutPage/flightsDetails/SET_NETWORK_ERROR_VALUE';
export const SET_REQUESTED_DEPARTURE_FLIGHT_MISSING =
  'checkoutPage/flightsDetails/SET_REQUESTED_DEPARTURE_FLIGHT_MISSING';
export const SET_TRAVEL_DOCUMENTS = 'checkoutPage/flightsDetails/SET_TRAVEL_DOCUMENTS';
