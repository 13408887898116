import * as types from './types';

const initialState = {
  fetching: false,
  cancellationProtections: null,
  validity: {
    valid: null,
    message: null,
  },
  currentTokens: null,
  locked: false,
};

function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.SET_STATE:
      return payload.state;

    case types.REQUEST_CANCELLATION_PROTECTIONS:
      return requestCancellationProtections(state, payload);

    case types.SET_CANCELLATION_PROTECTIONS:
      return setCancellationProtections(state, payload);

    case types.SET_CURRENT_TOKENS:
      return setCurrentTokens(state, payload);

    case types.SET_VALIDITY:
      return setValidity(state, payload);

    case types.SET_LOCKED:
      return setLocked(state, payload);

    case types.RESET_STATE:
      return state.locked ? state : initialState;

    default:
      return state;
  }
}

export default reducer;

function requestCancellationProtections(state, { fetching }) {
  return {
    ...state,
    fetching,
  };
}

function setCancellationProtections(state, { cancellationProtections }) {
  return {
    ...state,
    fetching: false,
    cancellationProtections,
  };
}

function setCurrentTokens(state, { currentTokens }) {
  return {
    ...state,
    currentTokens,
  };
}

function setValidity(state, { validity }) {
  return {
    ...state,
    validity,
  };
}

function setLocked(state, { locked }) {
  return {
    ...state,
    locked,
  };
}
