import getSelectedFlightsCombinations from './getSelectedFlightsCombinations';

export default function getFlightProductsForGTM() {
  return (dispatch) => {
    const selectedFlightsCombinations = dispatch(getSelectedFlightsCombinations()) || [];

    return selectedFlightsCombinations.map((flightCombination) => {
      const { legs, prices, productToken } = flightCombination;
      const name = `${legs[0].departureAirport}-${legs[legs.length - 1].destinationAirport}`;

      return {
        name,
        id: productToken,
        price: prices.total,
      };
    });
  };
}
