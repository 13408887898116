import getFlightsCombinationByProductToken from './getFlightsCombinationByProductToken';

export default function getSelectedFlightsCombinations() {
  return (dispatch, getState) => {
    const { selectedFlights } = getState().checkoutPageTravelSummary;

    if (selectedFlights === null) {
      return null;
    }

    return selectedFlights.map((productToken) => dispatch(getFlightsCombinationByProductToken(productToken)));
  };
}
