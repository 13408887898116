import { HOTEL_ONLY } from 'constants/selectValues';

export default function formatOriginsForReactSelect(
  { origins, availableOrigins = [] },
  includeHotelOnly,
  forceFormat,
  removeGot
) {
  const destinationSelected = availableOrigins.length > 0;
  const formattedOrigins = origins
    .filter(({ code, enabled }) => {
      if (removeGot && code === 'GOT') {
        return false;
      }

      return forceFormat ? true : enabled;
    })
    .map(({ code, name, isGroup }) => {
      return {
        value: code,
        label: name,
        disabled: destinationSelected && availableOrigins.indexOf(code) === -1,
        isGroup,
      };
    })
    .filter(origin => !origin.disabled);

  if (includeHotelOnly) {
    formattedOrigins.unshift({
      value: HOTEL_ONLY,
      data: {
        hideValue: true,
        translation: {
          id: 'originsDropdown.hotelOnly.label',
          description: 'Hotel Only',
          defaultMessage: 'Hotel Only',
        },
      },
    });
  }

  return formattedOrigins;
}
