import * as types from './types';

export function updateNumberOfAdults(value, roomIndex) {
  return {
    type: types.UPDATE_NUMBER_OF_ADULTS,
    payload: {
      value,
      roomIndex,
    },
  };
}

export function updateNumberOfChildren(value, roomIndex, childIndex, childAge) {
  return {
    type: types.UPDATE_NUMBER_OF_CHILDREN,
    payload: {
      value,
      roomIndex,
      childIndex,
      childAge,
    },
  };
}

export function updateChildAge(value, roomIndex, childIndex) {
  return {
    type: types.UPDATE_CHILD_AGE,
    payload: {
      value,
      roomIndex,
      childIndex,
    },
  };
}

export function addRoom() {
  return {
    type: types.ADD_ROOM,
  };
}

export function removeRoom() {
  return {
    type: types.REMOVE_ROOM,
  };
}

export function removeRoomAtIndex(index) {
  return {
    type: types.REMOVE_ROOM_AT_INDEX,
    payload: {
      index,
    },
  };
}

export function setRooms(rooms) {
  return {
    type: types.SET_ROOMS,
    payload: {
      rooms,
    },
  };
}

export function resetRooms() {
  return {
    type: types.RESET_ROOMS,
  };
}
