import { PRICE } from 'constants/sortByValues';
import * as types from './types';

const initialState = {
  direct: false,
  departureTime: {
    min: 0,
    max: 1440,
    value: [0, 1440],
  },
  returnTime: {
    min: 0,
    max: 1440,
    value: [0, 1440],
  },
  sortBy: {
    value: PRICE,
  },
  departureAirports: [],
  arrivalAirports: [],
  isSameAirports: false,
  triggerShowCheaperFlights: false,
};

function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.SET_STATE:
      return payload.state;

    case types.SET_DIRECT:
      return setDirect(state, payload);

    case types.TOGGLE_DIRECT:
      return toggleDirect(state);

    case types.SET_DEPARTURE_MIN_TIME_VALUE:
      return setDepartureMinTimeValue(state, payload);

    case types.SET_DEPARTURE_MAX_TIME_VALUE:
      return setDepartureMaxTimeValue(state, payload);

    case types.SET_DEPARTURE_TIME_VALUE:
      return setDepartureTimeValue(state, payload);

    case types.SET_RETURN_MIN_TIME_VALUE:
      return setReturnMinTimeValue(state, payload);

    case types.SET_RETURN_MAX_TIME_VALUE:
      return setReturnMaxTimeValue(state, payload);

    case types.SET_RETURN_TIME_VALUE:
      return setReturnTimeValue(state, payload);

    case types.SET_SORT_BY_VALUE:
      return setSortByValue(state, payload);

    case types.CLEAR_FLIGHTS_FILTERS:
      return clearFlightsFilters(state);

    case types.SET_DEPARTURE_AIRPORTS_VALUE:
      return setDepartureAirportsValue(state, payload);

    case types.SET_ARRIVAL_AIRPORTS_VALUE:
      return setArrivalAirportsValue(state, payload);

    case types.TOGGLE_IS_SAME_AIRPORTS_VALUE:
      return toggleIsSameAirports(state, payload);

    case types.TRIGGER_SHOW_CHEAPER_FLIGHTS_VALUE:
      return triggerShowCheaperFlights(state);

    case types.RESET_FLIGHT_FILTERS:
      return initialState;

    default:
      return state;
  }
}

export default reducer;

function setDirect(state, { direct }) {
  return {
    ...state,
    direct,
  };
}

function toggleDirect(state) {
  return {
    ...state,
    direct: !state.direct,
  };
}

function setDepartureMinTimeValue(state, { min }) {
  return {
    ...state,
    departureTime: {
      ...state.departureTime,
      min,
    },
  };
}

function setDepartureMaxTimeValue(state, { max }) {
  return {
    ...state,
    departureTime: {
      ...state.departureTime,
      max,
    },
  };
}

function setDepartureTimeValue(state, { value }) {
  return {
    ...state,
    departureTime: {
      ...state.departureTime,
      value,
    },
  };
}

function setReturnMinTimeValue(state, { min }) {
  return {
    ...state,
    returnTime: {
      ...state.returnTime,
      min,
    },
  };
}

function setReturnMaxTimeValue(state, { max }) {
  return {
    ...state,
    returnTime: {
      ...state.returnTime,
      max,
    },
  };
}

function setReturnTimeValue(state, { value }) {
  return {
    ...state,
    returnTime: {
      ...state.returnTime,
      value,
    },
  };
}

function setSortByValue(state, { value }) {
  return {
    ...state,
    sortBy: {
      ...state.sortBy,
      value,
    },
  };
}

function setDepartureAirportsValue(state, { value }) {
  return {
    ...state,
    departureAirports: value,
  };
}

function setArrivalAirportsValue(state, { value }) {
  return {
    ...state,
    arrivalAirports: value,
  };
}

function toggleIsSameAirports(state) {
  return {
    ...state,
    isSameAirports: !state.isSameAirports,
  };
}

function triggerShowCheaperFlights(state) {
  return {
    ...state,
    triggerShowCheaperFlights: !state.triggerShowCheaperFlights,
  };
}

function clearFlightsFilters(state) {
  return {
    ...state,
    direct: initialState.direct,
    departureTime: {
      ...state.departureTime,
      value: [state.departureTime.min, state.departureTime.max],
    },
    returnTime: {
      ...state.returnTime,
      value: [state.returnTime.min, state.returnTime.max],
    },
    isSameAirports: initialState.isSameAirports,
  };
}
