export default function getAirportsAreSame(legs, selectedOrigin) {
  const departureDestinationAirport = legs[0].segments[legs[0].segments.length - 1].destinationAirport;
  const returnDepartureAirport = legs[legs.length - 1].segments[0].departureAirport;
  const arrivalAndDepartureAirportAreNotTheSame = departureDestinationAirport !== returnDepartureAirport;

  const departureOriginAirport = legs[0].segments[0].departureAirport;
  const returnDestinationAirport =
    legs[legs.length - 1].segments[legs[legs.length - 1].segments.length - 1].destinationAirport;
  const initialAndLastAirportAreNotTheSame = departureOriginAirport !== returnDestinationAirport;

  const requestDepartureNotTheSame =
    selectedOrigin && !selectedOrigin.isGroup && selectedOrigin.value !== legs[0].segments[0].departureAirport;
    
  return {
    arrivalAndDepartureAirportAreNotTheSame,
    initialAndLastAirportAreNotTheSame,
    requestDepartureNotTheSame,
  };
}
