import _find from 'lodash/find';
import { HOTEL_ONLY } from 'constants/selectValues';

export default function createHotelsMap(results, hotels) {
  let hotelsMap = {};

  results.forEach((result, index) => {
    if (!hotelsMap[result.hotelId]) {
      const hotel = _find(hotels, (hotel) => hotel.id === result.hotelId);

      hotelsMap[result.hotelId] = {
        ...hotel,
        package: {
          index: index,
          price: result.price,
          departure: result.airport ? result.airport.name : HOTEL_ONLY,
        },
      };

      result.hotelScore = hotel ? hotel.score : 5;
    }
  });

  return hotelsMap;
}
