import setCookie from 'utils/cookie/setCookie';

export default function setCookieProxy(cname, cvalue, exdays = 1, domain = window.location.hostname, path) {
  return (dispatch, getState) => {
    const selectedCountry = getState().routes.selectedCountry;

    if (selectedCountry) {
      path = path ? `/${selectedCountry}${path}` : `/${selectedCountry}`;
    }

    setCookie(cname, cvalue, exdays, domain, path);
  };
}
