import push from 'utils/immutable/push';
import shift from 'utils/immutable/shift';
import * as types from './types';

/**
 * @typedef QueueItem
 * @type {object}
 * @property {LoggerType=} [loggerType=Rollbar] - Valid logger (Rollbar, console).
 * @property {LogType=} [logType='error'] - Log type (info, warn, error).
 * @property {string} message - Log message.
 * @property {object=} args - Log arguments.
 */

const initialState = {
  queue: [],
};

function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.SET_STATE:
      return payload.state;

    case types.PUSH_TO_QUEUE:
      return pushToQueue(state, payload);

    case types.SHIFT_QUEUE:
      return shiftQueue(state);

    case types.RESET_STATE:
      return initialState;

    default:
      return state;
  }
}

export default reducer;

function pushToQueue(state, { item }) {
  return {
    ...state,
    queue: push(state.queue, item),
  };
}

function shiftQueue(state) {
  return {
    ...state,
    queue: shift(state.queue),
  };
}
