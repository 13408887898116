import * as types from './types';

const initialState = {
  elements: {},
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_ELEMENT_INTO_VIEW:
      return setElementIntoView(state, action.payload);

    default:
      return state;
  }
}

export default reducer;

function setElementIntoView(state, { key }) {
  return {
    ...state,
    elements: {
      ...state.elements,
      [key]: true,
    },
  };
}
