import _isEmpty from 'lodash/isEmpty';
import {
  FRONTPAGE as PAGE_FRONTPAGE,
  COUNTRY_SELECTION as PAGE_COUNTRY_SELECTION,
  HOTELPAGE as PAGE_HOTELPAGE,
  TEMPLATE as PAGE_TEMPLATE,
} from 'constants/pages';
import { HOTEL as TEMPLATE_KEY_HOTEL } from 'routes/template/constants/templateKeys';

export function getRoutePathByRouteKey(page, excludeLang) {
  return (dispatch, getState) => {
    const { paths, selectedCountry } = getState().routes;

    if (paths !== null && paths[page] !== undefined) {
      const pagePath = paths[page].replace('/*', '');

      if (!_isEmpty(selectedCountry)) {
        if (!excludeLang) {
          return pagePath.replace(':lang', selectedCountry);
        }

        return pagePath.replace('/:lang', '');
      }

      return pagePath;
    }

    return null;
  };
}

export function getPageFromPath(requestPath) {
  return (dispatch, getState) => {
    const { routes, template: templateReducer, settings } = getState();
    const { airlineWhitelabel } = settings.value;
    const { config: routesConfig, selectedCountry } = routes;
    const { template } = templateReducer;

    if (template === TEMPLATE_KEY_HOTEL) {
      return PAGE_HOTELPAGE;
    }

    const regExMatch = requestPath.match(/^(\/[^/]*){1,2}/);

    if (!regExMatch) {
      return !airlineWhitelabel ? PAGE_FRONTPAGE : PAGE_COUNTRY_SELECTION;
    }

    let routePath = regExMatch[0].replace(/\/+$/, '');

    if (airlineWhitelabel) {
      if (!selectedCountry) {
        return PAGE_COUNTRY_SELECTION;
      }

      routePath = routePath.replace(/^\/[^/]*/, '/:lang');
    }

    const route = routesConfig.find(({ path }) => {
      const wildcardPosition = path.indexOf('/*');

      // Works only for wildcard at the end of the path
      if (wildcardPosition !== -1) {
        path = path.replace('/*', '');
        const _routePath = routePath.slice(0, wildcardPosition);
        return path.replace(/\/+$/, '') === _routePath;
      }

      return path.replace(/\/+$/, '') === routePath;
    });

    return route ? route.page : PAGE_TEMPLATE;
  };
}
