import moment from 'moment';
import _unionBy from 'lodash/unionBy';
import { LUGGAGE as LUGGAGE_SERVICE_KEY } from 'routes/checkoutPage/constants/servicesKeys';
import firstCharToLowerCase from 'utils/string/firstCharToLowerCase';
import sortServicesByType from '../selectors/sorting/sortServicesByType';
import getAirportsAreSame from './getAirportsAreSame';

// Services that are always visible on the flights combinations card
const alwaysVisibleServices = [{ type: LUGGAGE_SERVICE_KEY, included: false }];

export default function transformFlightsCombinationsForUI(
  flightsData,
  airportNamesMap,
  carrierNamesMap,
  selectedOrigin
) {
  if (!flightsData) {
    return null;
  }
  const { legs } = flightsData;

  const { arrivalAndDepartureAirportAreNotTheSame, initialAndLastAirportAreNotTheSame, requestDepartureNotTheSame } =
    getAirportsAreSame(legs, selectedOrigin);

  return legs.map(({ segments, travelTime, nonstop, fareClass, minimumServices, isOvernight }, index, array) => {
    const isFirstLeg = index === 0;
    const isLastLeg = array.length - 1 === index;
    const lastSegment = segments[segments.length - 1];
    const travelTimeDuration = moment.duration(travelTime, 'minutes');
    const travelDurationHours = travelTimeDuration.hours() + travelTimeDuration.days() * 24;
    const carriers = [...new Set(segments.map(({ carrier }) => carrier))].map((carrier) => ({
      carrierCode: carrier,
      carrierName: carrierNamesMap[carrier],
      carrierLogo: `https://carrierlogos.tripx.eu/${carrier}.png?w=32&`,
    }));
    const services = _unionBy(
      minimumServices
        .map(({ type, quantity, maxWeight }) => ({
          type: firstCharToLowerCase(type),
          included: true,
          description: maxWeight ? `${quantity} x ${maxWeight.value} ${maxWeight.unitOfMeasure}` : quantity,
        }))
        .sort(sortServicesByType),
      alwaysVisibleServices,
      'type'
    );
    return {
      departureTime: moment(segments[0].departureTime.slice(0, -6)).format('HH:mm'),
      departureDate: moment(segments[0].departureTime.slice(0, -6)).format('DD MMM YYYY'),
      departureDateLong: moment(segments[0].departureTime.slice(0, -6)).format('ddd DD MMM YYYY'),
      departureDateTime: moment(segments[0].departureTime).format('YYYY-MM-DD HH:mm'),
      departureAirport: segments[0].departureAirport,
      departureAirportName: airportNamesMap[segments[0].departureAirport],
      arrivalTime: moment(lastSegment.arrivalTime.slice(0, -6)).format('HH:mm'),
      arrivalDate: moment(lastSegment.arrivalTime).format('DD MMM YYYY'),
      arrivalDateLong: moment(lastSegment.arrivalTime.slice(0, -6)).format('ddd DD MMM YYYY'),
      arrivalDateTime: moment(lastSegment.arrivalTime).format('YYYY-MM-DD HH:mm'),
      destinationAirport: lastSegment.destinationAirport,
      destinationAirportName: airportNamesMap[lastSegment.destinationAirport],
      carriers,
      fareClass,
      flightNumber: segments[0].flightNumber,
      travelTime: `${travelDurationHours}h ${travelTimeDuration.minutes()}min`,
      numberOfStops: segments.length - 1,
      stop1AirportCode: !nonstop && segments[0].destinationAirport,
      stop1AirportName: !nonstop && airportNamesMap[segments[0].destinationAirport],
      arrivalAndDepartureAirportAreNotTheSame: isLastLeg && arrivalAndDepartureAirportAreNotTheSame,
      services,
      initialAndLastAirportAreNotTheSame: isLastLeg && initialAndLastAirportAreNotTheSame,
      isOvernight: isOvernight,
      requestDepartureNotTheSame: isFirstLeg && requestDepartureNotTheSame,
    };
  });
}
