import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';
import axis from 'axis.js';
import { FormattedMessage } from 'i18n';
import Arrow from './components/arrow/ArrowComponent';
import Overlay from './components/overlay/OverlayComponent';

const validSeverityValues = ['error'];
const propTypes = {
  visible: PropTypes.bool,
  message: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.node.isRequired,
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      defaultMessage: PropTypes.string.isRequired,
    }),
  ]),
  severity: PropTypes.oneOf(validSeverityValues),
};

function InputTooltipComponent({ visible, message, severity, children }) {
  const _message = !axis.isObject(message) ? message : <FormattedMessage {...message} />;

  return (
    <Tooltip
      visible={visible}
      placement='topLeft'
      trigger={[]}
      mouseLeaveDelay={0}
      destroyTooltipOnHide={true}
      prefixCls={'input-tooltip-v2'}
      overlay={<Overlay severity={severity}>{_message}</Overlay>}
      arrowContent={<Arrow severity={severity} />}
      align={{ offset: [0, -12] }}
    >
      {children}
    </Tooltip>
  );
}

InputTooltipComponent.propTypes = propTypes;

export default InputTooltipComponent;
