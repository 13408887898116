export const SET_STATE = 'checkoutPage/travelSummary/SET_STATE';
export const SET_SELECTED_HOTELS = 'checkoutPage/travelSummary/SET_SELECTED_HOTELS';
export const SET_SELECTED_ROOMS = 'checkoutPage/travelSummary/SET_SELECTED_ROOMS';
export const UPDATE_SELECTED_ROOMS = 'checkoutPage/travelSummary/UPDATE_SELECTED_ROOMS';
export const SET_SELECTED_FLIGHTS = 'checkoutPage/travelSummary/SET_SELECTED_FLIGHTS';
export const SET_SELECTED_FLIGHTS_EXTRAS = 'checkoutPage/travelSummary/SET_SELECTED_FLIGHTS_EXTRAS';
export const UPDATE_SELECTED_FLIGHTS_EXTRAS = 'checkoutPage/travelSummary/UPDATE_SELECTED_FLIGHTS_EXTRAS';
export const SET_SELECTED_OTHER_EXTRAS = 'checkoutPage/travelSummary/SET_SELECTED_OTHER_EXTRAS';
export const SET_SELECTED_TRANSFER = 'checkoutPage/travelSummary/SET_SELECTED_TRANSFER';
export const SET_SELECTED_VEHICLE_RENTALS = 'checkoutPage/travelSummary/SET_SELECTED_VEHICLE_RENTALS';
export const UPDATE_SELECTED_VEHICLE_RENTALS = 'checkoutPage/travelSummary/UPDATE_SELECTED_VEHICLE_RENTALS';
export const SET_SELECTED_INSURANCE = 'checkoutPage/travelSummary/SET_SELECTED_INSURANCE';
export const SET_SELECTED_CO2COMPENSATION = 'checkoutPage/travelSummary/SET_SELECTED_CO2COMPENSATION';
export const SET_SELECTED_EARTH_TODAY = 'checkoutPage/travelSummary/SET_SELECTED_EARTH_TODAY';
export const SET_SELECTED_CANCELLATION_PROTECTION = 'checkoutPage/travelSummary/SET_SELECTED_CANCELLATION_PROTECTION';
export const SET_CURRENTLY_OPEN_SUMMARIES = 'checkoutPage/travelSummary/SET_CURRENTLY_OPEN_SUMMARIES';
export const UPDATE_CURRENTLY_OPEN_SUMMARIES = 'checkoutPage/travelSummary/UPDATE_CURRENTLY_OPEN_SUMMARIES';
export const RESET_STATE = 'checkoutPage/travelSummary/RESET_STATE';
