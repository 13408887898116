import * as types from './types';

export function requestOrigins() {
  return {
    type: types.REQUEST_ORIGINS,
  };
}

export function setOrigins(origins) {
  return {
    type: types.SET_ORIGINS,
    payload: {
      origins,
    },
  };
}

export function setSelectedOrigins(selectedOrigins) {
  return {
    type: types.SET_SELECTED_ORIGINS,
    payload: {
      selectedOrigins,
    },
  };
}

export function setAvailableOrigins(availableOrigins, forceSingleValue) {
  return {
    type: types.SET_AVAILABLE_ORIGINS,
    payload: {
      availableOrigins,
      forceSingleValue,
    },
  };
}

export function setAvailableOriginsMap(availableOrigins, availableOriginsMap, forceSingleValue) {
  return {
    type: types.SET_AVAILABLE_ORIGINS_MAP,
    payload: {
      availableOrigins,
      availableOriginsMap,
      forceSingleValue,
    },
  };
}

export function setForceSingleValue(forceSingleValue) {
  return {
    type: types.SET_FORCE_SINGLE_VALUE,
    payload: {
      forceSingleValue,
    },
  };
}

export function resetSelectedOrigins() {
  return {
    type: types.RESET_SELECTED_ORIGINS,
  };
}
