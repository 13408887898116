import * as types from './types';

const initialState = {
  fetching: null,
  tsAndCs: {},
};

function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.SET_STATE:
      return payload.state;

    case types.SET_FETCHING:
      return setFetching(state, payload);

    case types.SET_TS_AND_CS:
      return setTsAndCs(state, payload);

    case types.SET_TS_AND_CS_FOR_PRODUCT_TOKEN:
      return setTsAndCsForProductToken(state, payload);

    case types.RESET_STATE:
      return initialState;

    default:
      return state;
  }
}

export default reducer;

function setFetching(state, { fetching }) {
  return {
    ...state,
    fetching,
  };
}

function setTsAndCs(state, { tsAndCs }) {
  return {
    ...state,
    tsAndCs,
  };
}

function setTsAndCsForProductToken(state, { productToken, value }) {
  return {
    ...state,
    tsAndCs: {
      ...state.tsAndCs,
      [productToken]: value,
    },
  };
}
