import moment from 'moment';
import Router from 'next/router';
import {
  FETCH_SEARCH_RESULTS,
  FILTER_SEARCH_RESULTS,
  FETCH_SEARCH_RESULTS_FROM_QUERY,
} from 'state/searchResults/types';
import fetchSearchResults from 'state/searchResults/operations/fetchSearchResults';
import buildSearchResultsRequestParams from 'state/searchResults/selectors/buildSearchResultsRequestParams';
import buildSearchResultsRequestParamsFromQuery from 'state/searchResults/selectors/buildSearchResultsRequestParamsFromQuery';
import mapNorwegianAirportCodesToOriginCodes from 'state/searchResults/selectors/mapNorwegianAirportCodesToOriginCodes';
import mapNorwegianAirportCodesToDestinationCodes from 'state/searchResults/selectors/mapNorwegianAirportCodesToDestinationCodes';
import getNumberOfBars from 'state/priceBars/selectors/getNumberOfBars';
import getPriceBarsRequestData from 'state/priceBars/selectors/getPriceBarsRequestData';
import fetchPriceBars from 'state/priceBars/operations/fetchPriceBars';
import { routesOperations } from 'state/routes';
import hydrateSelectedDates from 'state/selectDates/operations/hydrateSelectedDates';
import hydrateSelectedOrigins from 'state/selectOrigins/operations/hydrateSelectedOrigins';
import hydrateSelectedDestinations from 'state/selectDestinations/operations/hydrateSelectedDestinations';
import hydrateSelectedRooms from 'state/selectRooms/operations/hydrateSelectedRooms';
import { getSearchResultsQueryParams, getHotelPageQueryParams } from 'utils/requestQuery/getQueryParams';
import executeTrackingEvent from 'managers/gtmEventTracking/executeTrackingEvent';
import { SEARCH_BOX_SUBMIT as TRACKING_EVENT_SEARCH_BOX_SUBMIT } from 'managers/gtmEventTracking/constants/eventsNames';
import { SEARCH as SEARCH_ROUTE, FRONTPAGE as ROUTE_KEY_FRONTPAGE } from 'constants/routesKeys';
import { SEARCH as SEARCH_PAGE, HOTELPAGE as PAGE_HOTELPAGE } from 'constants/pages';
import {
  HOTELPAGE as PAGE_COMPONENT_KEY_HOTELPAGE,
  // CHECKOUT_PAGE as PAGE_COMPONENT_KEY_CHECKOUT_PAGE, //? USUSED
} from 'constants/pagesComponentsKeys';
import { setCurrentPageComponent } from 'state/currentPageComponent/actions';
import { setDisplayPriceBars, updatePriceBarsVisibility } from 'state/priceBars/actions';

let waitingForTimeout = false;

// @TODO Why was this made as a middleware? If you can't find a reason, transfer it to operations
function searchResultsMiddleware() {
  return ({ dispatch, getState }) =>
    (next) =>
    (action) => {
      let triggerSearch = false;
      const pathToSearchResults = dispatch(routesOperations.getRoutePathByRouteKey(SEARCH_ROUTE));
      const isSearch = action.type === FETCH_SEARCH_RESULTS;
      const isFiltering = action.type === FILTER_SEARCH_RESULTS;
      // The same action is dispatched on initial page load, that's why we need the `waitingForTimeout` variable
      // @TODO This won't work because we don't use connected-react-router
      const isBrowserButtonNavigation = false;
      // ((action.type === '@@router/LOCATION_CHANGE') &&
      // (action.payload.action === 'POP') &&
      // (action.payload.location.pathname === pathToSearchResults));
      let params;

      if (isSearch || isFiltering) {
        const { selectOrigins, selectDestinations, selectDates, selectRooms, searchResultsFilters } = getState();

        const destinationIsHotel =
          selectDestinations.selectedDestinations[0] && selectDestinations.selectedDestinations[0].hotel;
        const currentPath = Router.asPath.split('?')[0];

        if (currentPath !== pathToSearchResults && !destinationIsHotel) {
          Router.push(SEARCH_PAGE, pathToSearchResults);
          return next(action);
        }

        params = buildSearchResultsRequestParams(
          selectOrigins.selectedOrigins,
          selectDestinations.selectedDestinations,
          selectDates.dates,
          selectRooms.rooms,
          searchResultsFilters,
          null,
          {
            calendarOption: selectDates.calendarOption,
          }
        );

        if (destinationIsHotel) {
          params.origin = params.origins;
        }

        if (isSearch) {
          dispatch(
            executeTrackingEvent(TRACKING_EVENT_SEARCH_BOX_SUBMIT, {
              origins: params.origins,
              destinations: params.destinations,
              dates: params.dates,
              roomConfigurations: params.roomConfigurations,
            })
          );
        }

        const queryParams = !destinationIsHotel
          ? getSearchResultsQueryParams(params, null)
          : getHotelPageQueryParams(params, null);

        if (!destinationIsHotel) {
          Router.push(SEARCH_PAGE, `${pathToSearchResults}?${queryParams}`);
          triggerSearch = true;
        } else {
          const pathToFrontpage = dispatch(routesOperations.getRoutePathByRouteKey(ROUTE_KEY_FRONTPAGE)).replace(
            /\/([^/]*)$/,
            '$1'
          );
          const hotelPageUrl = `${pathToFrontpage}/c/${selectDestinations.selectedDestinations[0].value}?${queryParams}`;

          dispatch(setCurrentPageComponent(PAGE_COMPONENT_KEY_HOTELPAGE));

          Router.push(
            {
              pathname: PAGE_HOTELPAGE,
              query: {
                data: { requestParams: selectDestinations.selectedDestinations[0].value },
              },
            },
            hotelPageUrl
          );

          return next(action);
        }
      }

      if (action.type === FETCH_SEARCH_RESULTS_FROM_QUERY || isBrowserButtonNavigation) {
        params = buildSearchResultsRequestParamsFromQuery();
        if (params.destinations) {
          params.destinations = mapNorwegianAirportCodesToDestinationCodes(params.destinations);
        }
        if (params.origins) {
          params.origins = mapNorwegianAirportCodesToOriginCodes(params.origins);
        }
        triggerSearch = true;
      }

      if (triggerSearch && !waitingForTimeout) {
        const currentDate = moment(moment().format('YYYY-MM-DD'));
        const queryDate = params.dates && params.dates.from ? params.dates.from : currentDate.format('YYYY-MM-DD');
        const numberOfBars = getNumberOfBars();
        const priceBarsRequestData = getPriceBarsRequestData(currentDate, queryDate, numberOfBars);
        const { priceBars } = getState();

        if (priceBars.rolledUp) {
          dispatch(updatePriceBarsVisibility(undefined, true));
          dispatch(setDisplayPriceBars(true));
        }

        if (!isBrowserButtonNavigation) {
          dispatch(fetchSearchResults(params, isFiltering));

          if (
            (action.payload && action.payload.typeOfAction !== 'priceBarsAction') ||
            typeof action.payload === 'undefined'
          ) {
            dispatch(fetchPriceBars(queryDate, params, priceBarsRequestData));
          }
        } else {
          waitingForTimeout = true;
          setTimeout(() => {
            waitingForTimeout = false;
            dispatch(hydrateSelectedDates());
            dispatch(hydrateSelectedOrigins());
            dispatch(hydrateSelectedDestinations());
            dispatch(hydrateSelectedRooms());
            dispatch(fetchSearchResults(params, isFiltering));

            if (action.payload && action.payload.typeOfAction !== 'priceBarsAction') {
              dispatch(fetchPriceBars(queryDate, params, priceBarsRequestData));
            }
          }, 0);
        }
      }

      return next(action);
    };
}

export default searchResultsMiddleware();
