/**
 * Removes UTM parameters and additional specified parameters from a URL.
 * @param {string} url The URL from which to remove parameters.
 * @param {string[]} [additionalParamsToDelete=[]] Additional parameters to remove, if any.
 * @returns {string} The modified URL with specified parameters removed.
 */

export function deleteUTMParametersFromURL(url, additionalParamsToDelete = []) {
  const urlParsed = new URL(url);
  const urlQueryParsed = new URLSearchParams(urlParsed.search);

  // Iterate through all parameters and remove those that start with "utm_" or are in additionalParamsToDelete
  for (const param of urlQueryParsed.keys()) {
    if (param.startsWith('utm_') || additionalParamsToDelete.includes(param)) {
      urlQueryParsed.delete(param);
    }
  }

  urlParsed.search = urlQueryParsed.toString();

  // Return the modified url as string
  return urlParsed.toString();
}
