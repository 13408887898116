import * as types from './types';

export function setFetching(fetching) {
  return {
    type: types.SET_FETCHING,
    payload: {
      fetching,
    },
  };
}

export function setResolverData(response) {
  return {
    type: types.SET_RESOLVER_DATA,
    payload: {
      response,
    },
  };
}

export function setRedirect(redirect) {
  return {
    type: types.SET_REDIRECT,
    payload: {
      redirect,
    },
  };
}

export function setShouldFetchData(shouldFetchData) {
  return {
    type: types.SET_SHOULD_FETCH_DATA,
    payload: {
      shouldFetchData,
    },
  };
}

export function updateTemplateMeta(meta) {
  return {
    type: types.UPDATE_TEMPLATE_META,
    payload: {
      meta,
    },
  };
}

export function resetState() {
  return {
    type: types.RESET_STATE,
  };
}
