import * as servicesKeys from './servicesKeys';

export default {
  [servicesKeys.LUGGAGE]: 'suitcase',
  [servicesKeys.CABIN_LUGGAGE]: 'suitcase-rolling',
  [servicesKeys.PERSONAL_ITEM]: 'backpack',
  [servicesKeys.MEAL]: 'hamburger',
  [servicesKeys.PRIORITY_BOARDING]: 'star',
  [servicesKeys.PRIORITY_BUNDLE]: 'star',
  [servicesKeys.SEAT_RESERVATION]: 'chair',
  [servicesKeys.CO2_COMPENSATION]: 'hand-holding-heart',
  [servicesKeys.TRANSFERS]: 'car-bus',
  [servicesKeys.INSURANCES]: 'briefcase-medical',
  [servicesKeys.CANCELLATION_PROTECTION]: 'shield-alt',
  [servicesKeys.CAR_RENTAL]: 'car',
  [servicesKeys.EARTH_TODAY]: 'globe',
  PlaneArrival: 'plane-arrival',
  PlaneDeparture: 'plane-departure',
  Plane: 'plane',
};
