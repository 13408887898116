import * as types from './types';

const initialState = {
  fetchingPayment: null,
  paymentData: null,
  paymentResponse: null,
  additionalDetails: null,
  additionalDetailsResponse: null, // @TODO: Not implemented. It was not required for `scheme` (Credit Card)
};

function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.SET_STATE:
      return payload.state;

    case types.SET_FETCHING_PAYMENT:
      return setFetchingPayment(state, payload);

    case types.SET_PAYMENT_DATA:
      return setPaymentData(state, payload);

    case types.SET_PAYMENT_RESPONSE:
      return setPaymentResponse(state, payload);

    case types.SET_ADDITIONAL_DETAILS:
      return setAdditionalDetails(state, payload);

    case types.RESET_STATE:
      return initialState;

    default:
      return state;
  }
}

export default reducer;

function setFetchingPayment(state, { fetchingPayment }) {
  return {
    ...state,
    fetchingPayment,
  };
}

function setPaymentData(state, { paymentData }) {
  return {
    ...state,
    paymentData,
  };
}

function setPaymentResponse(state, { paymentResponse }) {
  return {
    ...state,
    paymentResponse,
  };
}

function setAdditionalDetails(state, { additionalDetails }) {
  return {
    ...state,
    additionalDetails,
  };
}
