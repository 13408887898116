import React, { useCallback, useRef } from 'react';
import animatedScrollTo from 'animated-scroll-to';

const defaultConfig = { minDuration: 400, verticalOffset: -100 };

export default function withAnimatedScrollTo(config = defaultConfig) {
  return function (WrappedComponent) {
    let refs = [];

    return function (props) {
      //For single component scroll
      const componentRef = useRef(null);

      //For multiple components scroll
      const componentsRefs = useCallback((node) => {
        if (node && node.node) {
          if (node.error === 'error') {
            refs.push(node);
          } else {
            refs = refs.filter((ref) => ref.node !== node.node);
          }
        }
      }, []);

      function handleOnScrollToComponent(type) {
        const scroll = type && type === 'multiple' ? refs.length && refs[0].node : componentRef.current;

        if (scroll) {
          // You can pass an element to which you want to scroll to, that way it will scroll both horizontally and vertically...
          const scrollTop = window.scrollY || document.documentElement.scrollTop;
          const scrollTo = scrollTop + scroll.getBoundingClientRect().top - 50;
          animatedScrollTo(scrollTo, config);
        }
      }

      return (
        <WrappedComponent
          {...props}
          componentRef={componentRef}
          componentsRefs={componentsRefs}
          scrollToComponent={handleOnScrollToComponent}
        />
      );
    };
  };
}
