import axios from 'axios';
import axiosRetry from 'axios-retry';
import axis from 'axis.js';
import axiosConfig from './configs/axios.config';
import axiosRetryConfig from './configs/axiosRetry.config';
import { onFullfilledResponse as retryOnFullfilledResponse } from './interceptors/retry.interceptor';

// Custom axios instance
const instance = axios.create(axiosConfig);

// Interceptors
instance.interceptors.response.use(retryOnFullfilledResponse, null);
axiosRetry(instance, axiosRetryConfig);

export default instance;

export function updateBaseURL(schema, args, hostnameData) {
  if (axis.isFunction(schema)) {
    const baseHostname = instance.defaults.baseURL.replace('https://', '');
    const newHostname = schema(baseHostname, args);

    instance.defaults.baseURL = `https://${newHostname}`;
  } else if (hostnameData) {
    instance.defaults.baseURL = `https://${hostnameData.hostname}`;
    instance.defaults.params = {
      frontDomain: hostnameData.frontDomain,
      countryCode: hostnameData.countryCode,
    };
  }
}

export function getHttpClientBaseUrl() {
  return instance.defaults.baseURL;
}
