import * as types from './types';

export function setInputsHidden(inputsHidden) {
  return {
    type: types.SET_INPUTS_HIDDEN,
    payload: {
      inputsHidden,
    },
  };
}

export function toggleInputsHiddenOnMobile() {
  return {
    type: types.TOGGLE_INPUTS_HIDDEN_ON_MOBILE,
  };
}

export function resetInputsHidden() {
  return {
    type: types.RESET_INPUTS_HIDDEN,
  };
}

export function resetInputsHiddenOnMobile() {
  return {
    type: types.RESET_INPUTS_HIDDEN_ON_MOBILE,
  };
}
