import push from 'utils/immutable/push';
import removeAtIndex from 'utils/immutable/removeAtIndex';
import replaceAtIndex from 'utils/immutable/replaceAtIndex';
import * as types from './types';

const initialState = {
  selectedHotels: null,
  selectedRooms: null,
  selectedFlights: null,
  selectedFlightsExtras: null,
  selectedOtherExtras: null,
  currentlyOpenSummaries: [],
};

function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.SET_STATE:
      return payload.state;

    case types.SET_SELECTED_HOTELS:
      return setSelectedHotels(state, payload);

    case types.SET_SELECTED_ROOMS:
      return setSelectedRooms(state, payload);

    case types.UPDATE_SELECTED_ROOMS:
      return updateSelectedRooms(state, payload);

    case types.SET_SELECTED_FLIGHTS:
      return setSelectedFlights(state, payload);

    case types.SET_SELECTED_FLIGHTS_EXTRAS:
      return setSelectedFlightsExtras(state, payload);

    case types.UPDATE_SELECTED_FLIGHTS_EXTRAS:
      return updateSelectedFlightsExtras(state, payload);

    case types.SET_SELECTED_OTHER_EXTRAS:
      return setSelectedOtherExtras(state, payload);

    case types.SET_SELECTED_TRANSFER:
      return setSelectedTransfer(state, payload);

    case types.SET_SELECTED_VEHICLE_RENTALS:
      return setSelectedVehicleRentals(state, payload);

    case types.UPDATE_SELECTED_VEHICLE_RENTALS:
      return updateSelectedVehicleRentals(state, payload);

    case types.SET_SELECTED_INSURANCE:
      return setSelectedInsurance(state, payload);

    case types.SET_SELECTED_CO2COMPENSATION:
      return setSelectedCO2Compensation(state, payload);

    case types.SET_SELECTED_EARTH_TODAY:
      return setSelectedEarthToday(state, payload);

    case types.SET_SELECTED_CANCELLATION_PROTECTION:
      return setSelectedCancellationProtection(state, payload);

    case types.SET_CURRENTLY_OPEN_SUMMARIES:
      return setCurrentlyOpenSummaries(state, payload);

    case types.UPDATE_CURRENTLY_OPEN_SUMMARIES:
      return updateCurrentlyOpenSummaries(state, payload);

    case types.RESET_STATE:
      return initialState;

    default:
      return state;
  }
}

export default reducer;

function setSelectedHotels(state, { selectedHotels }) {
  return {
    ...state,
    selectedHotels,
  };
}

function setSelectedRooms(state, { selectedRooms }) {
  return {
    ...state,
    selectedRooms,
  };
}

function updateSelectedRooms(state, { roomIndex, selectedRoom }) {
  return {
    ...state,
    selectedRooms: replaceAtIndex(state.selectedRooms, roomIndex, selectedRoom),
  };
}

function setSelectedFlights(state, { selectedFlights }) {
  return {
    ...state,
    selectedFlights,
  };
}

function setSelectedFlightsExtras(state, { selectedFlightsExtras }) {
  return {
    ...state,
    selectedFlightsExtras,
  };
}

function updateSelectedFlightsExtras(state, { data: { checked, upgradeToken }, passengerType, passengerIndex }) {
  const passengerArray =
    passengerIndex !== undefined
      ? (state.selectedFlightsExtras[passengerType] && state.selectedFlightsExtras[passengerType][passengerIndex]) || []
      : state.selectedFlightsExtras[passengerType] || [];
  const newUpgradesArray = checked
    ? push(passengerArray, upgradeToken)
    : removeAtIndex(passengerArray, passengerArray.indexOf(upgradeToken));
  return {
    ...state,
    selectedFlightsExtras: {
      ...state.selectedFlightsExtras,
      [passengerType]:
        passengerIndex !== undefined
          ? {
              ...state.selectedFlightsExtras[passengerType],
              [passengerIndex]: newUpgradesArray,
            }
          : newUpgradesArray,
    },
  };
}

function setSelectedOtherExtras(state, { selectedOtherExtras }) {
  return {
    ...state,
    selectedOtherExtras,
  };
}

function setSelectedTransfer(state, { transfer }) {
  return {
    ...state,
    selectedOtherExtras: {
      ...state.selectedOtherExtras,
      transfer,
    },
  };
}

function setSelectedVehicleRentals(state, { vehicleRentals }) {
  return {
    ...state,
    selectedOtherExtras: {
      ...state.selectedOtherExtras,
      vehicleRentals,
    },
  };
}

function updateSelectedVehicleRentals(state, { checked, searchToken, productToken }) {
  const findByProductToken = getFindByProductTokenCallback(productToken);
  const _vehicleRentals =
    state.selectedOtherExtras.vehicleRentals !== null ? state.selectedOtherExtras.vehicleRentals : [];
  const vehicleRentals = checked
    ? push(_vehicleRentals, { searchToken, productToken })
    : removeAtIndex(_vehicleRentals, _vehicleRentals.findIndex(findByProductToken));

  // When removed it will allow for multiple vehicle rental selections.
  if (vehicleRentals.length > 1) {
    vehicleRentals.shift();
  }

  return {
    ...state,
    selectedOtherExtras: {
      ...state.selectedOtherExtras,
      vehicleRentals,
    },
  };
}

function setSelectedInsurance(state, { insurance }) {
  return {
    ...state,
    selectedOtherExtras: {
      ...state.selectedOtherExtras,
      insurance,
    },
  };
}

function setSelectedCO2Compensation(state, { co2Compensation }) {
  return {
    ...state,
    selectedOtherExtras: {
      ...state.selectedOtherExtras,
      co2Compensation,
    },
  };
}

function setSelectedEarthToday(state, { earthToday }) {
  return {
    ...state,
    selectedOtherExtras: {
      ...state.selectedOtherExtras,
      earthToday,
    },
  };
}

function setSelectedCancellationProtection(state, { cancellationProtection }) {
  return {
    ...state,
    selectedOtherExtras: {
      ...state.selectedOtherExtras,
      cancellationProtection,
    },
  };
}

function setCurrentlyOpenSummaries(state, { currentlyOpenSummaries }) {
  return {
    ...state,
    currentlyOpenSummaries,
  };
}

function updateCurrentlyOpenSummaries(state, { summaryKey, collapsed }) {
  const { currentlyOpenSummaries: currentCurrentlyOpenSummaries } = state;
  let currentlyOpenSummaries = [];

  if (collapsed) {
    currentlyOpenSummaries = currentCurrentlyOpenSummaries.filter(({ summaryType, id }) => {
      return summaryType !== summaryKey.summaryType || (summaryType === summaryKey.summaryType && id !== summaryKey.id);
    });
  } else {
    if (summaryKey.exclusive) {
      currentlyOpenSummaries = currentCurrentlyOpenSummaries.filter(
        ({ summaryType }) => summaryType !== summaryKey.summaryType
      );
      currentlyOpenSummaries.push(summaryKey);
    } else {
      currentlyOpenSummaries = [...currentCurrentlyOpenSummaries, summaryKey];
    }
  }

  return {
    ...state,
    currentlyOpenSummaries,
  };
}

// Utility
function getFindByProductTokenCallback(productToken) {
  return function findByProductToken(vehicleRental) {
    return vehicleRental.productToken === productToken;
  };
}
