import React from 'react';
import styled from 'styled-components';

function OverlayComponent({ severity, children }) {
  return <Root severity={severity}>{children}</Root>;
}

export default OverlayComponent;

const Root = styled.div`
  padding: 10px;
  background-color: ${({ severity, theme }) => theme.colors.background.severity[severity]};
  border: none;
  border-radius: 2px;
  font-size: 1.4em;
  text-align: center;
  color: ${({ theme }) => theme.colors.text.white};
`;
