import push from 'utils/immutable/push';
import * as types from './types';

const initialState = {
  count: 0,
  packages: [],
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case types.ADD_PACKAGE:
      return addPackage(state, action.payload);

    case types.REMOVE_PACKAGE:
      return removePackage(state, action.payload);

    case types.SET_PACKAGES:
      return setPackages(state, action.payload);

    default:
      return state;
  }
}

export default reducer;

// Private functions
function addPackage(state, { packet }) {
  const _packages = push(state.packages, packet);

  return {
    ...state,
    count: _packages.length,
    packages: _packages,
  };
}

function removePackage(state, { packageUniqueKey }) {
  const _packages = state.packages.filter((packet) => packet.packageUniqueKey !== packageUniqueKey);

  return {
    ...state,
    count: _packages.length,
    packages: _packages,
  };
}

function setPackages(state, payload) {
  return {
    ...state,
    count: payload.packages.length,
    packages: payload.packages,
  };
}
