import copy from 'utils/immutable/copy';
import * as types from './types';

const initialState = {
  fetching: null,
  booking: null,
  value: null,
  filteredDepartures: [],
  filteredDeparturesTimeStamp: null,
  selectedDepartureId: null,
};

function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.SET_STATE:
      return payload.state;

    case types.SET_FETCHING:
      return setFetching(state, payload);

    case types.SET_BOOKING:
      return setBooking(state, payload);

    case types.SET_DEAL:
      return setDeal(state, payload);

    case types.SET_FILTERED_DEPARTURES:
      return setFilteredDepartures(state, payload);

    case types.SET_SELECTED_DEPARTURE_ID:
      return setSelectedDepartureId(state, payload);

    case types.RESET_STATE:
      return initialState;

    default:
      return state;
  }
}

export default reducer;

function setFetching(state, { fetching }) {
  return {
    ...state,
    fetching,
  };
}

function setBooking(state, { booking }) {
  return {
    ...state,
    booking,
  };
}

function setDeal(state, { value }) {
  return {
    ...state,
    value,
    filteredDepartures: copy(value.departures),
    filteredDeparturesTimeStamp: Date.now(),
  };
}

function setFilteredDepartures(state, { filteredDepartures }) {
  return {
    ...state,
    filteredDepartures,
    filteredDeparturesTimeStamp: Date.now(),
  };
}

function setSelectedDepartureId(state, { selectedDepartureId }) {
  return {
    ...state,
    selectedDepartureId,
  };
}
