import * as types from './types';

const initialState = {
  translationsLoaded: null,
};

function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.SET_STATE:
      return payload.state;

    case types.SET_FLAG:
      return setFlag(state, payload);

    case types.RESET_STATE:
      return initialState;

    default:
      return state;
  }
}

export default reducer;

function setFlag(state, { flag, value }) {
  return {
    ...state,
    [flag]: value,
  };
}
