import * as types from './types';

const initialState = {
  results: [],
  hotels: {},
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_SEARCH_RESULTS:
      return setLastMinuteSearchResults(state, action.payload);

    case types.UPDATE_HOTELS_INFO:
      return updateLastMinuteHotelsInfo(state, action.payload);

    default:
      return state;
  }
}

export default reducer;

function setLastMinuteSearchResults(state, { results, hotels }) {
  return {
    ...state,
    results: results,
    hotels: hotels,
  };
}
function updateLastMinuteHotelsInfo(state, { hotels }) {
  hotels.forEach((hotel) => {
    state.hotels[hotel.id] = {
      ...hotel,
      package: state.hotels[hotel.id].package,
    };
  });

  return {
    ...state,
    hotels: state.hotels,
  };
}
