import * as types from './types';

export function setState(state) {
  return {
    type: types.SET_STATE,
    payload: {
      state,
    },
  };
}

export function requestInsurances(fetching) {
  return {
    type: types.REQUEST_INSURANCES,
    payload: {
      fetching,
    },
  };
}

export function setInsurances(insurances) {
  return {
    type: types.SET_INSURANCES,
    payload: {
      insurances,
    },
  };
}

export function setCurrentTokens(currentTokens) {
  return {
    type: types.SET_CURRENT_TOKENS,
    payload: {
      currentTokens,
    },
  };
}

export function setValidity(validity) {
  return {
    type: types.SET_VALIDITY,
    payload: {
      validity,
    },
  };
}

export function resetInsurances() {
  return {
    type: types.RESET_INSURANCES,
  };
}
