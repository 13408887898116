import _orderBy from 'lodash/orderBy';
import * as types from './types';

const initialState = {
  fetching: false,
  transfers: null,
  validity: {
    valid: null,
    message: null,
  },
  currentTokens: null,
};

function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.SET_STATE:
      return payload.state;

    case types.REQUEST_TRANSFERS:
      return requestTransfers(state, payload);

    case types.SET_TRANSFERS:
      return setTransfers(state, payload);

    case types.SET_CURRENT_TOKENS:
      return setCurrentTokens(state, payload);

    case types.SET_VALIDITY:
      return setValidity(state, payload);

    case types.RESET_TRANSFERS:
      return initialState;

    default:
      return state;
  }
}

export default reducer;

function requestTransfers(state, { fetching }) {
  return {
    ...state,
    fetching,
  };
}

function setTransfers(state, { transfers }) {
  return {
    ...state,
    fetching: false,
    transfers: _orderBy(transfers, 'prices.perPerson.amount', 'asc'),
  };
}

function setCurrentTokens(state, { currentTokens }) {
  return {
    ...state,
    currentTokens,
  };
}

function setValidity(state, { validity }) {
  return {
    ...state,
    validity,
  };
}
