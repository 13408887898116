export default function roomConfigurationsParser(rooms) {
  const roomConfigurations = [];

  rooms &&
    rooms.forEach &&
    rooms.forEach((room) => {
      if (room) {
        const roomConfig = room.endsWith('-') ? room.slice(0, -1) : room;
        const occupants = roomConfig.split('-');
        const numberOfAdults = occupants.splice(0, 1);
        roomConfigurations.push({
          numberOfAdults: parseInt(numberOfAdults[0]),
          numberOfChildren: occupants.length,
          childrenAges: occupants.map((age) => parseInt(age)),
        });
      }
    });

  roomConfigurations.length === 0 &&
    roomConfigurations.push({
      numberOfAdults: 2,
      numberOfChildren: 0,
      childrenAges: [],
    });

  return roomConfigurations;
}
