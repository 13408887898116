import getSelectedHotelsRoomsWithSelectedOption from 'state/checkoutPage/packagesSearch/selectors/getSelectedHotelsRoomsWithSelectedOption';
import getSelectedFlightsCombinations from 'state/checkoutPage/flights/selectors/getSelectedFlightsCombinations';

export default function calculatePriceForHotelsAndFlights() {
  return (dispatch, getState) => {
    const { checkoutPagePackagesSearch } = getState();

    if (checkoutPagePackagesSearch.value === null) {
      return null;
    }

    const { packages } = checkoutPagePackagesSearch.value;
    const { total: packageBasePrice } = packages[0].price;
    const selectedRooms = dispatch(getSelectedHotelsRoomsWithSelectedOption());
    const selectedFlightsCombinations = dispatch(getSelectedFlightsCombinations());

    let totalPrice = selectedRooms.map(getSelectedRoomUpgradePrice).reduce(getSumOfPrices, packageBasePrice);

    if (selectedFlightsCombinations && selectedFlightsCombinations.length > 0) {
      totalPrice += selectedFlightsCombinations.map(getSelectedFlightsCombinationsPrice).reduce(getSumOfPrices);
    }

    return totalPrice;
  };
}

function getSelectedRoomUpgradePrice(selectedRoom) {
  return selectedRoom.selectedOption.upgradePrice.total;
}

function getSelectedFlightsCombinationsPrice(flightCombination) {
  return flightCombination.prices.total;
}

function getSumOfPrices(accumulator, current) {
  return accumulator + current;
}
