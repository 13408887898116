export default function getNumberOfBars() {
  // numberOfBarsToFillScreen = (windowWidth / widthOfSingleBar) + 15 bars for scroll
  let numberOfBarsToFillScreen = Math.ceil(window.innerWidth / 20) + 15;

  // for small devices we don't want to return very small number of bars
  if (numberOfBarsToFillScreen < 60) {
    numberOfBarsToFillScreen = 60;
  }

  // we want number divisible by 2
  if (numberOfBarsToFillScreen % 2 !== 0) {
    numberOfBarsToFillScreen += 1;
  }

  return numberOfBarsToFillScreen;
}
