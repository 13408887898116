export default function getFlightsCombinationByProductToken(productToken) {
  return (dispatch, getState) => {
    const { flights } = getState().checkoutPageFlights;

    if (flights === null) {
      return null;
    }

    return flights.find((flight) => flight.productToken === productToken);
  };
}
