export default function getPackageInfoKey(packageInfo) {
  if (packageInfo === null || packageInfo === undefined) {
    return null;
  }

  const { origin, dates } = packageInfo.search;

  if (origin === null && dates.from === null && dates.to === null) {
    return null;
  }

  const datesFrom = dates.from !== null ? dates.from.replace(/-/g, '') : '';
  const datesTo = dates.to !== null ? dates.to.replace(/-/g, '') : '';

  return `${origin}${datesFrom}${datesTo}`;
}
