import moment from 'moment';
import * as types from './types';

const initialState = {
  floatingPackagePrice: {
    clicked: null,
    visible: true,
    style: {
      icon: 'chevron-down',
      topPosition: 'auto',
    },
  },
  transitionToCheckout: false,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_STATE:
      return action.payload.state;

    case types.SET_FLOATING_PACKAGE_PRICE_CLICKED:
      return setFloatingPackagePriceClicked(state);

    case types.SET_FLOATING_PACKAGE_PRICE_VISIBILITY:
      return setFloatingPackagePriceVisibility(state, action.payload);

    case types.SET_TRANSITION_TO_CHECKOUT:
      return setTransitionToCheckout(state, action.payload);

    case types.SET_FLOATING_PACKAGE_PRICE_STYLE:
      return setFloatingPackagePriceStyle(state, action.payload);

    default:
      return state;
  }
}

export default reducer;

function setFloatingPackagePriceClicked(state) {
  return {
    ...state,
    floatingPackagePrice: {
      clicked: moment().unix(),
      visible: false,
      style: state.floatingPackagePrice.style, 
    },
  };
}

function setFloatingPackagePriceVisibility(state, { visible }) {
  return {
    ...state,
    floatingPackagePrice: {
      clicked: state.floatingPackagePrice.clicked,
      visible,
      style: state.floatingPackagePrice.style, 
    },
  };
}

function setFloatingPackagePriceStyle(state, { style }) {
  return {
    ...state,
    floatingPackagePrice: {
      clicked: state.floatingPackagePrice.clicked,
      visible: state.floatingPackagePrice.visible,
      style,
    },
    
  };
}

function setTransitionToCheckout(state, { transitionToCheckout }) {
  return {
    ...state,
    transitionToCheckout,
  };
}
