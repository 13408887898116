import moment from 'moment';
import immutableCopy from 'utils/immutable/copy';
import * as sortByValues from 'constants/sortByValues';
import { MAP } from 'constants/filterByTypes';
import * as types from './types';
import sortByPrice from './selectors/sortByPrice';
import sortByScore from './selectors/sortByScore';

const initialState = {
  results: [],
  filtered: [],
  filteredTimeStamp: null,
  sortByTimeStamp: null,
  hotels: {},
  isFetchingSearchResults: false,
  isFilteringSearchResults: false,
  showLoader: false,
  canHideLoader: true,
  showNoResultsFoundPage: false,
  scrollPackageIntoView: null,
  showInfoMessage: false,
  typeOfAction: null,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_IS_FETCHING_SEARCH_RESULTS:
      return setIsFetchingSearchResults(state, action.payload);

    case types.SET_IS_FILTERING_SEARCH_RESULTS:
      return setIsFilteringSearchResults(state, action.payload);

    case types.EVAL_SHOW_LOADER:
      return evalShowLoader(state);

    case types.SET_CAN_HIDE_LOADER:
      return setCanHideLoader(state, action.payload);

    case types.SET_SEARCH_RESULTS:
      return setSearchResults(state, action.payload);

    case types.UPDATE_HOTELS_INFO:
      return updateHotelsInfo(state, action.payload);

    case types.SCROLL_PACKAGE_INTO_VIEW:
      return scrollPackageIntoView(state, action.payload);

    case types.SORT_BY_SEARCH_RESULTS:
      return sortBySearchResult(state, action.payload);

    case types.FILTER_BY_SEARCH_RESULTS:
      return filterBySearchResults(state, action.payload);

    case types.FILTER_SEARCH_RESULTS:
      return filterSearchResults(state, action.payload);

    case types.SET_SHOW_NO_RESULTS_FOUND_PAGE:
      return setShowNoResultsFoundPage(state, action.payload);

    default:
      return state;
  }
}

export default reducer;

function setIsFetchingSearchResults(state, { fetching }) {
  return {
    ...state,
    isFetchingSearchResults: fetching,
  };
}

function setIsFilteringSearchResults(state, { filtering }) {
  return {
    ...state,
    isFilteringSearchResults: filtering,
  };
}

function evalShowLoader(state) {
  return {
    ...state,
    showLoader: state.isFetchingSearchResults || state.isFilteringSearchResults,
    canHideLoader: !state.isFetchingSearchResults || !state.isFilteringSearchResults,
  };
}

function setCanHideLoader(state, payload) {
  return {
    ...state,
    canHideLoader: payload.value,
    showLoader: !(payload.value && !state.isFetchingSearchResults && !state.isFilteringSearchResults),
  };
}

function setSearchResults(state, { results, hotels, showInfoMessage, sortBy }) {
  let filtered = immutableCopy(results);

  if (sortBy === sortByValues.SCORE) {
    filtered = filtered.sort(sortByScore);
  }

  return {
    ...state,
    results: results,
    filtered,
    filteredTimeStamp: moment().unix(),
    hotels: hotels,
    isFetchingSearchResults: false,
    isFilteringSearchResults: false,
    showLoader: !state.canHideLoader,
    showNoResultsFoundPage: results.length === 0,
    showInfoMessage: showInfoMessage,
  };
}

function updateHotelsInfo(state, { hotels }) {
  hotels.forEach((hotel) => {
    state.hotels[hotel.id] = {
      ...hotel,
      package: state.hotels[hotel.id].package,
    };
  });

  return {
    ...state,
    hotels: state.hotels,
  };
}

function scrollPackageIntoView(state, { hotelId }) {
  return {
    ...state,
    scrollPackageIntoView: `${hotelId}:${moment().unix()}`,
  };
}

function sortBySearchResult(state, { value }) {
  let sortedResults = [];

  if (value === sortByValues.PRICE) {
    sortedResults = [...state.filtered].sort(sortByPrice);
  } else if (value === sortByValues.SCORE) {
    sortedResults = [...state.filtered].sort(sortByScore);
  }

  return {
    ...state,
    filtered: sortedResults,
    sortByTimeStamp: moment().unix(),
  };
}

function filterBySearchResults(state, { type, value }) {
  let filtered = [];

  if (type === MAP) {
    if (value.length === 0) {
      filtered = [...state.results];
    } else {
      filtered = state.results.filter(({ hotelId }) => value.indexOf(hotelId) !== -1);
    }
  }

  return {
    ...state,
    filtered,
    filteredTimeStamp: moment().unix(),
  };
}

function filterSearchResults(state, { typeOfAction }) {
  return {
    ...state,
    typeOfAction: typeOfAction ? typeOfAction : null,
  };
}

function setShowNoResultsFoundPage(state, { value }) {
  return {
    ...state,
    showNoResultsFoundPage: value,
  };
}
