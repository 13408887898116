import trackEvent from '../utils/trackEvent';

export default function hotelPageRoomsViewed(eventName, { requestParameters, selectRooms, hotelInfo }) {
  const eventData = {
    content_type: 'hotel',
    checkin_date: requestParameters.departureDate,
    checkout_date: requestParameters.returnDate,
    content_ids: hotelInfo.id,
    city: hotelInfo.location.city,
    region: hotelInfo.location.city,
    country: hotelInfo.location.country,
    num_adults: selectRooms.rooms
      .map(({ numberOfAdults }) => numberOfAdults)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0), // Or 2
    num_children: selectRooms.rooms
      .map(({ childrenAges }) => childrenAges.length)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0), // Or 0
  };

  trackEvent(eventName, eventData);
}
