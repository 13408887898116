export const SET_CALENDAR_DATES = 'selectDates/SET_CALENDAR_DATES';
export const TOGGLE_FLEXIBLE_DATES = 'selectDates/TOGGLE_FLEXIBLE_DATES';
export const UPDATE_CALENDAR_HIGHLIGHTS = 'selectDates/UPDATE_CALENDAR_HIGHLIGHTS';
export const UPDATE_CALENDAR_HIGHLIGHTS_V2 = 'selectDates/UPDATE_CALENDAR_HIGHLIGHTS_V2';
export const UPDATE_CALENDAR_HIGHLIGHTS_V3 = 'selectDates/UPDATE_CALENDAR_HIGHLIGHTS_V3';
export const RESET_CALENDAR_HIGHLIGHTS = 'selectDates/RESET_CALENDAR_HIGHLIGHTS';
export const SET_CALENDAR_HIGHLIGHTS_FOR_REACT_DATES = 'selectDates/SET_CALENDAR_HIGHLIGHTS_FOR_REACT_DATES';
export const UPDATE_AIRLINE_CALENDAR_VALUES = 'selectDates/UPDATE_AIRLINE_CALENDAR_VALUES';
export const UPDATE_AIRLINE_CALENDAR_VALUES_V2 = 'selectDates/UPDATE_AIRLINE_CALENDAR_VALUES_V2';
export const SET_AIRLINE_CALENDAR_AVAILABLE_DATES = 'selectDates/SET_AIRLINE_CALENDAR_AVAILABLE_DATES';
export const SET_FLEXIBLE_DATES_DISABLED = 'selectDates/SET_FLEXIBLE_DATES_DISABLED';
export const RESET_SELECTED_DATES = 'selectDates/RESET_SELECTED_DATES';
export const RESET_CALENDAR_DATES = 'selectDates/RESET_CALENDAR_DATES';
export const SET_CALENDAR_OPTION = 'selectDates/SET_CALENDAR_OPTION';
