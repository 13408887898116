import no from 'static/airlines/norwegianholidays/images/flags/no.svg';
import se from 'static/airlines/norwegianholidays/images/flags/se.svg';
import dk from 'static/airlines/norwegianholidays/images/flags/dk.svg';
import fi from 'static/airlines/norwegianholidays/images/flags/fi.svg';
import uk from 'static/airlines/norwegianholidays/images/flags/uk.svg';
import eu from 'static/airlines/norwegianholidays/images/flags/eu.svg';

import mt from 'static/airlines/airmaltaholidays/images/flags/mt.svg';
import de from 'static/airlines/airmaltaholidays/images/flags/de.svg';
import fr from 'static/airlines/airmaltaholidays/images/flags/fr.svg';
import it from 'static/airlines/airmaltaholidays/images/flags/it.svg';
import uk2 from 'static/airlines/airmaltaholidays/images/flags/uk.svg';
import eu2 from 'static/airlines/airmaltaholidays/images/flags/eu.svg';

import gr from 'static/airlines/cyholidaydeals/images/flags/gr.svg';

import nl from 'static/whitelabels/hillman.travel/images/nl.svg';

const norwegianholidays = [
  { domain: 'no', locale: 'nb', countryName: 'Norge', icon: no },
  { domain: 'se', locale: 'sv', countryName: 'Sverige', icon: se },
  { domain: 'dk', locale: 'da', countryName: 'Denmark', icon: dk },
  { domain: 'fi', locale: 'fi', countryName: 'Suomi', icon: fi },
  { domain: 'uk', locale: 'en', countryName: 'United Kingdom', icon: uk },
  { domain: 'eu', locale: 'en', countryName: 'Other countries', icon: eu },
];

const airmaltaholiday = [
  { domain: 'mt', locale: 'mt', countryName: 'Malta', icon: mt },
  { domain: 'de', locale: 'de', countryName: 'Deutschland', icon: de },
  { domain: 'fr', locale: 'fr', countryName: 'France', icon: fr },
  { domain: 'it', locale: 'it', countryName: 'Italia', icon: it },
  { domain: 'uk', locale: 'en', countryName: 'United Kingdom', icon: uk2 },
  { domain: 'eu', locale: 'en', countryName: 'Other countries', icon: eu2 },
];

const airbalticholidays = [
  { domain: 'eu', locale: 'en', countryName: 'Other countries', icon: eu2 },
  { domain: 'fi', locale: 'fi', countryName: 'Suomi', icon: fi },
  { domain: 'no', locale: 'nb', countryName: 'Norge', icon: no },
  { domain: 'se', locale: 'sv', countryName: 'Sverige', icon: se },
  { domain: 'dk', locale: 'da', countryName: 'Denmark', icon: dk },
];

const cyprusairways = [
  { domain: 'el', locale: 'el', countryName: 'Greece', icon: gr },
  { domain: 'eu', locale: 'en', countryName: 'Other countries', icon: eu2 },
];

const hillman = [
  { domain: 'nl', locale: 'nl', countryName: 'Netherlands', icon: nl },
  { domain: 'eu', locale: 'en', countryName: 'Other countries', icon: eu2 },
  { domain: 'fr', locale: 'fr', countryName: 'France', icon: fr },
];

export default {
  norwegianholidays,
  airmaltaholiday,
  airbalticholidays,
  cyprusairways,
  hillman,
};
