import getSelectedExtras from './getSelectedExtras';

export default function getFlightExtrasProductsForGTM() {
  return (dispatch) => {
    const selectedFlightsExtras = dispatch(getSelectedExtras());

    if (!selectedFlightsExtras) {
      return [];
    }

    const { Leave = [], Return = [], Both = [] } = selectedFlightsExtras;
    const upgradesArray = [...Leave, ...Return, ...Both];

    return upgradesArray.map((upgrade) => ({
      name: `${upgrade.direction}-${upgrade.type}`,
      price: upgrade.price,
    }));
  };
}
