import * as types from './types';

export function setFiltersData(data) {
  return {
    type: types.SET_FILTERS_DATA,
    payload: {
      data,
    },
  };
}

export function setNumberOfDaysValue(value, keepPristine) {
  return {
    type: types.SET_NUMBER_OF_DAYS_VALUE,
    payload: {
      value,
      keepPristine,
    },
  };
}

export function setHotelScore(hotelScore) {
  return {
    type: types.SET_HOTEL_SCORE,
    payload: {
      hotelScore,
    },
  };
}

export function setHotelScoreReset(reset) {
  return {
    type: types.SET_HOTEL_SCORE_RESET,
    payload: {
      reset,
    },
  };
}

export function setHotelStars(hotelStars) {
  return {
    type: types.SET_HOTEL_STARS,
    payload: {
      hotelStars,
    },
  };
}

export function setPriceRange(priceRange) {
  return {
    type: types.SET_PRICE_RANGE,
    payload: {
      priceRange,
    },
  };
}

export function setPriceRangeValue(value, keepPristine) {
  return {
    type: types.SET_PRICE_RANGE_VALUE,
    payload: {
      value,
      keepPristine,
    },
  };
}

export function setBoardType(boardType) {
  return {
    type: types.SET_BOARD_TYPE,
    payload: {
      boardType,
    },
  };
}

export function setSelectedHotelThemes(selectedHotelThemes) {
  return {
    type: types.SET_SELECTED_HOTEL_THEMES,
    payload: {
      selectedHotelThemes,
    },
  };
}

export function setSelectedHotelFacilities(selectedHotelFacilities) {
  return {
    type: types.SET_SELECTED_HOTEL_FACILITIES,
    payload: {
      selectedHotelFacilities,
    },
  };
}

export function setSelectedHotelCities(selectedHotelCities) {
  return {
    type: types.SET_SELECTED_HOTEL_CITIES,
    payload: {
      selectedHotelCities,
    },
  };
}

export function setSortBy(sortBy) {
  return {
    type: types.SET_SORT_BY,
    payload: {
      sortBy,
    },
  };
}

export function setDisplayMap(value) {
  return {
    type: types.SET_DISPLAY_MAP,
    payload: {
      value,
    },
  };
}

export function resetSearchResultsFilters() {
  return {
    type: types.RESET_SEARCH_RESULTS_FILTERS,
  };
}
