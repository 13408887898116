import updateObject from 'utils/immutable/updateObject';
import concat from 'utils/immutable/concat';
import * as types from './types';
import getInitialVisibleMonth from './selectors/getInitialVisibleMonth';
import * as calendarOptions from 'components/inputs/SelectDates/constants/calendarSwitch';

const initialState = {
  dates: {
    from: null,
    to: null,
    flexibleDates: false,
  },
  calendarHighlights: {},
  calendarHighlightsForReactDates: {},
  airlineCalendar: {
    values: {},
    availableDates: {},
    highlights: {},
  },
  initialVisibleMonth: {
    leave: null,
    return: null,
  },
  flexibleDatesDisabled: false,
  calendarOption: calendarOptions.SPECIFIC_DATES_CALENDAR,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_CALENDAR_DATES:
      return setCalendarDates(state, action.payload);

    case types.TOGGLE_FLEXIBLE_DATES:
      return toggleFlexibleDates(state);

    case types.UPDATE_CALENDAR_HIGHLIGHTS:
      return updateCalendarHighlights(state, action.payload);

    case types.UPDATE_CALENDAR_HIGHLIGHTS_V2:
      return updateCalendarHighlightsV2(state, action.payload);

    case types.UPDATE_CALENDAR_HIGHLIGHTS_V3:
      return updateCalendarHighlightsV3(state, action.payload);

    case types.RESET_CALENDAR_HIGHLIGHTS:
      return resetCalendarHighlights(state);

    case types.SET_CALENDAR_HIGHLIGHTS_FOR_REACT_DATES:
      return setCalendarHighlightsForReactDates(state, action.payload);

    case types.UPDATE_AIRLINE_CALENDAR_VALUES:
      return updateAirlineCalendarValues(state, action.payload);

    case types.UPDATE_AIRLINE_CALENDAR_VALUES_V2:
      return updateAirlineCalendarValuesV2(state, action.payload);

    case types.SET_AIRLINE_CALENDAR_AVAILABLE_DATES:
      return setAirlineCalendarAvailableDates(state, action.payload);

    case types.SET_FLEXIBLE_DATES_DISABLED:
      return setFlexibleDatesDisabled(state, action.payload);

    case types.SET_CALENDAR_OPTION:
      return setCalendarOption(state, action.payload);

    case types.RESET_SELECTED_DATES:
      return resetSelectedDates(state);

    case types.RESET_CALENDAR_DATES:
      return resetCalendarDates();

    default:
      return state;
  }
}

export default reducer;

function setCalendarDates(state, payload) {
  return {
    ...state,
    dates: updateObject(state.dates, {
      from: payload.calendarDates.startDate !== undefined ? payload.calendarDates.startDate : state.dates.from,
      to: payload.calendarDates.endDate !== undefined ? payload.calendarDates.endDate : state.dates.to,
      flexibleDates:
        payload.calendarDates.flexibleDates !== undefined
          ? payload.calendarDates.flexibleDates
          : state.dates.flexibleDates,
    }),
  };
}

function toggleFlexibleDates(state) {
  return {
    ...state,
    dates: updateObject(state.dates, {
      flexibleDates: !state.dates.flexibleDates,
    }),
  };
}

function updateCalendarHighlights(state, payload) {
  return {
    ...state,
    calendarHighlights: updateObject(state.calendarHighlights, payload.calendarHighlights),
  };
}

function updateCalendarHighlightsV2(state, { originCode, highlights }) {
  const highlightsForOrigin = state.calendarHighlights[originCode]
    ? concat(state.calendarHighlights[originCode], highlights)
    : highlights;

  return {
    ...state,
    calendarHighlights: {
      ...state.calendarHighlights,
      [originCode]: highlightsForOrigin,
    },
  };
}

function updateCalendarHighlightsV3(state, { originCode, highlights }) {
  return {
    ...state,
    calendarHighlights: {
      ...state.calendarHighlights,
      highlights,
    },
  };
}

function resetCalendarHighlights(state) {
  return {
    ...state,
    calendarHighlights: initialState.calendarHighlights,
    calendarHighlightsForReactDates: initialState.calendarHighlightsForReactDates,
    initialVisibleMonth: initialState.initialVisibleMonth,
  };
}

function setCalendarHighlightsForReactDates(state, { calendarHighlightsForReactDates, initialVisibleMonth }) {
  return {
    ...state,
    calendarHighlightsForReactDates,
    initialVisibleMonth: initialVisibleMonth ? initialVisibleMonth : state.initialVisibleMonth,
  };
}

function updateAirlineCalendarValues(state, { airlineCalendarValues }) {
  return {
    ...state,
    airlineCalendar: {
      ...state.airlineCalendar,
      values: updateObject(state.airlineCalendar.values, airlineCalendarValues),
    },
  };
}

function updateAirlineCalendarValuesV2(state, { originCode, values }) {
  const valuesForOrigin = state.airlineCalendar.values[originCode]
    ? concat(state.airlineCalendar.values[originCode], values)
    : values;

  return {
    ...state,
    airlineCalendar: {
      ...state.airlineCalendar,
      values: {
        ...state.airlineCalendar.values,
        [originCode]: valuesForOrigin,
      },
    },
  };
}

function setAirlineCalendarAvailableDates(state, { availableDates }) {
  const initialVisibleMonth = getInitialVisibleMonth(availableDates);

  return {
    ...state,
    initialVisibleMonth,
    airlineCalendar: {
      ...state.airlineCalendar,
      availableDates,
    },
  };
}

function setFlexibleDatesDisabled(state, { flexibleDatesDisabled }) {
  return {
    ...state,
    flexibleDatesDisabled,
    dates: updateObject(state.dates, {
      flexibleDates: !flexibleDatesDisabled,
    }),
  };
}

function setCalendarOption(state, { option }) {
  return {
    ...state,
    calendarOption: option,
  };
}

function resetSelectedDates(state) {
  return {
    ...state,
    dates: initialState.dates,
  };
}

function resetCalendarDates() {
  return {
    ...initialState,
  };
}
