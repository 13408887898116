import { parsePhoneNumberFromString } from 'libphonenumber-js';
import moment from 'moment';
import axis from 'axis.js';
import * as inputNames from 'state/checkoutPage/customerDetails/constants/inputNames';
import isStringWithoutNumbersOrSpecialCharacters from 'validators/isStringWithoutNumbersOrSpecialCharacters';
import isStringWithoutNumbersOrSpacesOrSpecialCharacters from 'validators/isStringWithoutNumbersOrSpacesOrSpecialCharacters';
import isEmail from 'validators/isEmail';
import { firstNamePool, lastNamePool, genderPool } from 'constants/dummy';
import generateRandomString from 'utils/string/generateRandomString';
import isStringOnlyWithLatinLetters from 'validators/isStringOnlyWithLatinLetters';
import { enforceLattinLettersMessage } from 'constants/validationMessages';
import isStringOnlyWithLatinLettersAndNumbers from 'validators/isStringWithLatinLettersAndNumbers';

const date = new Date();
const currentYear = date.getFullYear();

/**
 *
 * @param {string} name - Name of the input value.
 * @param {*} value - Input field value.
 * @param {object} data - Additional data required for validation.
 * @param {boolean} required - Indicates if it should validate empty values.
 * @returns {boolean} - Returns true if the value is valid, otherwise false.
 */
export function validateInputValue(name, value, data, required) {
  let valid;
  if (required && !value) {
    return {
      valid: false,
      message: {
        id: 'missingValue.tooltip',
        description: 'Please fill in this field',
        defaultMessage: 'Please fill in this field',
      },
      clearOnFocus: true,
    };
  }

  switch (name) {
    case inputNames.FIRST_NAME:
      valid = isStringWithoutNumbersOrSpecialCharacters(value);
      if (!valid) {
        return {
          valid,
          message: !valid && {
            id: 'firstNameInput.invalid.tooltip',
            description: "Names can't contain numbers or special characters",
            defaultMessage: "Names can't contain numbers or special characters",
          },
        };
      }
      if (data && data.shouldForceLatinLetters) {
        valid = isStringOnlyWithLatinLetters(value);
        if (!valid) {
          return {
            valid,
            message: enforceLattinLettersMessage,
          };
        }
      }
      if (value) {
        valid = value !== data[inputNames.LAST_NAME];
        if (!valid) {
          return {
            valid,
            message: !valid && {
              id: 'firstNameLastNameIdentical',
              description: 'First name and last name cannot be identical.',
              defaultMessage: 'First name and last name cannot be identical.',
            },
          };
        }
      }

      valid = value.length <= 20;
      return {
        valid,
        message: !valid
          ? {
              id: 'nameInputToLong.invalid.tooltip',
              description: "Names can't exceed {number} characters",
              defaultMessage: "Names can't exceed {number} characters",
              values: { number: 20 },
            }
          : null,
      };

    case inputNames.LAST_NAME:
      valid = isStringWithoutNumbersOrSpecialCharacters(value);
      if (!valid) {
        return {
          valid,
          message: !valid && {
            id: 'lastNameInput.invalid.tooltip',
            description: "Names can't contain numbers or special characters",
            defaultMessage: "Names can't contain numbers or special characters",
          },
        };
      }
      if (data && data.shouldForceLatinLetters) {
        valid = isStringOnlyWithLatinLetters(value);
        if (!valid) {
          return {
            valid,
            message: enforceLattinLettersMessage,
          };
        }
      }

      if (value) {
        valid = value !== data[inputNames.FIRST_NAME];
        if (!valid) {
          return {
            valid,
            message: !valid && {
              id: 'firstNameLastNameIdentical',
              description: 'First name and last name cannot be identical.',
              defaultMessage: 'First name and last name cannot be identical.',
            },
          };
        }
      }

      valid = value.length <= 20;
      return {
        valid,
        message: !valid
          ? {
              id: 'nameInputToLong.invalid.tooltip',
              description: "Names can't exceed {number} characters",
              defaultMessage: "Names can't exceed {number} characters",
              values: { number: 20 },
            }
          : null,
      };

    case inputNames.EMAIL:
      valid = value ? isEmail(value) : true;
      return {
        valid,
        message: !valid && {
          id: 'emailInput.invalid.tooltip',
          description: 'Please enter a valid email',
          defaultMessage: 'Please enter a valid email',
        },
      };

    case inputNames.VERIFY_EMAIL:
      valid = value === data[inputNames.EMAIL];
      return {
        valid,
        message: !valid && {
          id: 'verifyEmailInput.invalid.tooltip',
          description: "Email doesn't match",
          defaultMessage: "Email doesn't match",
        },
      };

    case inputNames.CITY:
      valid = isStringWithoutNumbersOrSpecialCharacters(value);
      if (!valid) {
        return {
          valid,
          message: !valid && {
            id: 'cityInput.invalid.tooltip',
            description: "City names can't contain numbers or special characters",
            defaultMessage: "City names can't contain numbers or special characters",
          },
        };
      }
      if (data && data.shouldForceLatinLetters) {
        valid = isStringOnlyWithLatinLetters(value);
        if (!valid) {
          return {
            valid,
            message: enforceLattinLettersMessage,
          };
        }
      }
      valid = value !== data[inputNames.ADDRESS];
      return {
        valid,
        message: !valid && {
          id: 'verifyAddressInput.invalid.tooltip',
          description: 'City cannot be same as address',
          defaultMessage: 'City cannot be same as address',
        },
      };

    case inputNames.ADDRESS:
      valid = value !== data[inputNames.CITY];
      if (!valid) {
        return {
          valid,
          message: !valid && {
            id: 'verifyAddressInput.invalid.tooltip',
            description: 'City cannot be same as address',
            defaultMessage: 'City cannot be same as address',
          },
        };
      }
      if (data && data.shouldForceLatinLetters) {
        valid = isStringOnlyWithLatinLetters(value);
        return {
          valid,
          message: enforceLattinLettersMessage,
        };
      }
      return {
        valid,
        message: '',
      };

    case inputNames.DATE_OF_BIRTH:
      valid = value && value.isValid && value.isValid();
      return {
        valid,
        message: !valid && {
          id: 'yearInput.invalid.tooltip',
          description: `Year should be a number between 1900 and ${currentYear}`,
          defaultMessage: `Year should be a number between 1900 and ${currentYear}`,
        },
      };

    case inputNames.PHONE:
      const formattedPhoneNumberValue = formatPhoneNumberValue(value, data[inputNames.COUNTRY_CODE].countryCode);
      valid = formattedPhoneNumberValue ? formattedPhoneNumberValue.isValid() : false;
      return {
        valid,
        message: !valid && {
          id: 'phoneNumber.invalid.tooltip',
          description: 'Phone number format is not valid for selected country.',
          defaultMessage: 'Phone number format is not valid for selected country.',
        },
      };

    case inputNames.POST_CODE:
      if (data && data.shouldForceLatinLetters) {
        valid = isStringOnlyWithLatinLettersAndNumbers(value);
        if (!valid) {
          return {
            valid,
            message: enforceLattinLettersMessage,
          };
        }
      }

      valid = value.length <= 20;
      return {
        valid,
        message: !valid
          ? {
              id: 'postCodeInputToLong.invalid.tooltip',
              description: "Post Code can't exceed {number} characters",
              defaultMessage: "Post Code can't exceed {number} characters",
              values: { number: 20 },
            }
          : null,
      };

    default:
      return {
        valid: true,
        message: null,
      };
  }
}

export function getValidationData(state, name) {
  switch (name) {
    case inputNames.FIRST_NAME:
      return {
        [inputNames.LAST_NAME]: state[inputNames.LAST_NAME].value,
      };

    case inputNames.LAST_NAME:
      return {
        [inputNames.FIRST_NAME]: state[inputNames.FIRST_NAME].value,
      };

    case inputNames.VERIFY_EMAIL:
      return {
        [inputNames.EMAIL]: state[inputNames.EMAIL].value,
      };

    case inputNames.CITY:
      return {
        [inputNames.ADDRESS]: state[inputNames.ADDRESS].value,
      };

    case inputNames.ADDRESS:
      return {
        [inputNames.CITY]: state[inputNames.CITY].value,
      };

    case inputNames.PHONE:
      return {
        [inputNames.COUNTRY_CODE]: state[inputNames.COUNTRY_CODE].value,
      };

    default:
      return null;
  }
}

export function formatPhoneNumberValue(value, countryCode) {
  return parsePhoneNumberFromString(value, countryCode);
}

export function getFormattedCustomerDetailsValues(customerDetails, inputs) {
  return inputs.map((name) => {
    let value = customerDetails[name] && customerDetails[name].value;

    if (axis.isUndefined(value)) {
      if (name === inputNames.TELEPHONE) {
        const countryCode = customerDetails[inputNames.COUNTRY_CODE].value.value;
        let phoneNumber = customerDetails[inputNames.PHONE].value;

        if (phoneNumber && phoneNumber.length > 0 && phoneNumber.indexOf(countryCode) === 0) {
          phoneNumber = phoneNumber.replace(countryCode, '');
        }

        const phoneNumberObject = parsePhoneNumberFromString(`${countryCode}${phoneNumber}`);

        if (phoneNumberObject && phoneNumberObject.formatInternational) {
          value = phoneNumberObject.formatInternational();
        } else {
          value = `${countryCode}${phoneNumber}`;
        }
      }
    } else if (axis.isObject(value)) {
      if (value._isAMomentObject) {
        value = value.format('YYYY-MM-DD');
      } else {
        value = value.label;
      }
    }

    return {
      name,
      value,
    };
  });
}

export function getCustomer(useDummyData) {
  const validity = useDummyData ? { valid: true, message: null } : null;
  const firstNameValue = useDummyData ? firstNamePool[Math.floor(Math.random() * firstNamePool.length)] : '';
  const lastNameValue = useDummyData ? lastNamePool[Math.floor(Math.random() * lastNamePool.length)] : '';
  const genderPoolValue = genderPool[Math.floor(Math.random() * genderPool.length)];
  const genderValue = useDummyData
    ? {
        value: 'male',
        data: { message: { id: 'gender.male.label', description: 'Male', defaultMessage: 'Male' } },
        dataAttr: { customer: true },
      }
    : '';
  const dateOfBirthValue = useDummyData ? moment('1990-05-26') : '';
  const passportNumberValue = useDummyData ? generateRandomString(8) : '';

  return {
    [inputNames.TITLE]: { value: 'Mr.', validity, disabled: false },
    [inputNames.FIRST_NAME]: { value: 'Testperson-se', validity, disabled: false },
    [inputNames.LAST_NAME]: { value: 'Approved', validity, disabled: false },
    [inputNames.GENDER]: { value: genderValue, validity, disabled: false },
    [inputNames.EMAIL]: { value: 'mitko@test.com', validity, disabled: false },
    [inputNames.VERIFY_EMAIL]: { value: 'mitko@test.com', validity, disabled: false },
    [inputNames.PHONE]: { value: '0770110122', validity, disabled: false },
    [inputNames.COUNTRY_CODE]: {
      value: { countryCode: 'SE', value: '+46', label: 'Sweden' },
      validity,
      disabled: false,
    },
    [inputNames.ADDRESS]: { value: 'Stårgatan 1', validity, disabled: false },
    [inputNames.CITY]: { value: 'Ankeborg', validity, disabled: false },
    [inputNames.POST_CODE]: { value: '12345', validity, disabled: false },
    [inputNames.COUNTRY]: { value: { value: 'MK', label: 'Sweden' }, validity, disabled: false },
    [inputNames.DATE_OF_BIRTH]: { value: dateOfBirthValue, validity, disabled: false },
  };
}

function filterPhoneNumber(phone, countryCode) {
  if (!countryCode) {
    return phone;
  }

  return phone.replaceAll(countryCode, '');
}

export function getCustomerForBookingAndPayments(customerDetails) {
  if (customerDetails === null) {
    return customerDetails;
  }

  return {
    title: customerDetails.title.value,
    firstName: !!customerDetails.firstName.value
      ? customerDetails.firstName.value.trim()
      : customerDetails.firstName.value,
    lastName: !!customerDetails.lastName.value ? customerDetails.lastName.value.trim() : customerDetails.lastName.value,
    gender: customerDetails.gender.value.value,
    email: customerDetails.email.value,
    phone: filterPhoneNumber(customerDetails.phone.value, customerDetails.countryCode.value.value),
    countryCode: customerDetails.countryCode.value.value,
    address: customerDetails.address.value,
    city: customerDetails.city.value,
    postCode: customerDetails.postCode.value,
    country: customerDetails.country.value.value,
    dateOfBirth: customerDetails.dateOfBirth.value.format('YYYY-MM-DD'),
  };
}

export function getTitleFromGender(gender) {
  return gender === 'male' ? 'Mr.' : 'Mrs.';
}
