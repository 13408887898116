import { TRIPX_AFFILIATE as TRIPX_AFFILIATE_COOKIE } from 'constants/cookiesKeys';
import calculateTotalPrice from 'state/checkoutPage/travelSummary/selectors/calculateTotalPrice';
import getCookie from 'utils/cookie/getCookie';
import getProductId from './utils/getProductId';
import getProductName from './utils/getProductName';
import { getDestinationFullName } from './utils/getProductCategory';
import trackEecEvent from '../utils/trackEecEvent';

export default function bookingSuccessful(eventName, eventData, state) {
  const { settings, hotelPage, deal, checkoutPagePromotionCode } = state;
  const { totalPrice } = calculateTotalPrice();

  const _eventData = {
    ecommerce: {
      currencyCode: settings.value.locale.currency,
      purchase: {
        actionField: {
          id: eventData.bookingCode,
          affiliation: getCookie(TRIPX_AFFILIATE_COOKIE), // affiliate id or empty string
          revenue: totalPrice, //total
          tax: '0',
          shipping: '0',
          coupon: checkoutPagePromotionCode.promotionCode, // promo code or empty string
        },
        products: [
          {
            id: getProductId(hotelPage.hotelsInfo, deal),
            name: getProductName(hotelPage.hotelsInfo, deal),
            price: totalPrice,
            category: getDestinationFullName(hotelPage.hotelsInfo),
            quantity: 1,
          },
        ],
      },
    },
  };

  trackEecEvent(eventName, _eventData);
}
