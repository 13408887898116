import * as types from './types';

const initialState = {
  fetching: null,
  slug: null,
  template: null,
  content: {},
  meta: {},
  shouldFetchData: true,
  redirect: null,
};

function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.SET_FETCHING:
      return setFetching(state, payload);

    case types.SET_RESOLVER_DATA:
      return setResolverData(state, payload);

    case types.SET_REDIRECT:
      return setRedirect(state, payload);

    case types.SET_SHOULD_FETCH_DATA:
      return setShouldFetchData(state, payload);

    case types.UPDATE_TEMPLATE_META:
      return updateTemplateMeta(state, payload);

    case types.RESET_STATE:
      return initialState;

    default:
      return state;
  }
}

export default reducer;

function setFetching(state, { fetching }) {
  return {
    ...state,
    fetching,
  };
}

function setResolverData(state, payload) {
  return {
    ...state,
    slug: payload.response.slug,
    template: payload.response.tripx && payload.response.tripx.template,
    content: {
      title: payload.response.title.rendered,
      description: payload.response.content.rendered,
      gallery: payload.response.tripx && payload.response.tripx.gallery,
      country_code: payload.response.tripx && payload.response.tripx.country_code,
      destination_code: payload.response.tripx && payload.response.tripx.destination_code,
      destination_id: payload.response.tripx && payload.response.tripx.destination_id,
      hotel_id: payload.response.tripx && payload.response.tripx.hotel_id,
      theme_id: payload.response.tripx && payload.response.tripx.theme_id && parseInt(payload.response.tripx.theme_id),
      theme_image: payload.response.tripx && payload.response.tripx.theme_image,
      theme_color: payload.response.tripx && payload.response.tripx.theme_color,
      featuredImage: payload.response.tripx && payload.response.tripx.featuredImage,
    },
    meta: payload.response.tripx && payload.response.tripx.meta,
    shouldFetchData: false,
    redirect: null,
    worldSearch: payload.response.worldSearch ? true : false,
    worldSearchHotelCode: payload.response.worldSearchHotelCode ? payload.response.worldSearchHotelCode : false,
  };
}

function setRedirect(state, { redirect }) {
  return {
    ...state,
    redirect,
  };
}

function setShouldFetchData(state, { shouldFetchData }) {
  return {
    ...state,
    shouldFetchData,
  };
}

function updateTemplateMeta(state, { meta }) {
  return {
    ...state,
    meta,
  };
}
