import { getHomeBoxes } from 'httpClient/requests/homeBoxes';
import { setHomeBoxes } from './actions';

export function fetchHomeBoxes() {
  return (dispatch) => {
    getHomeBoxes()
      .then((response) => {
        dispatch(setHomeBoxes(response.data));
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
