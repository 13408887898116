import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RESEGUIDEN, RESEGUIDEN_NO } from 'constants/whitelabels';
import PrimaryButtonComponent from './PrimaryButtonComponent';
import PrimaryButtonReseguiden from './PrimaryButtonReseguiden';

class PrimaryButton extends Component {
  render() {
    const { whitelabel, ...props } = this.props;
    if (whitelabel === RESEGUIDEN || whitelabel === RESEGUIDEN_NO) {
      return <PrimaryButtonReseguiden {...props} />;
    }

    return <PrimaryButtonComponent {...props} />;
  }
}

function mapStateToProps(state) {
  return {
    whitelabel: state.settings.value.whitelabel,
  };
}

export default connect(mapStateToProps)(PrimaryButton);
