/**
 * Components rendered by the pages.
 * When updating this file also update `server/utils/routes/constants/pagesComponentsKeys.js`.
 */
export const ACCOUNT_BOOKING = 'accountBooking';
export const ACCOUNT_LOGIN = 'accountLogin';
export const AGENT_LOGIN = 'agentLogin';
export const CHECKOUT_PAGE = 'checkoutPage';
export const CONFIRMATION = 'confirmation';
export const COUNTRY_SELECTION = 'countrySelection';
export const DEAL = 'deal';
export const DEALS = 'deals';
export const FRONTPAGE = 'frontpage';
export const HOTELPAGE = 'hotelpage';
export const TEMPLATE = 'template';
export const THANK_YOU = 'thankYou';
export const SAVED_PACKAGES = 'savedPackages';
export const SEARCH = 'search';
export const PROMO = 'promo';
export const LAST_MINUTE = '/lastminute';
export const SUCCESS_BOOKING = 'successBooking';
export const NOT_FOUND_PAGE = 'notFound';
