import moment from 'moment';
import isAfterDay from 'components/inputs/SelectDates/utils/isAfterDay';
import { POPULAR_HOTELS_PACKAGE } from 'components/packageCard/constants/packageTypes';

export function getFuturePackages(packages) {
  return packages.map((packageEl) => {
    if (packageEl.packageType === POPULAR_HOTELS_PACKAGE) {
      return packageEl;
    }
    const { packet } = packageEl;
    const packageStartDate = packet.dates && packet.dates.from ? moment(packet.dates.from) : null;
    const isFuturePacket = packageStartDate && isAfterDay(packageStartDate, moment());

    if (!isFuturePacket) {
      return { ...packageEl, packageType: POPULAR_HOTELS_PACKAGE };
    }

    return packageEl;
  });
}
