import moment from 'moment';
import searchQueryAvailable from 'utils/SearchQueryAvailable';
import { routesOperations } from 'state/routes';
import { SEARCH } from 'constants/routesKeys';
import SearchQueryParser from 'state/_utils/SearchQueryParser';
import searchQueryLS from 'managers/localStorage/searchQuery/searchQueryLS';
import searchPropertiesKeys from 'state/_constants/searchPropertiesKeys';
import isBeforeDay from 'components/inputs/SelectDates/utils/isBeforeDay';
import { setCalendarDates } from '../actions';
import { setCalendarOption } from '../actions';
import { setNumberOfDaysValue } from 'state/searchResultsFilters/actions';

export default function hydrateSelectedDates() {
  return (dispatch, getState) => {
    const pathToSearchResults = dispatch(routesOperations.getRoutePathByRouteKey(SEARCH));
    const flexibleDatesFromState = getState().selectDates.dates.flexibleDates;
    const dates = { flexibleDates: flexibleDatesFromState };
    let storedQuery = null;
    const language = getState().settings.value.locale.language;

    // FOR NOW WE DISABLE DEFAULT FLEXIBLE DATES = TRUE
    // if (isFrontPage) {
    //   const websiteId = getState().settings.value.websiteId;
    //   const website = Object.values(websiteConfig).find((el) => el.ID === websiteId);
    //   dates.flexibleDates = website && website.calendarDaysBlocked ? false : true;
    // }

    if (searchQueryAvailable(pathToSearchResults)) {
      storedQuery = SearchQueryParser.getSelectedDates();
    } else {
      storedQuery = searchQueryLS.get();
    }

    if (storedQuery !== null && storedQuery[searchPropertiesKeys.SELECTED_DATES_KEY]) {
      const _dates = storedQuery[searchPropertiesKeys.SELECTED_DATES_KEY];
      if (_dates.startDate) {
        const startDate = moment(_dates.startDate).locale(language);
        if (!isBeforeDay(startDate, moment())) {
          dates.startDate = startDate;
        }
      }

      if (_dates.endDate) {
        const endDate = moment(_dates.endDate).locale(language);
        if (!isBeforeDay(endDate, moment())) {
          dates.endDate = endDate;
        }
      }

      if (_dates.flexibleDates) {
        dates.flexibleDates = _dates.flexibleDates === 'true';
      }
      if (_dates.calendarOption) {
        dispatch(setCalendarOption(_dates.calendarOption));
      }
      if (_dates.numberOfDays) {
        dispatch(setNumberOfDaysValue(_dates.numberOfDays, true));
      }
    }

    dispatch(setCalendarDates(dates));
  };
}
