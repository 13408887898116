import concat from 'utils/immutable/concat';
import * as types from './types';

const initialState = {
  themes: [],
  packages: {},
  fetching: null,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_THEMES:
      return setThemes(state, action.payload);

    case types.SET_THEMES_PACKAGES:
      return setThemesPackages(state, action.payload);

    case types.UPDATE_PACKAGES_FOR_THEME:
      return updatePackagesForTheme(state, action.payload);
    case types.SET_FETCHING:
      return setFetching(state, action.payload);

    default:
      return state;
  }
}

export default reducer;

function setThemes(state, payload) {
  return {
    ...state,
    themes: payload.themes,
  };
}

function setThemesPackages(state, payload) {
  return {
    ...state,
    packages: payload.packages,
  };
}

function updatePackagesForTheme(state, { themeId, packages }) {
  let updatedPackagesForTheme = [];

  if (state.packages[themeId]) {
    updatedPackagesForTheme = concat(state.packages[themeId], packages);
  } else {
    updatedPackagesForTheme = packages;
  }

  return {
    ...state,
    packages: {
      ...state.packages,
      [themeId]: updatedPackagesForTheme,
    },
  };
}
function setFetching(state, { fetching }) {
  return {
    ...state,
    fetching,
  };
}
