import * as types from './types';

export function setState(state) {
  return {
    type: types.SET_STATE,
    payload: {
      state,
    },
  };
}

export function setSelectedHotels(selectedHotels) {
  return {
    type: types.SET_SELECTED_HOTELS,
    payload: {
      selectedHotels,
    },
  };
}

export function setSelectedRooms(selectedRooms) {
  return {
    type: types.SET_SELECTED_ROOMS,
    payload: {
      selectedRooms,
    },
  };
}

export function updateSelectedRooms(roomIndex, selectedRoom) {
  return {
    type: types.UPDATE_SELECTED_ROOMS,
    payload: {
      roomIndex,
      selectedRoom,
    },
  };
}

export function setSelectedFlights(selectedFlights) {
  return {
    type: types.SET_SELECTED_FLIGHTS,
    payload: {
      selectedFlights,
    },
  };
}

export function setSelectedFlightsExtras(selectedFlightsExtras) {
  return {
    type: types.SET_SELECTED_FLIGHTS_EXTRAS,
    payload: {
      selectedFlightsExtras,
    },
  };
}

export function updateSelectedFlightsExtras(data, passengerType, passengerIndex) {
  return {
    type: types.UPDATE_SELECTED_FLIGHTS_EXTRAS,
    payload: {
      data,
      passengerType,
      passengerIndex,
    },
  };
}

export function setSelectedOtherExtras(selectedOtherExtras) {
  return {
    type: types.SET_SELECTED_OTHER_EXTRAS,
    payload: {
      selectedOtherExtras,
    },
  };
}

export function setSelectedTransfer(transfer) {
  return {
    type: types.SET_SELECTED_TRANSFER,
    payload: {
      transfer,
    },
  };
}

export function setSelectedVehicleRentals(vehicleRentals) {
  return {
    type: types.SET_SELECTED_VEHICLE_RENTALS,
    payload: {
      vehicleRentals,
    },
  };
}

export function updateSelectedVehicleRentals(checked, searchToken, productToken) {
  return {
    type: types.UPDATE_SELECTED_VEHICLE_RENTALS,
    payload: {
      checked,
      searchToken,
      productToken,
    },
  };
}

export function setSelectedInsurance(insurance) {
  return {
    type: types.SET_SELECTED_INSURANCE,
    payload: {
      insurance,
    },
  };
}

export function setSelectedCO2Compensation(co2Compensation) {
  return {
    type: types.SET_SELECTED_CO2COMPENSATION,
    payload: {
      co2Compensation,
    },
  };
}

export function setSelectedEarthToday(earthToday) {
  return {
    type: types.SET_SELECTED_EARTH_TODAY,
    payload: {
      earthToday,
    },
  };
}

export function setSelectedCancellationProtection(cancellationProtection) {
  return {
    type: types.SET_SELECTED_CANCELLATION_PROTECTION,
    payload: {
      cancellationProtection,
    },
  };
}

export function setCurrentlyOpenSummaries(currentlyOpenSummaries) {
  return {
    type: types.SET_CURRENTLY_OPEN_SUMMARIES,
    payload: {
      currentlyOpenSummaries,
    },
  };
}

export function updateCurrentlyOpenSummaries(summaryKey, collapsed) {
  return {
    type: types.UPDATE_CURRENTLY_OPEN_SUMMARIES,
    payload: {
      summaryKey,
      collapsed,
    },
  };
}

export function resetState() {
  return {
    type: types.RESET_STATE,
  };
}
