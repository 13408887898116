import moment from 'moment';
import getSelectedHotelsRoomsWithSelectedOption from 'state/checkoutPage/packagesSearch/selectors/getSelectedHotelsRoomsWithSelectedOption';
import * as passengerTypes from '../constants/passengerTypes';

export default function validateDateOfBirth(passengerType, travelerRoomIndex, travelerIndex, dateOfBirth) {
  return (dispatch, getState) => {
    const { checkOut } = getState().checkoutPagePackagesSearch.value.hotels[0];
    const returnDate = moment(checkOut);
    const passengerCalculatedAge = returnDate.diff(dateOfBirth, 'years');
    let valid = false;
    let message = null;

    if (passengerTypes.ADULT === passengerType || passengerType === null) {
      valid = passengerCalculatedAge >= 18;
      message = !valid && {
        id: 'dateInput.adultAgeMissmatch.tooltip',
        description: 'Adults age must be 18 or over at checkout time.',
        defaultMessage: 'Adults age must be 18 or over at checkout time.',
      };
    } else {
      const selectedRooms = dispatch(getSelectedHotelsRoomsWithSelectedOption({ firstHotel: true }));
      const selectedRoom = selectedRooms.find(({ roomIndex }) => roomIndex === travelerRoomIndex);
      const childAges = selectedRoom.childages.filter((age) => age >= 2);
      const infantAges = selectedRoom.childages.filter((age) => age < 2);
      const inputedAge =
        passengerTypes.CHILD === passengerType
          ? childAges.find((age) => age === passengerCalculatedAge)
          : infantAges.find((age) => age === passengerCalculatedAge);

      valid = passengerCalculatedAge === inputedAge;
      message = !valid
        ? {
            id: 'dateInput.childAgeMissmatch.tooltip',
            description: 'Child age is not the same as the one requested in the search.',
            defaultMessage: 'Child age is not the same as the one requested in the search.',
          }
        : null;
    }

    return {
      valid,
      message,
    };
  };
}
