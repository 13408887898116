import React, { Component } from 'react';
import { initializeStore } from 'state';
import { updateBaseURL } from 'httpClient';
import { setCurrentPageComponent } from 'state/currentPageComponent/actions';
import { setPaths, setConfig, setSelectedCountry } from 'state/routes/actions';
import { setSettings } from 'state/settings/actions';
import { setResolverData } from 'state/template/actions';
import { updateColors } from 'state/theme/actions';
import { setValue } from 'state/urlSearchQuery/actions';
import { setForceSingleValue as selectOriginsSetForceSingleValue } from 'state/selectOrigins/actions';
import { setForceSingleValue as selectDestinationsSetForceSingleValue } from 'state/selectDestinations/actions';
import availableCountries from 'constants/availableCountries';

const __NEXT_REDUX_STORE__ = '__NEXT_REDUX_STORE__';

export function getOrCreateStore(initialState) {
  // Always make a new store if server, otherwise state is shared between requests
  if (!process.browser) {
    return initializeStore(initialState);
  }

  // Create store if unavailable on the client and set it on the window object
  if (!window[__NEXT_REDUX_STORE__]) {
    window[__NEXT_REDUX_STORE__] = initializeStore(initialState);
  }

  return window[__NEXT_REDUX_STORE__];
}

function populateReduxState(
  store,
  settings,
  pageData,
  pageComponent,
  routeToPathMap,
  routesConfig,
  selectedCountry,
  urlSearchQuery
) {
  settings.survivalMode = false;
  settings.availableCountries =
    (settings.airlineWhitelabel && availableCountries[settings.airlineWhitelabel.hostname]) || [];

  //updates the currency in the settings.locale object based on the customSettings.currency value, if it exists.
  settings.locale.currency =
    settings.customSettings && settings.customSettings.currency
      ? settings.customSettings.currency
      : settings.locale.currency;

  store.dispatch(setSettings(settings));
  pageData && store.dispatch(setResolverData(pageData));
  store.dispatch(setCurrentPageComponent(pageComponent));
  store.dispatch(setPaths(routeToPathMap));
  store.dispatch(setConfig(routesConfig));
  store.dispatch(setSelectedCountry(selectedCountry || ''));
  store.dispatch(updateColors(settings.style.colors));
  store.dispatch(setValue(urlSearchQuery));

  if (settings.airlineWhitelabel) {
    store.dispatch(selectOriginsSetForceSingleValue(settings.survivalMode));
    store.dispatch(selectDestinationsSetForceSingleValue(settings.survivalMode));
  }
}

export default (App) => {
  return class AppWithRedux extends Component {
    /**
     * Is executed once on the server side and then for every client side routing.
     * This behaviour is required to call the `getInitialProps` on individual page level.
     * @param {object} appContext
     */
    static async getInitialProps(appContext) {
      let initialProps = {},
        appProps = {};

      // Get or Create the store with `undefined` as initialState
      // This allows you to set a custom default initialState
      const store = getOrCreateStore();

      // Provide the store to getInitialProps of pages
      appContext.ctx.store = store;

      if (!process.browser) {
        const {
          wpHostnameData: ssrHostnameData,
          settings,
          pageData: ssrPageData,
          pageComponent,
          routeToPathMap,
          routesConfig,
          selectedCountry,
          processEnv: ssrProcessEnv,
        } = appContext.router.query.data;

        populateReduxState(
          store,
          settings,
          ssrPageData,
          pageComponent,
          routeToPathMap,
          routesConfig,
          selectedCountry,
          appContext.router.query.query
        );

        initialProps.hostnameData = ssrHostnameData;
        initialProps.pageData = ssrPageData;
        initialProps.processEnv = ssrProcessEnv;
      }

      // Must be executed before `getInitialProps` of page component.
      if (initialProps.hostnameData) {
        updateBaseURL(undefined, undefined, initialProps.hostnameData);
      }

      // Call the `getInitialProps` on individual page level. (if available)
      if (typeof App.getInitialProps === 'function') {
        appProps = await App.getInitialProps(appContext);
      }

      // The initial redux state is required only on the initial server render.
      // It must be accessed after the page level `getInitialProps` as they might update the store as well.
      if (!process.browser) {
        const initialReduxState = store.getState();
        initialProps.initialReduxState = initialReduxState;
      }

      initialProps = {
        ...initialProps,
        ...appProps,
      };

      return initialProps;
    }

    constructor(props) {
      super(props);

      if (!process.env.RUNTIME_HOST_ENV && props.processEnv && props.processEnv.RUNTIME_HOST_ENV) {
        console.warn(`RUNTIME_HOST_ENV not set. Will set from server response. [${props.processEnv.RUNTIME_HOST_ENV}]`);
        process.env.RUNTIME_HOST_ENV = props.processEnv.RUNTIME_HOST_ENV;
      }

      this.store = getOrCreateStore(props.initialReduxState);

      if (props.hostnameData) {
        updateBaseURL(undefined, undefined, props.hostnameData);
      }
    }

    render() {
      return <App {...this.props} store={this.store} />;
    }
  };
};
