import * as types from './types';
import { PRICE } from 'constants/sortByValues';

const initialState = {
  numberOfDays: {
    min: 2,
    max: 16,
    value: [],
  },
  hotelScore: {
    current: 0,
    possible: [0, 99],
  },
  hotelStars: {
    current: [],
    possible: [5, 4, 3, 2, 1],
  },
  priceRange: {
    min: 0,
    max: 0,
    value: [],
  },
  boardType: {
    current: null,
    possible: [],
  },
  hotelThemes: {
    current: [],
    possible: [],
  },
  hotelFacilities: {
    current: [],
    possible: [],
  },
  hotelCities: {
    current: [],
    possible: [],
  },
  sortBy: {
    value: PRICE,
  },
  displayMap: false,
  pristineResults: true,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_FILTERS_DATA:
      return setFiltersData(state, action.payload);

    case types.SET_NUMBER_OF_DAYS_VALUE:
      return setNumberOfDaysValue(state, action.payload);

    case types.SET_HOTEL_SCORE:
      return setHotelScore(state, action.payload);

    case types.SET_HOTEL_SCORE_RESET:
      return setHotelScoreReset(state, action.payload);

    case types.SET_HOTEL_STARS:
      return setHotelStars(state, action.payload);

    case types.SET_PRICE_RANGE:
      return setPriceRange(state, action.payload);

    case types.SET_PRICE_RANGE_VALUE:
      return setPriceRangeValue(state, action.payload);

    case types.SET_BOARD_TYPE:
      return setBoardType(state, action.payload);

    case types.SET_SELECTED_HOTEL_THEMES:
      return setSelectedHotelThemes(state, action.payload);

    case types.SET_SELECTED_HOTEL_FACILITIES:
      return setSelectedHotelFacilities(state, action.payload);

    case types.SET_SELECTED_HOTEL_CITIES:
      return setSelectedHotelCities(state, action.payload);

    case types.SET_SORT_BY:
      return setSortBy(state, action.payload);

    case types.SET_DISPLAY_MAP:
      return setDisplayMap(state, action.payload);

    case types.RESET_SEARCH_RESULTS_FILTERS:
      return resetSearchResultsFilters(state);

    default:
      return state;
  }
}

export default reducer;

// Private functions
function setFiltersData(state, { data }) {
  return {
    ...state,
    numberOfDays: data.numberOfDays ? data.numberOfDays : state.numberOfDays,
    hotelScore: {
      current: data.hotelScore.current,
      possible: state.hotelScore.possible,
    },
    hotelStars: {
      current: data.hotelStars.current,
      possible: state.hotelStars.possible,
    },
    priceRange: data.priceRange,
    boardType: data.boardType,
    hotelThemes: data.hotelThemes,
    hotelFacilities: data.hotelFacilities,
    hotelCities: data.hotelCities,
    pristineResults: true,
  };
}

function setNumberOfDaysValue(state, { value, keepPristine = false }) {
  return {
    ...state,
    numberOfDays: {
      ...state.numberOfDays,
      value,
    },
    pristineResults: keepPristine,
  };
}

function setHotelScore(state, { hotelScore }) {
  return {
    ...state,
    hotelScore,
  };
}

function setHotelScoreReset(state, { reset }) {
  return {
    ...state,
    hotelScore: {
      ...state.hotelScore,
      reset,
    },
  };
}

function setHotelStars(state, { hotelStars }) {
  return {
    ...state,
    hotelStars,
  };
}

function setPriceRange(state, { priceRange }) {
  return {
    ...state,
    priceRange,
  };
}

function setPriceRangeValue(state, { value }) {
  return {
    ...state,
    priceRange: {
      ...state.priceRange,
      value,
    },
  };
}

function setBoardType(state, { boardType }) {
  return {
    ...state,
    boardType: {
      ...state.boardType,
      current: boardType,
    },
  };
}

function setSelectedHotelThemes(state, { selectedHotelThemes }) {
  return {
    ...state,
    hotelThemes: {
      ...state.hotelThemes,
      current: selectedHotelThemes,
    },
  };
}

function setSelectedHotelFacilities(state, { selectedHotelFacilities }) {
  return {
    ...state,
    hotelFacilities: {
      ...state.hotelFacilities,
      current: selectedHotelFacilities,
    },
  };
}

function setSelectedHotelCities(state, { selectedHotelCities }) {
  return {
    ...state,
    hotelCities: {
      ...state.hotelCities,
      current: selectedHotelCities,
    },
  };
}

function setSortBy(state, payload) {
  return {
    ...state,
    sortBy: payload.sortBy,
  };
}

function setDisplayMap(state, payload) {
  return {
    ...state,
    displayMap: payload.value,
  };
}

function resetSearchResultsFilters(state) {
  // Because number of days is no longer part of the search results, but of calendar-options, we do not need to reset it
  // TODO : Find better solution for this
  return {
    ...initialState,
    numberOfDays: state.numberOfDays,
  };
}
