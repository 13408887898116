import concat from 'utils/immutable/concat';
import * as types from './types';

const initialState = {
  result: [],
  minMax: [],
  rolledUp: false,
  windowLastScrollYPosition: 0,
  displayPriceBars: true,
  status: null,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_PRICE_BARS_RESULT:
      return setPriceBarsResult(state, action.payload);

    case types.UPDATE_PRICE_BARS_RESULT:
      return updatePriceBarsResult(state, action.payload);

    case types.UPDATE_PRICE_BARS_VISIBILITY:
      return updatePriceBarsVisibility(state, action.payload);
    case types.SET_DISPLAY_PRICE_BARS:
      return setDisplayPriceBars(state, action.payload);

    case types.SET_FETCHING_PRICE_BARS:
      return setFetchingPriceBars(state, action.payload);

    default:
      return state;
  }
}

export default reducer;

function setPriceBarsResult(state, { result, minMax }) {
  return {
    ...state,
    result,
    minMax,
    rolledUp: result.length === 0,
  };
}

function updatePriceBarsResult(state, payload) {
  if (!payload.isBarsPast) {
    const lastResult = state.result[state.result.length - 1];
    const lastResultKey = lastResult.key;

    if (lastResultKey === payload.result[0].key) {
      lastResult.items = concat(lastResult.items, payload.result[0].items);
      payload.result.splice(0, 1);
    }
  } else {
    const firstResult = state.result[0];
    const firstResultKey = firstResult.key;

    if (firstResultKey === payload.result[payload.result.length - 1].key) {
      firstResult.items = concat(payload.result[payload.result.length - 1].items, firstResult.items);
      payload.result.pop();
    }
  }

  return {
    ...state,
    result: payload.isBarsPast ? concat(payload.result, state.result) : concat(state.result, payload.result),
    minMax: payload.updatedMinMax,
  };
}

function updatePriceBarsVisibility(state, payload) {
  if (payload.rollDown) {
    return {
      ...state,
      rolledUp: false,
    };
  }

  if (payload.rollUp) {
    return {
      ...state,
      rolledUp: payload.rollUp,
      windowLastScrollYPosition: payload.windowCurrentScrollYPosition,
    };
  }

  return {
    ...state,
    windowLastScrollYPosition: payload.windowCurrentScrollYPosition,
  };
}

function setDisplayPriceBars(state, payload) {
  return {
    ...state,
    displayPriceBars: payload.isDisplayingBars,
  };
}

function setFetchingPriceBars(state, payload) {
  return {
    ...state,
    status: payload.status,
  };
}
