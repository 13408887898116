import moment from 'moment';

export default function convertPackageSearchWorld(responseData, params) {
  let results = [];
  let hotelCodes = [];

  const datesFrom = moment(params.dates.from);
  const datesTo = moment(params.dates.to);
  const days = datesTo.diff(datesFrom, 'days') + 1;

  responseData &&
    responseData.data.packages &&
    responseData.data.packages.forEach &&
    responseData.data.packages.forEach((packageData) => {
      const hotelRef = packageData.hotelRefs[0];
      const hotel = responseData.data.hotels.find((hotel) => hotel.referenceToken === hotelRef);
      const room = hotel.rooms[0].availableOptions[0];

      const flightRef = packageData.flightRefs[0];
      const flight = responseData.data.flights.find((flight) => flight.productToken === flightRef);

      const body = {
        hotelId: hotel.id,
        hotelName: hotel.name,
        airport: {
          code: flight.legs[0].departureAirport,
          name: flight.legs[0].departureAirport,
        },
        price: packageData.price.perPerson,
        dates: {
          from: params.dates.from,
          to: params.dates.to,
          days: days,
        },
        board: {
          code: room.boards[0].boardCode,
          name: room.boards[0].name,
        },
        room: {
          id: room.id,
          name: room.name,
        },
        isOvernight: packageData.overnight,
      };
      hotelCodes.push(hotel.id);
      results.push(body);
    });
  return { results, hotelCodes };
}
