export const SET_FILTERS_DATA = 'searchResultsFilters/SET_FILTERS_DATA';
export const SET_NUMBER_OF_DAYS_VALUE = 'searchResultsFilters/SET_NUMBER_OF_DAYS_VALUE';
export const SET_HOTEL_SCORE = 'searchResultsFilters/SET_HOTEL_SCORE';
export const SET_HOTEL_SCORE_RESET = 'searchResultsFilters/SET_HOTEL_SCORE_RESET';
export const SET_HOTEL_STARS = 'searchResultsFilters/SET_HOTEL_STARS';
export const SET_PRICE_RANGE = 'searchResultsFilters/SET_PRICE_RANGE';
export const SET_PRICE_RANGE_VALUE = 'searchResultsFilters/SET_PRICE_RANGE_VALUE';
export const SET_BOARD_TYPE = 'searchResultsFilters/SET_BOARD_TYPE';
export const SET_SELECTED_HOTEL_THEMES = 'searchResultsFilters/SET_SELECTED_HOTEL_THEMES';
export const SET_SELECTED_HOTEL_FACILITIES = 'searchResultsFilters/SET_SELECTED_HOTEL_FACILITIES';
export const SET_SELECTED_HOTEL_CITIES = 'searchResultsFilters/SET_SELECTED_HOTEL_CITIES';
export const SET_SORT_BY = 'searchResultsFilters/SET_SORT_BY';
export const SET_DISPLAY_MAP = 'searchResultsFilters/SET_DISPLAY_MAP';
export const RESET_SEARCH_RESULTS_FILTERS = 'searchResultsFilters/RESET_SEARCH_RESULTS_FILTERS';
