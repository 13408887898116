import queryString from 'query-string';
import searchPropertiesKeys from 'state/_constants/searchPropertiesKeys';
import hotelPropertiesKeys from 'state/_constants/hotelPropertiesKeys';
import mapNorwegianAirportCodesToOriginCodes from 'state/searchResults/selectors/mapNorwegianAirportCodesToOriginCodes';
import mapNorwegianAirportCodesToDestinationCodes from 'state/searchResults/selectors/mapNorwegianAirportCodesToDestinationCodes';
import roomConfigurationsParser from './roomConfigurationsParser';
import { PERIOD_CALENDAR, SPECIFIC_DATES_CALENDAR } from 'components/inputs/SelectDates/constants/calendarSwitch';
import { tryParseUtf8 } from './tryParseUtf8';

const privateMethods = {
  updateQueryParams(hydrationSource) {
    if (hydrationSource) {
      if (typeof hydrationSource === 'string') {
        this.query = hydrationSource.slice(hydrationSource.indexOf('?'));
        this.queryParams = queryString.parse(this.query);
      } else {
        this.queryParams = hydrationSource;
      }
    } else if (process.browser && this.query !== window.location.search) {
      this.query = window.location.search;
      this.queryParams = queryString.parse(this.query);
    }
  },
};

class SearchQueryParser {
  constructor() {
    this.queryParams = {};
  }

  getSelectedOrigins() {
    privateMethods.updateQueryParams.call(this);

    const result = {};

    result[searchPropertiesKeys.SELECTED_ORIGINS_KEY] = this.queryParams.origins
      ? this.queryParams.origins.split(',').map((origin) => origin.substring(0, 3))
      : null;

    if (result[searchPropertiesKeys.SELECTED_ORIGINS_KEY]) {
      result[searchPropertiesKeys.SELECTED_ORIGINS_KEY] = mapNorwegianAirportCodesToOriginCodes(
        result[searchPropertiesKeys.SELECTED_ORIGINS_KEY]
      );
    }

    return result;
  }

  getSelectedDestinations() {
    privateMethods.updateQueryParams.call(this);

    const result = {};

    result[searchPropertiesKeys.SELECTED_DESTINATIONS_KEY] = this.queryParams.destinations
      ? this.queryParams.destinations.split(',')
      : null;

    if (result[searchPropertiesKeys.SELECTED_DESTINATIONS_KEY]) {
      result[searchPropertiesKeys.SELECTED_DESTINATIONS_KEY] = mapNorwegianAirportCodesToDestinationCodes(
        result[searchPropertiesKeys.SELECTED_DESTINATIONS_KEY]
      );
    }

    return result;
  }

  getSelectedOriginsAndDestinations() {
    privateMethods.updateQueryParams.call(this);

    const result = {};

    result[searchPropertiesKeys.SELECTED_ORIGINS_KEY] = this.queryParams.origins
      ? this.queryParams.origins.split(',')
      : null;
    result[searchPropertiesKeys.SELECTED_DESTINATIONS_KEY] = this.queryParams.destinations
      ? this.queryParams.destinations.split(',')
      : null;

    if (result[searchPropertiesKeys.SELECTED_DESTINATIONS_KEY]) {
      result[searchPropertiesKeys.SELECTED_DESTINATIONS_KEY] = mapNorwegianAirportCodesToDestinationCodes(
        result[searchPropertiesKeys.SELECTED_DESTINATIONS_KEY]
      );
    }

    if (result[searchPropertiesKeys.SELECTED_ORIGINS_KEY]) {
      result[searchPropertiesKeys.SELECTED_ORIGINS_KEY] = mapNorwegianAirportCodesToOriginCodes(
        result[searchPropertiesKeys.SELECTED_ORIGINS_KEY]
      );
    }

    return result;
  }

  getSelectedDates() {
    privateMethods.updateQueryParams.call(this);

    const result = {};

    result[searchPropertiesKeys.SELECTED_DATES_KEY] = {
      startDate: this.queryParams.from || this.queryParams.offerFrom || null,
      endDate: this.queryParams.to ? this.queryParams.to : this.queryParams.offerTo ? this.queryParams.offerTo : null,
      flexibleDates: this.queryParams.flexibleDates,
      calendarOption:
        this.queryParams.offerFrom && this.queryParams.offerTo ? PERIOD_CALENDAR : SPECIFIC_DATES_CALENDAR,
      numberOfDays: this.queryParams.numberOfDays
        ? this.queryParams.numberOfDays.split(',').map((el) => parseInt(el))
        : null,
    };

    return result;
  }

  getSelectedRoomConfigurations() {
    privateMethods.updateQueryParams.call(this);

    let roomConfigurations = [];

    if (this.queryParams.rooms !== undefined) {
      const rooms = this.queryParams.rooms.split(',');
      roomConfigurations = roomConfigurationsParser(rooms);
    }

    const result = {};

    result[searchPropertiesKeys.SELECTED_ROOM_CONFIGURATIONS_KEY] =
      this.queryParams.rooms !== undefined ? roomConfigurations : null;

    return result;
  }

  getPackageInfo(hydrationSource) {
    privateMethods.updateQueryParams.call(this, hydrationSource);

    const result = {
      search: {},
    };

    const config = {};

    result.search[hotelPropertiesKeys.ORIGIN_KEY] = this.queryParams.origin ? this.queryParams.origin : null;
    result.search[hotelPropertiesKeys.DATES_KEY] = {
      from: this.queryParams.from ? this.queryParams.from : null,
      to: this.queryParams.to ? this.queryParams.to : null,
    };
    result[hotelPropertiesKeys.PACKAGE_ROOM_ID_KEY] = this.queryParams.packageRoomId
      ? this.queryParams.packageRoomId
      : null;
    result[hotelPropertiesKeys.PACKAGE_PRICE_KEY] =
      this.queryParams.packagePrice && tryParseUtf8(this.queryParams.packagePrice.replace(/-/g, ''), config)
        ? config.value
        : null;
    result[hotelPropertiesKeys.PACKAGE_BOARD_CODE_KEY] = this.queryParams.packageBoardCode
      ? this.queryParams.packageBoardCode
      : null;

    let roomConfigurations = [
      {
        numberOfAdults: 2,
        numberOfChildren: 0,
        childrenAges: [],
      },
    ];

    if (this.queryParams.rooms !== undefined) {
      const rooms = this.queryParams.rooms.split(',');
      roomConfigurations = roomConfigurationsParser(rooms);
    }

    result.search[hotelPropertiesKeys.ROOM_CONFIGURATIONS_KEY] = roomConfigurations;

    return result;
  }
}

const instance = new SearchQueryParser();

export default instance;
