import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateSelectedFlightsExtras } from 'state/checkoutPage/travelSummary/actions';
import UpgradeComponent from './UpgradeComponent';

class Upgrade extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: false,
    };

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnClickUpgrade = this.handleOnClickUpgrade.bind(this);
  }

  componentDidMount() {
    const { selectedFlightsExtras, passengerType, passengerIndex, upgradeToken } = this.props;
    const selectedFlightsExtrasUpgrades =
      passengerIndex !== undefined
        ? selectedFlightsExtras[passengerType] && selectedFlightsExtras[passengerType][passengerIndex]
        : selectedFlightsExtras[passengerType];

    if (selectedFlightsExtrasUpgrades && selectedFlightsExtrasUpgrades.includes(upgradeToken)) {
      this.setState({ checked: true });
    }
  }

  handleOnClickUpgrade() {
    const fakeEvent = {
      target: {
        checked: !this.state.checked,
      },
    };

    this.handleOnChange(fakeEvent);
  }

  handleOnChange(event) {
    const { checked } = event.target;
    const { passengerType, passengerIndex, upgradeToken, updateSelectedFlightsExtras } = this.props;

    this.setState({ checked });
    updateSelectedFlightsExtras({ checked, upgradeToken }, passengerType, passengerIndex);
  }

  render() {
    return (
      <UpgradeComponent
        {...this.props}
        {...this.state}
        onChange={this.handleOnChange}
        onClickUpgrade={this.handleOnClickUpgrade}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedFlightsExtras: state.checkoutPageTravelSummary.selectedFlightsExtras,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateSelectedFlightsExtras(data, passengerType, passengerIndex) {
      dispatch(updateSelectedFlightsExtras(data, passengerType, passengerIndex));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Upgrade);
