import * as types from './types';

const initialState = {
  value: {
    affiliates: {
      forcedAffiliateId: null,
      list: [], // { id: number, name: string, utm_source: string, utm_campaign: string, utm_medium: string }
    },
    blacklistedDestinations: [],
    company: {
      shortName: '',
      fullName: '',
      isWhitelabel: true,
    },
    customerService: {
      phoneNumber: '',
    },
    faq: [], // { text: string, url: string }
    locale: {
      language: '',
      currency: '',
    },
    meta: {
      siteName: '',
      defaultTitle: '',
      defaultDescription: '',
      defaultShareImageUrl: '',
    },
    openingHours: {
      line1: { label: '', value: '' },
      line2: { label: '', value: '' },
      line3: { label: '', value: '' },
    },
    promoHero: null, // { heading: '', text: '' }
    style: {
      logoUrl: '',
      logoWhiteUrl: '',
      frontBgUrl: '',
      favicon: '',
      colors: {},
    },
    systemPages: {},
    topMessage: null, // { homepage_only: false, style: '', // oneOf [warning | info | danger | success] text: null }
    websiteId: 6,
    airlineWhitelabel: null,
    whitelabel: null,
    survivalMode: false,
    hostname: null,
    tld: null,
    checkoutOnlyWhitelabel: null,
  },
};

function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.SET_SETTINGS:
      return setSettings(state, payload);

    default:
      return state;
  }
}

export default reducer;

function setSettings(state, { settings }) {
  return {
    ...state,
    value: {
      ...state.value,
      ...settings,
    },
  };
}
