import toKebabCase from 'utils/string/toKebabCase';

export default function formatDataAttributes(dataAttr = {}) {
  const result = {};

  for (const property in dataAttr) {
    result[`data-${toKebabCase(property)}`] = dataAttr[property];
  }

  return result;
}
