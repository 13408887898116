import * as menuNames from '../constants/menuNames';

export default function getInitialState() {
  return Object.values(menuNames)
    .map((menuName) => {
      return {
        [menuName]: {
          fetching: null,
          value: [],
        },
      };
    })
    .reduce((accumulator, currentValue) => {
      return {
        ...accumulator,
        ...currentValue,
      };
    }, {});
}
