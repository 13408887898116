import * as types from './types';

const initialState = {
  missingValues: {
    selectOrigins: false,
    selectDestinations: false,
    selectDates: false,
  },
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_MISSING_VALUES_FOR_INPUT:
      return setMissingValuesForInput(state, action.payload);

    case types.RESET_MISSING_VALUES:
      return resetMissingValues(state);

    default:
      return state;
  }
}

export default reducer;

function setMissingValuesForInput(state, { input, value }) {
  return {
    ...state,
    missingValues: {
      ...state.missingValues,
      [input]: value,
    },
  };
}

function resetMissingValues(state) {
  return {
    ...state,
    missingValues: initialState.missingValues,
  };
}
