export default function getChargeablePersonsForRoom(room) {
  return [room].flat().map(getNumberOfAdultsAndChargeableChildren).reduce(sumChargeablePersons, 0);
}

function getNumberOfAdultsAndChargeableChildren(room) {
  const { adults, childages } = room;
  return {
    adults,
    children: childages.filter(chargeableChildren).length,
  };
}

function chargeableChildren(childAge) {
  return childAge >= 2;
}

function sumChargeablePersons(accumulator, currentValue) {
  return accumulator + currentValue.adults + currentValue.children;
}
