import * as types from './types';

/**
 * @typedef CheckoutPaymentOptionsReducerState
 * @type {object}
 * @property {boolean} fetching - Specifies whether a request to `POST /v3/payment_options` is dispatched.
 * @property {PaymentOptionsResponse=} value - Response from `POST /v3/payment_options`.
 * @property {number} valueTimeStamp - Unix timestamp indicating when the value was set.
 * @property {SelectedPaymentOption=} selectedPaymentOption - Array of destination codes.
 */

/**
 * @typedef PaymentOptionsResponse
 * @type {object}
 * @property {object[]} paymentOptions - Array of PaymentOption.
 * @property {object} finalPrice
 * @property {object=} cashPoints
 */

/**
 * @typedef SelectedPaymentOption
 * @type {object}
 * @property {string} type - Valid type specified in `src\state\templates\checkout\paymentOptions\constants\paymentTypes.js`.
 * @property {string} method - Valid type specified in `src\state\templates\checkout\paymentOptions\constants\paymentMethods.js`.
 * @property {string} provider - Valid providers specified in `src\state\templates\checkout\paymentProviders\constants\paymentProviders.js`.
 * @property {string=} providerType - Currently available for Adyen and Klarna providers. (FE internal)
 */

const initialState = {
  fetching: null,
  value: null,
  valueTimeStamp: null,
  selectedPaymentOption: null,
  partialPaymentOptionsRequestProperties: null,
  allowedPaymentOptions: null,
};

function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.SET_STATE:
      return payload.state;

    case types.SET_FETCHING:
      return setFetching(state, payload);

    case types.SET_PAYMENT_OPTIONS_VALUE:
      return setPaymentOptionsValue(state, payload);

    case types.SET_SELECTED_PAYMENT_OPTION:
      return setSelectedPaymentOption(state, payload);

    case types.PARTIAL_PAYMET_OPTIONS_REQUEST_PROPERTIES:
      return setPartialPaymentOptionsRequestProperties(state, payload);

    case types.SET_ALLOWED_PAYMENT_OPTIONS:
      return setAllowedPaymentOptions(state, payload);

    case types.RESET_STATE:
      return initialState;

    default:
      return state;
  }
}

export default reducer;

function setFetching(state, { fetching }) {
  return {
    ...state,
    fetching,
  };
}

function setPaymentOptionsValue(state, { value }) {
  return {
    ...state,
    value,
    valueTimeStamp: Date.now(),
    validity: null,
  };
}

function setSelectedPaymentOption(state, { selectedPaymentOption }) {
  return {
    ...state,
    selectedPaymentOption,
  };
}

function setPartialPaymentOptionsRequestProperties(state, { partialPaymentOptionsRequestProperties }) {
  return {
    ...state,
    partialPaymentOptionsRequestProperties,
  };
}

function setAllowedPaymentOptions(state, { allowedPaymentOptions } ) {
  return {
    ...state,
    allowedPaymentOptions,
  };
}
