import calculateTotalPrice from 'state/checkoutPage/travelSummary/selectors/calculateTotalPrice';
import getProductId from './utils/getProductId';
import getProductName from './utils/getProductName';
import { getDestinationFullName } from './utils/getProductCategory';
import trackEecEvent from '../utils/trackEecEvent';

export default function addedToCart(eventName, eventData, state) {
  const { settings, hotelPage, deal } = state;
  const { totalPrice } = calculateTotalPrice();

  const _eventData = {
    ecommerce: {
      currencyCode: settings.value.locale.currency.toUpperCase(),
      detail: {
        products: [
          {
            id: getProductId(hotelPage.hotelsInfo, deal),
            name: getProductName(hotelPage.hotelsInfo, deal),
            price: totalPrice,
            category: getDestinationFullName(hotelPage.hotelsInfo),
            quantity: 1,
          },
        ],
      },
    },
  };

  trackEecEvent(eventName, _eventData);
}
