import React, { useCallback } from 'react';
import styled from 'styled-components';
import InputTooltip from 'components/inputs/inputTooltip_v2/InputTooltipComponent';
import flexbox from 'styles/utils/flexbox';

const tooltipSeverity = 'error';

function InputComponent({
  componentsRefs,
  validity = {},
  validationVisible,
  focused,
  label,
  styles = {},
  name,
  nativeInputProps,
  dataAttr,
}) {
  let _validationVisible =
    validationVisible && validity && validity.valid === false && ((focused && !validity.clearOnFocus) || !focused);

  const setRef = useCallback(
    (node) => {
      if (node) {
        componentsRefs({
          node: node,
          error: _validationVisible ? 'error' : 'none',
        });
      }
    },
    [_validationVisible, componentsRefs]
  );

  return (
    <Root ref={setRef} styles={styles.root}>
      {label && (
        <InputLabel htmlFor={name} styles={styles.label}>
          {label}
        </InputLabel>
      )}
      <InputTooltip
        visible={_validationVisible}
        message={_validationVisible && validity.message}
        severity={tooltipSeverity}
      >
        <InputStyled
          {...nativeInputProps}
          id={name}
          name={name}
          styles={styles.input}
          invalid={_validationVisible}
          {...dataAttr}
        />
      </InputTooltip>
    </Root>
  );
}

export default InputComponent;

const Root = styled.div`
  ${flexbox({
    flexDirection: 'column',
  })}
  ${({ styles }) => styles};
`;

const InputLabel = styled.label`
  margin: 16px 0 12px;
  font-size: 1.4em;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.text.default};
  ${({ styles }) => styles};
`;

const InputStyled = styled.input`
  height: 38px;
  padding: 0 16px;
  border: 1px solid
    ${({ invalid, theme }) =>
      invalid ? theme.colors.background.severity[tooltipSeverity] : theme.colors.inputs.border.default};
  font-size: 1.4em;
  font-weight: 500;
  color: ${({ disabled, theme }) =>
    !disabled ? theme.colors.text.secondary : theme.colors.text.darkTransparent} !important;
  ${({ styles }) => styles};
`;
