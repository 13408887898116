import { CABIN_LUGGAGE, LUGGAGE } from 'routes/checkoutPage/constants/servicesKeys';

const servicesSortOrder = [CABIN_LUGGAGE, LUGGAGE];

export default function sortServicesByType(a, b) {
  const indexOfA = servicesSortOrder.indexOf(a.type);
  const indexOfB = servicesSortOrder.indexOf(b.type);

  return indexOfA - indexOfB;
}
