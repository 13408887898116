import { HOTEL_ONLY } from 'constants/selectValues';
export default function getPackagesSearchRequestParametersWorld(searchParameters) {
  const { selectDates, selectOrigin, selectRooms, destinationCode } = searchParameters;

  const withFlight = selectOrigin ? selectOrigin !== HOTEL_ONLY : false;
  const departureAirport = withFlight ? selectOrigin : null;
  const departureDate = selectDates.from ? selectDates.from : null;
  const returnDate = selectDates.to ? selectDates.to : null;
  const rooms = selectRooms.length;

  const body = {
    departureAirport,
    departureDate,
    returnDate,
    rooms,
    destinationCode,
    withFlight: withFlight.toString(),
  };

  selectRooms.forEach(({ numberOfAdults, childrenAges }, index) => {
    if (numberOfAdults) {
      body[`room${index + 1}adults`] = numberOfAdults;
    }
    if (childrenAges.length > 0) {
      body[`room${index + 1}childages`] = childrenAges.toString();
    }
  });

  return body;
}
