import * as pageComponentsKeys from 'constants/pagesComponentsKeys';
import * as types from './types';

const initialState = {
  isAccountBooking: false,
  isAccountLogin: false,
  isAgentLogin: false,
  isCheckoutPage: false,
  isCountrySelection: false,
  isDeal: false,
  isDeals: false,
  isFrontpage: false,
  isHotelPage: false,
  isTemplate: false,
  isThankYou: false,
  isSavedPackages: false,
  isSearchResults: false,
  isLastMinute: false,
  isSuccessBooking: false,
  isNotFoundPage: false,
};

function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.SET_CURRENT_PAGE_COMPONENT:
      return setCurrentPageComponent(state, payload);

    default:
      return state;
  }
}

export default reducer;

function setCurrentPageComponent(state, { value }) {
  return {
    ...state,
    isAccountBooking: pageComponentsKeys.ACCOUNT_BOOKING === value,
    isAccountLogin: pageComponentsKeys.ACCOUNT_LOGIN === value,
    isAgentLogin: pageComponentsKeys.AGENT_LOGIN === value,
    isCheckoutPage: pageComponentsKeys.CHECKOUT_PAGE === value,
    isCountrySelection: pageComponentsKeys.COUNTRY_SELECTION === value,
    isDeal: pageComponentsKeys.DEAL === value,
    isDeals: pageComponentsKeys.DEALS === value,
    isFrontpage: pageComponentsKeys.FRONTPAGE === value,
    isHotelPage: pageComponentsKeys.HOTELPAGE === value,
    isTemplate: pageComponentsKeys.TEMPLATE === value,
    isThankYou: pageComponentsKeys.THANK_YOU === value,
    isSavedPackages: pageComponentsKeys.SAVED_PACKAGES === value,
    isSearchResults: pageComponentsKeys.SEARCH === value,
    isPromoPage: pageComponentsKeys.PROMO === value,
    isLastMinute: pageComponentsKeys.LAST_MINUTE === value,
    isSuccessBooking: pageComponentsKeys.SUCCESS_BOOKING === value,
    isNotFoundPage: pageComponentsKeys.NOT_FOUND_PAGE === value,
  };
}
