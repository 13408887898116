import * as types from './types';

export function setMissingValuesForInput(input, value) {
  return {
    type: types.SET_MISSING_VALUES_FOR_INPUT,
    payload: {
      input,
      value,
    },
  };
}

export function resetMissingValues() {
  return {
    type: types.RESET_MISSING_VALUES,
  };
}
