import React from 'react';
import { components } from 'react-select';
import { FormattedMessage, FormattedCurrency } from 'i18n';

function Option(props) {
  const { description, price } = props.data.data;

  return (
    <components.Option {...props}>
      {description && description.id ? <FormattedMessage {...description} /> : description}
      {price && (
        <b>
          &nbsp;&nbsp;(+
          <FormattedCurrency value={price} />)
        </b>
      )}
    </components.Option>
  );
}

export default Option;
