export default function setCookie(cname, cvalue, exdays = 1, domain = window.location.hostname, path = '/') {
  if (!process.browser) {
    return;
  }

  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;

  document.cookie = `${cname}=${cvalue};${expires};domain=${domain};path=${path}`;
}
