import updateAtIndex from 'utils/immutable/updateAtIndex';
import replaceAtIndex from 'utils/immutable/replaceAtIndex';
import * as types from './types';
import validateInputValue from './validations/validateInputValue';
import { getValidationData } from '../customerDetails/selectors';
/**
 * @typedef TravelersReducerState
 * @type {object}
 * @property {Room[]} travelers
 * @property {object} validity
 * @property {boolean?} namesSpelledCorrectly
 * @property {boolean} namesSpelledCorrectlyVisible
 */

/**
 * @typedef Room
 * @type {object}
 * @property {Traveler[]} adults
 * @property {Traveler[]} children
 */

/**
 * Enum for valid gender values.
 * @readonly
 * @enum {string}
 */

/**
 * @typedef Traveler
 * @type {object}
 * @property {Genders} gender
 * @property {Input} firstName - Traveler first name as presented in the passport.
 * @property {Input} lastName - Traveler last name as presented in the passport.
 * @property {Input} dateOfBirth - Traveler date of birth as presented in the passport.
 * @property {Input} passportNumber - Traveler first name as presented in the passport.
 */

/**
 * @typedef Input
 * @type {object}
 * @property {*} value
 * @property {object} validity
 */

const initialState = {
  travelers: [],
  validity: null,
  namesSpelledCorrectly: null,
  namesSpelledCorrectlyVisible: false,
};

function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.SET_STATE:
      return payload.state;

    case types.SET_TRAVELERS_INITIAL_VALUE:
      return setTravelersInitialValue(state, payload);

    case types.SET_TRAVELER_INFORMATION:
      return setTravelerInformation(state, payload);

    case types.UPDATE_TRAVELER_INPUT_VALUE_AND_VALIDATE:
      return updateTravelerInputValueAndValidate(state, payload);

    case types.SET_VALIDITY:
      return setValidity(state, payload);

    case types.SET_NAMES_SPELLED_CORRECTLY:
      return setNamesSpelledCorrectly(state, payload);

    case types.SET_NAMES_SPELLED_CORRECTLY_VISIBLE:
      return setNamesSpelledCorrectlyVisible(state, payload);

    case types.RESET_TRAVELERS:
      return initialState;

    default:
      return state;
  }
}

export default reducer;

function setTravelersInitialValue(state, { travelers }) {
  return {
    ...state,
    travelers,
  };
}

function setTravelerInformation(state, { roomIndex, index, passengerType, traveler }) {
  return {
    ...state,
    travelers: updateAtIndex(state.travelers, roomIndex, {
      ...state.travelers[roomIndex],
      [passengerType]: updateAtIndex(state.travelers[roomIndex][passengerType], index, {
        ...traveler,
      }),
    }),
  };
}

function updateTravelerInputValueAndValidate(
  state,
  { name, value, roomIndex, index, passengerType, validity, additionalData }
) {
  const validationData = getValidationData(state.travelers[roomIndex][passengerType][index], name);
  return {
    ...state,
    travelers: updateAtIndex(state.travelers, roomIndex, {
      ...state.travelers[roomIndex],
      [passengerType]: updateAtIndex(state.travelers[roomIndex][passengerType], index, {
        ...state.travelers[roomIndex][passengerType][index],
        [name]: {
          ...state.travelers[roomIndex][passengerType][index][name],
          value,
          validity: validity
            ? validity
            : validateInputValue(name, value, undefined, { ...additionalData, validationData }),
        },
      }),
    }),
  };
}

function setValidity(state, { validity }) {
  return {
    ...state,
    validity,
  };
}

function setNamesSpelledCorrectly(state, { namesSpelledCorrectly }) {
  return {
    ...state,
    namesSpelledCorrectly,
  };
}

function setNamesSpelledCorrectlyVisible(state, { namesSpelledCorrectlyVisible }) {
  return {
    ...state,
    namesSpelledCorrectlyVisible,
  };
}
