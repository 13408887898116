/**
 * Get dates search request parameters based on provided dates and calendar options.
 *
 * @param {object} dates - The dates object containing 'from', 'to', and 'flexibleDates'.
 * @param {object} [restProps={}] - Additional properties and options.
 * @param {string} [restProps.calendarOption] - The calendar option (e.g., 'PERIOD_CALENDAR').
 * @returns {object} - The dates search request parameters.
 */

import { PERIOD_CALENDAR } from 'components/inputs/SelectDates/constants/calendarSwitch';

export const getDatesForSearchRequestParameters = (dates, restProps = {}) => {
  const { calendarOption } = restProps;

  if (calendarOption === PERIOD_CALENDAR) {
    return {
      offerFrom: dates.from ? dates.from.format('YYYY-MM-DD') : null,
      offerTo: dates.to ? dates.to.format('YYYY-MM-DD') : null,
    };
  }
  return {
    from: dates.from ? dates.from.format('YYYY-MM-DD') : null,
    to: dates.to ? dates.to.format('YYYY-MM-DD') : null,
    flexibleDates: dates.flexibleDates,
  };
};
