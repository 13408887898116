/**
 * Retrieves an array of two or returns null.
 *
 * @param {Object|null} searchResultsFilters - An object containing search-related items, or null.
 * @param {Object} restProps - An object containing calendar options and potentially other properties.
 * @param {string|null} restProps.calendarOption - A string representing a calendar option.
 * @returns {Array|null} An array of two items or null.
 */

import { PERIOD_CALENDAR } from 'components/inputs/SelectDates/constants/calendarSwitch';

export const getNumberOfDaysForSearchRequestParameters = (searchResultsFilters, restProps = {}) => {
  const { calendarOption } = restProps;

  if (calendarOption === PERIOD_CALENDAR) {
    if (
      searchResultsFilters !== null &&
      searchResultsFilters.numberOfDays &&
      searchResultsFilters.numberOfDays.value.length === 2
    ) {
      return searchResultsFilters.numberOfDays.value;
    }
    return [4, 6];
  }

  return null;
};
