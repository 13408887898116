import { SEARCH as ROUTE_KEY_SEARCH } from 'constants/routesKeys';
import searchPropertiesKeys from 'state/_constants/searchPropertiesKeys';
import searchQueryAvailable from 'utils/SearchQueryAvailable';
import SearchQueryParser from 'state/_utils/SearchQueryParser';
import searchQueryLS from 'managers/localStorage/searchQuery/searchQueryLS';
import { getRoutePathByRouteKey } from 'state/routes/operations';
import { setRooms } from '../actions';

export default function hydrateSelectedRooms() {
  return (dispatch) => {
    const pathToSearchResults = dispatch(getRoutePathByRouteKey(ROUTE_KEY_SEARCH));
    let storedQuery = null;

    if (searchQueryAvailable(pathToSearchResults)) {
      storedQuery = SearchQueryParser.getSelectedRoomConfigurations();
    } else {
      storedQuery = searchQueryLS.get();
    }

    if (
      storedQuery !== null &&
      storedQuery[searchPropertiesKeys.SELECTED_ROOM_CONFIGURATIONS_KEY] &&
      storedQuery[searchPropertiesKeys.SELECTED_ROOM_CONFIGURATIONS_KEY].length > 0
    ) {
      const rooms = storedQuery[searchPropertiesKeys.SELECTED_ROOM_CONFIGURATIONS_KEY];

      dispatch(setRooms(rooms));
    }
  };
}
