import { getPriceBars } from 'httpClient/requests/priceBars';
import { PRICES as ACTION_ID_PRICES } from 'state/actionBar/constants/actionsIds';
import { setDisableForAction } from 'state/actionBar/actions';
import groupByMonth from '../selectors/groupByMonth';
import { setFetchingPriceBars, setPriceBarsResult } from '../actions';
import * as status from 'constants/status/fetchingStatus';
import findMinMax from 'utils/array/findMinMax';

export default function fetchPriceBars(queryDate, params, priceBarsRequestData) {
  return async (dispatch) => {
    dispatch(setFetchingPriceBars(status.IN_PROGRESS));

    try {
      const response = await getPriceBars(queryDate, params, priceBarsRequestData);
      const data = response.data.length >= 40 ? response.data : [];
      const result = groupByMonth(data);
      const minMax = findMinMax(data, 'percentage');
      dispatch(setDisableForAction(ACTION_ID_PRICES, result.length === 0));
      dispatch(setPriceBarsResult(result, minMax));
      dispatch(setFetchingPriceBars(status.SUCCEEDED));
    } catch (error) {
      console.error(error);
      dispatch(setFetchingPriceBars(status.FAILED));
    }
  };
}
