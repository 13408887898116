/**
 *
 * @typedef GetHotelsInfoParams
 * @type {object}
 * @property {(number[]=|string[]=)} hotels - Array of hotel ids.
 * @property {string[]=} countries - Array of country codes.
 * @property {string[]=} destinations - Array of destination codes.
 * @property {string[]=} cities - Array of city names.
 * @property {string[]=} themes - Array of theme ids.
 */

import HttpClient from 'httpClient';
import { urlBuilder } from 'httpClient/aws/getAwsEndpoint';
import setTripxCacheKey from 'httpClient/aws/headers/setTripxCacheKey';
import proxy from 'httpClient/requests';
import getPackagesSearchRequestParametersWorld from 'state/checkoutPage/packagesSearch/selectors/getPackagesSearchRequestParametersWorld';

const methodSearch = '/search';
const methodSearchHotelsInfo = '/search-hotels-info';

export function getSearchResults(params, worldSearch = false) {
  const url = urlBuilder({ method: methodSearch, queryParam: null });
  const config = { headers: setTripxCacheKey(params) };

  if (worldSearch) {
    const worldSearchParams = getPackagesSearchRequestParametersWorld({
      selectDates: params.dates,
      selectOrigin: params.origins[0],
      selectRooms: params.roomConfigurations,
      destinationCode: params.destinations[0],
    });

    return proxy({
      httpMethod: 'GET',
      timeout: 60,
      version: 2,
      apiMethod: 'packages/search',
      body: { ...worldSearchParams },
    });
  }

  return HttpClient.post(url, params, config);
}

export async function getSearchResultsWorldHotel(params) {
  return proxy({
    httpMethod: 'GET',
    timeout: 60,
    version: 3,
    apiMethod: `hotels/bedBankData?hotelCodes=${params}&`,
  });
}

/**
 * @param {GetHotelsInfoParams} params
 */
export function getHotelsInfo(params) {
  const query = Object.keys(params)
    .map((key) => `${key}=${params[key].join(',')}`)
    .join('&');

  const url = urlBuilder({ method: methodSearchHotelsInfo, queryParam: query });

  return HttpClient.get(url);
}
