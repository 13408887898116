import * as types from './types';
import validateInputValue from './selectors/validateInputValue';

const initialState = {
  fetchingCustomer: false,
  fetchingPayment: null,
  customer: null,
  payment: null,
  inputs: {
    pno: {
      value: '',
      validity: null,
    },
  },
};

function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.SET_STATE:
      return payload.state;

    case types.REQUEST_CUSTOMER:
      return requestCustomer(state, payload);

    case types.SET_FETCHING_PAYMENT:
      return setFetchingPayment(state, payload);

    case types.SET_CUSTOMER:
      return setCustomer(state, payload);

    case types.SET_PAYMENT:
      return setPayment(state, payload);

    case types.SET_AND_VALIDATE_INPUT_VALUE:
      return setAndValidateInputValue(state, payload);

    case types.SET_INPUT_FIELD_VALIDITY:
      return setInputFieldValidity(state, payload);

    case types.RESET_PAYMENT_KLARNA:
      return initialState;

    default:
      return state;
  }
}

export default reducer;

function requestCustomer(state, { fetchingCustomer }) {
  return {
    ...state,
    fetchingCustomer,
  };
}

function setFetchingPayment(state, { fetchingPayment }) {
  return {
    ...state,
    fetchingPayment,
  };
}

function setCustomer(state, { customer }) {
  return {
    ...state,
    customer,
    fetchingCustomer: false,
  };
}

function setPayment(state, { payment }) {
  return {
    ...state,
    payment,
  };
}

function setAndValidateInputValue(state, { value, name, tld }) {
  return {
    ...state,
    customer: null,
    inputs: {
      ...state.inputs,
      [name]: {
        ...state.inputs[name],
        value,
        validity: validateInputValue(name, value, tld),
      },
    },
  };
}

function setInputFieldValidity(state, { name, validity }) {
  return {
    ...state,
    inputs: {
      ...state.inputs,
      [name]: {
        ...state.inputs[name],
        validity,
      },
    },
  };
}
