import React from 'react';
import styled from 'styled-components';
import formatDataAttributes from 'utils/object/formatDataAttributes';

function CheckboxComponent({ onChange, isChecked, name, id, rounded, readOnly, dataAttr, defaultChecked, className }) {
  return (
    <CheckboxStyled
      rounded={rounded}
      readOnly={readOnly}
      onClick={(event) => event.stopPropagation()}
      className={className}
    >
      <input
        type='checkbox'
        name={name}
        id={id}
        onChange={onChange}
        checked={isChecked}
        defaultChecked={defaultChecked}
        data-checked={isChecked}
        data-is-checkbox={'true'}
        {...formatDataAttributes(dataAttr)}
      />
      <span className='checkmark'></span>
    </CheckboxStyled>
  );
}

export default CheckboxComponent;

// Styled components
const CheckboxStyled = styled.label`
  display: inline-block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 12px;
  cursor: ${({ readOnly }) => (readOnly ? 'default' : 'pointer')};
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: ${({ readOnly }) => (readOnly ? 'none' : 'auto')};

  ${({ theme, rounded, readOnly }) =>
    !theme.touchDevice &&
    `
    &:hover {
      input ~ .checkmark {
        background-color: ${rounded ? theme.colors.inputs.radio.backgroundChecked : theme.colors.secondary.default};
        border: none;
        opacity: ${readOnly ? '1' : '0.3'};
        &:after {
          display: block;
        }
      }
    }
  `}

  input {
    position: absolute;
    height: 0;
    width: 0;
    opacity: 0;

    &:checked {
      ~ .checkmark {
        background-color: ${({ theme, rounded }) =>
          rounded ? theme.colors.inputs.radio.backgroundChecked : theme.colors.secondary.default};
        border: none;
        &:after {
          display: block;
        }
      }
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: ${({ rounded }) => (rounded ? '25px' : '20px')};
    width: ${({ rounded }) => (rounded ? '25px' : '20px')};
    background-color: ${({ theme, rounded }) => (rounded ? 'white' : theme.colors.primary.default)};
    border-radius: ${({ rounded }) => (rounded ? '50%' : '2px')};
    border: ${({ theme, rounded }) => (rounded ? `solid 1px ${theme.colors.inputs.radio.border}` : 'none')};
    &:after {
      content: '';
      display: none;
      position: absolute;
      left: ${({ rounded }) => (rounded ? '9px' : '7px')};
      top: ${({ rounded }) => (rounded ? '5px' : '3px')};
      width: 7px;
      height: 12px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
`;
