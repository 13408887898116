import React, { useCallback } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import axis from 'axis.js';
import InputTooltip from 'components/inputs/inputTooltip_v2/InputTooltipComponent';
import flexbox from 'styles/utils/flexbox';

const tooltipSeverity = 'error';

function SelectComponent({
  name,
  options,
  selectedOptions,
  validity = {},
  validationVisible,
  focused,
  label,
  placeholder,
  onChange,
  onFocus,
  onBlur,
  config = {},
  styles,
  colors,
  data,
  isUpgradeSelected,
  componentsRefs,
}) {
  const { className, ...reactSelectProps } = config;
  const customStyles = axis.isFunction(styles) ? styles(colors, isUpgradeSelected) : {};

  const _validationVisible =
    validationVisible && validity && validity.valid === false && ((focused && !validity.clearOnFocus) || !focused);

  const setRef = useCallback(
    (node) => {
      if (node) {
        componentsRefs({
          node: node,
          error: _validationVisible ? 'error' : 'none',
        });
      }
    },
    [_validationVisible, componentsRefs]
  );

  return (
    <Root ref={setRef} data-test='select-component'>
      {label && <InputLabel>{label}</InputLabel>}
      <InputTooltip
        visible={_validationVisible}
        message={_validationVisible && validity.message}
        severity={tooltipSeverity}
      >
        <Select
          styles={mergeStyles(getSelectStyles(colors), customStyles)}
          name={name}
          className={className}
          classNamePrefix={className}
          options={options}
          value={selectedOptions}
          placeholder={placeholder}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          {...reactSelectProps}
          data={{ ...data, invalid: _validationVisible }}
        />
      </InputTooltip>
    </Root>
  );
}

export default SelectComponent;

const Root = styled.div`
  ${flexbox({
    flexDirection: 'column',
  })}
`;

const InputLabel = styled.label`
  margin: 16px 0 12px;
  font-size: 1.4em;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.text.default};
`;

function mergeStyles(selectStyles, styles) {
  return {
    ...selectStyles,
    ...styles,
  };
}

const getSelectStyles = function (colors) {
  return {
    control: (styles, { selectProps }) => ({
      ...styles,
      background: colors.background.light,
      border: `1px solid ${
        selectProps.data && selectProps.data.invalid
          ? colors.background.severity[tooltipSeverity]
          : colors.inputs.border.default
      }`,
      borderColor: colors.inputs.border.default,
      borderRadius: '0',
      boxShadow: 'none',
      cursor: 'pointer',

      '&:hover': {
        borderColor: colors.inputs.border.default,
      },
    }),
    placeholder: (styles) => ({
      ...styles,
      fontSize: '1.4em',
      fontWeight: '500',
      color: colors.text.default,
    }),
    valueContainer: (styles) => ({
      ...styles,
      paddingLeft: '12px',
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: '1.4em',
      fontWeight: '500',
      color: colors.text.secondary,
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: '8',
      marginTop: '0',
      border: `1px solid ${colors.inputs.border.default}`,
      borderWidth: '0 1px 1px 1px',
      borderRadius: '0',
      boxShadow: '0px 3px 18px 0 rgba(0, 0, 0, 0.11)',
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: isFocused ? colors.background.hover : colors.background.light,
      fontSize: '1.4em',
      fontWeight: isSelected ? '700' : '500',
      color: colors.text.default,
      cursor: 'pointer',
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      display: 'none',
    }),
    indicatorsContainer: (styles, { selectProps }) => ({
      ...styles,
      transform: selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
      transition: 'transform 0.3s ease-out',
    }),
    input: (styles) => ({
      ...styles,
      fontSize: '16px',
    }),
  };
};
