import localStorageBase from '../localStorageBase';

const searchQueryLocalStorage = (function (ls) {
  const key = 'search_query';

  function getQueryValue() {
    return ls.get(key);
  }

  function updateQueryValue(property, updatedValue) {
    let query = ls.get(key);

    if (query === null) {
      query = {};
    }

    query[property] = updatedValue;

    ls.set(key, query);
  }

  return {
    get: getQueryValue,
    update: updateQueryValue,
  };
})(localStorageBase);

export default searchQueryLocalStorage;
